import { MDBCol, MDBRow } from "mdbreact";
import moment from "moment";
import React, { useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { v4 as uuidv4 } from "uuid";
import { ERROR_CODE_FORM_CHECKLIST_BUILD_FAILED } from "./ChecklistErrorCode";
import { COMPONENT_TYPE_ENUM } from "./ChecklistHelpers";
import { CheckboxInput } from "./FormInputs/CheckboxInput";
import { DateInput } from "./FormInputs/DateInput";
import { FileUpload } from "./FormInputs/FileUpload";
import { LongTextInput } from "./FormInputs/LongTextInput";
import { RadioInput } from "./FormInputs/RadioInput";
import { RepeatableSectionInput } from "./FormInputs/RepeatableSectionInput";
import { SelectInput } from "./FormInputs/SelectInput";
import { TableInput } from "./FormInputs/TableInput";
import { TextInput } from "./FormInputs/TextInput";
import "./style.css";

export const ChecklistBuilder = (props) => {
  const react = React;
  const [error, setError] = useState(null);
  const { section, changeHandler, loading, noTitle, errorCallback } = props;

  const getComponent = (section) => {
    try {
      switch (section.__component) {
        case COMPONENT_TYPE_ENUM.TEXT:
          return buildTextInput(section, changeHandler, loading);
        case COMPONENT_TYPE_ENUM.TITLE:
          return buildTitleComponent(section.title);
        case COMPONENT_TYPE_ENUM.SELECT:
          return buildSelectInput(section, changeHandler, loading);
        case COMPONENT_TYPE_ENUM.RADIO:
          return buildRadioInput(section, changeHandler, loading);
        case COMPONENT_TYPE_ENUM.MULTICHOICE:
          return buildCheckboxInput(section, changeHandler, loading);
        case COMPONENT_TYPE_ENUM.LONGTEXT:
          return buildLongTextInput(section, changeHandler, loading);
        case COMPONENT_TYPE_ENUM.PARAGRAPH:
          return buildParagraphComponent(section.text);
        case COMPONENT_TYPE_ENUM.DATE:
          return buildDateInput(section, changeHandler, loading);
        case COMPONENT_TYPE_ENUM.ATTACHMENT:
          return buildAttachmentComponent(section.files);
        case COMPONENT_TYPE_ENUM.TABLE:
          return buildTableComponent(section, changeHandler, loading);
        case COMPONENT_TYPE_ENUM.UPLOAD:
          return buildFileUploadComponent(section);
        case COMPONENT_TYPE_ENUM.REPEATABLE_GROUP:
          return buildRepeatableSectionGroupComponent(
            section,
            changeHandler,
            loading
          );
        default:
          return null;
      }
    } catch (e) {
      setError(ERROR_CODE_FORM_CHECKLIST_BUILD_FAILED);
      if (errorCallback)
        errorCallback(ERROR_CODE_FORM_CHECKLIST_BUILD_FAILED, e);
    }
  };

  if (error) {
    return (
      <div>
        Error initialising field. Please reload questionnaire and try again.
      </div>
    );
  }

  if (noTitle) {
    return (
      <>
        {section.map((s, i) => {
          return getComponent(s);
        })}
      </>
    );
  }

  return (
    <MDBRow className="checklist-form-row">
      <MDBCol md="2">
        <p className="checklist-form-section-title amiko-bold">
          {section[0].title}
        </p>
      </MDBCol>
      <MDBCol md="6">
        <div>
          {section.map((s, i) => {
            if (i === 0) {
              return null;
            }
            return getComponent(s);
          })}
        </div>
      </MDBCol>
    </MDBRow>
  );
};

const buildTextInput = (q, changeHandler, loading) => {
  const { questionKey, question, placeholder, required, questionHint, answer } =
    q;
  return (
    <TextInput
      key={questionKey}
      questionKey={questionKey}
      question={question}
      placeholder={placeholder}
      required={required}
      questionHint={questionHint}
      answer={answer}
      changeHandler={changeHandler}
      disabled={loading}
    />
  );
};

const buildDateInput = (q, changeHandler, loading) => {
  const { questionKey, question, placeholder, required, questionHint, answer } =
    q;
  return (
    <DateInput
      key={questionKey}
      questionKey={questionKey}
      question={question}
      placeholder={placeholder}
      required={required}
      questionHint={questionHint}
      answer={answer}
      changeHandler={changeHandler}
      disabled={loading}
    />
  );
};

const buildLongTextInput = (q, changeHandler, loading) => {
  const { questionKey, question, placeholder, required, questionHint, answer } =
    q;
  return (
    <LongTextInput
      key={questionKey}
      questionKey={questionKey}
      question={question}
      placeholder={placeholder}
      required={required}
      questionHint={questionHint}
      answer={answer}
      changeHandler={changeHandler}
      disabled={loading}
    />
  );
};

const buildSelectInput = (q, changeHandler, loading) => {
  const {
    questionKey,
    question,
    placeholder,
    required,
    questionHint,
    answer,
    options,
  } = q;

  return (
    <SelectInput
      key={questionKey}
      questionKey={questionKey}
      question={question}
      placeholder={placeholder}
      required={required}
      questionHint={questionHint}
      answer={answer}
      options={options}
      changeHandler={changeHandler}
      disabled={loading}
    />
  );
};

const buildRadioInput = (q, changeHandler, loading) => {
  const {
    id,
    questionKey,
    question,
    required,
    questionHint,
    answer,
    options,
    extraDetails,
  } = q;

  return (
    <RadioInput
      key={`${questionKey}_${id}`}
      questionKey={questionKey}
      question={question}
      required={required}
      questionHint={questionHint}
      answer={answer}
      options={options}
      extraDetails={extraDetails}
      changeHandler={changeHandler}
      disabled={loading}
    />
  );
};

const buildCheckboxInput = (q, changeHandler, loading) => {
  const {
    questionKey,
    question,
    required,
    questionHint,
    answer,
    options,
    extraDetails,
  } = q;

  return (
    <CheckboxInput
      key={questionKey}
      questionKey={questionKey}
      question={question}
      required={required}
      questionHint={questionHint}
      answer={answer}
      options={options}
      extraDetails={extraDetails}
      changeHandler={changeHandler}
      disabled={loading}
    />
  );
};

const buildTitleComponent = (q) => {
  return <h3 className="text-left">{q}</h3>;
};

const buildParagraphComponent = (q) => {
  const key = uuidv4();
  return (
    <div key={key} className="text-left">
      {ReactHtmlParser(q)}
    </div>
  );
};

const buildAttachmentComponent = (files) => {
  return (
    <div className="text-left">
      <ul>
        {files.map((f, i) => {
          const now = moment().valueOf();

          return (
            <a
              key={`${now}_${i}`}
              rel="noreferrer noopener"
              target="_blank"
              href={f.url}
            >
              <li style={{ textDecoration: "underline" }}>{f.name}</li>
            </a>
          );
        })}
      </ul>
    </div>
  );
};

const buildTableComponent = (q, changeHandler, loading) => {
  const { questionKey, question, required, questionHint, answer, headings } = q;

  return (
    <TableInput
      key={questionKey}
      questionKey={questionKey}
      question={question}
      required={required}
      questionHint={questionHint}
      answer={answer}
      headings={headings}
      changeHandler={changeHandler}
      disabled={loading}
    />
  );
};

const buildFileUploadComponent = (q) => {
  const { questionKey, question } = q;

  return (
    <FileUpload
      key={questionKey}
      question={question}
      questionKey={questionKey}
    />
  );
};

const buildRepeatableSectionGroupComponent = (q, changeHandler, loading) => {
  const {
    question,
    min,
    max,
    questionHint,
    questionKey,
    sectionPrefix,
    form_section,
  } = q;

  return (
    <RepeatableSectionInput
      question={question}
      min={min}
      max={max}
      questionHint={questionHint}
      questionKey={questionKey}
      sectionPrefix={sectionPrefix}
      changeHandler={changeHandler}
      disabled={loading}
      sectionGroup={form_section}
    />
  );
};

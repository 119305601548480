import {
  MDBAnimation,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBMask,
  MDBRow,
  MDBView,
} from "mdbreact";
import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async"; // import ReactGA from 'react-ga';
import { withRouter } from "react-router-dom";
import ScrollToTop from "../Nav/ScrollToTop";

import "./style.css";

class Trusts extends React.Component {
  // componentDidMount() {
  //     ReactGA.initialize('UA-103173484-2');
  //     ReactGA.pageview('/data-company');
  // }

  render() {
    return (
      <HelmetProvider>
        <div>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Trust Advisory | Neovia Advisory</title>
            <link
              rel="canonical"
              href="https://www.neovia.co.nz/trust-advisory"
            />
          </Helmet>
          <ScrollToTop />
          <div className="trust-header d-flex justify-content-center align-items-center page-header header-filter clear-filter bg-gradient-overlay">
            <div className="header-text">
              <h2 className="h4-responsive text-left center font-weight-md mb-4 pl-lg-2 secondary-text font-nanum-b sf-compact">
                Trust Advisory
              </h2>
              <h1 className="h1-responsive text-left center font-weight-md mb-4 pl-lg-2 font-nanum-b">
                We cannot predict the future, but we can protect it.{" "}
              </h1>
            </div>
          </div>
          <div id="about">
            <div className="padding-lg">
              <MDBAnimation reveal type="fadeIn" className="slow">
                <MDBContainer className="pt-5 pb-5">
                  <MDBRow>
                    <MDBCol md="5" className="mx-auto text-center pb-4">
                      <h2 className="display-h2 font-weight-md text-lg-right text-left mb-3 tblue-text font-nanum-eb">
                        Protect your future with a compliant trust.
                      </h2>
                      <h6 className="h5-responsive text-lg-right text-left font-space-xs mt-lg-5 font-nanum-eb sf-pro-compact">
                        Did you know the best time to establish a trust is when
                        you do not need it.
                      </h6>
                    </MDBCol>
                    <MDBCol
                      md="7"
                      className="mx-auto mt-lg-5 pl-lg-5  text-right"
                    >
                      <div className="mt-lg-5">
                        <h6 className="h5-responsive font-weight-md text-left mb-2 font-space-xs">
                          Our purpose is to educate individuals on asset
                          preservation and protection through a client-centric
                          approach tailored to their needs.
                        </h6>
                        <h6 className="h5-responsive text-left font-space-xs">
                          We are driven by integrity and building strong and
                          everlasting relationships with our clients.
                          {/* Our trust
                          division is led by a dedicated team who understand the
                          detail of New Zealand Trust Law. */}
                        </h6>
                        {/* <MDBBtn outline color="primary" className="mt-4" onClick={() => this.props.history.push('/financial-experts')} >Meet our team</MDBBtn> */}
                      </div>
                    </MDBCol>
                  </MDBRow>
                </MDBContainer>
              </MDBAnimation>
            </div>
          </div>

          <MDBAnimation reveal type="fadeIn" className="slow">
            <div className="trust-banner-1 page-header header-filter clear-filter blue-filter secondary-text">
              <MDBMask overlay="black-strong" className="w-100 pt-5 pb-5">
                <MDBCol md="10" lg="9" xl="8" className="mx-auto">
                  <MDBContainer className="pt-5 pb-5 text-left">
                    <div className="h3-responsive mb-4 mt-2 font-nanum-eb white-text">
                      Trusts require meticulous management to ensure they are
                      and continue to be, legally compliant.
                    </div>
                    <div
                      className="white-text mb-4 font-weight-md"
                      style={{ letterSpacing: "0.5px" }}
                    >
                      Over the years we have reviewed many non-compliant trusts
                      in New Zealand and swiftly made the necessary changes so
                      they were legally compliant. If a trust is not compliant
                      it cannot do the job of protecting your assets.
                    </div>
                  </MDBContainer>
                </MDBCol>
              </MDBMask>
            </div>
          </MDBAnimation>

          <div className="padding-lg">
            <div className="pt-5">
              <MDBContainer className="pt-2">
                <MDBRow className="mx-auto">
                  <MDBCol md="9" className="mx-auto">
                    <MDBAnimation reveal type="fadeIn" className="slow">
                      <h2 className="display-h2 tblue-text font-nanum-eb text-left mb-5">
                        How we protect your future.
                      </h2>
                    </MDBAnimation>
                    <h5 className="h5-responsive font-weight-md text-left mb-1 primary-text">
                      Trust Establishment
                    </h5>
                    <h6 className="h5-responsive text-left mb-5 font-nanum-eb ">
                      We take the time with our clients to understand their
                      current situation and their goals for the future. Advising
                      if a trust is best for them and if so, deciding the most
                      suitable type. The various types we consider include
                      Family, Parallel, Single, Business, Investment,
                      Inheritance or Charitable depending on their specific
                      personal needs or objectives.
                    </h6>
                    <h5 className="h5-responsive font-weight-md text-left mb-1 primary-text">
                      Trust Management
                    </h5>
                    <h6 className="h5-responsive text-left mb-5 font-nanum-eb ">
                      <p>
                        {" "}
                        Trusts require meticulous management to ensure they are
                        and continue to be legally compliant. This is
                        particularly important with the recent changes to
                        legislation in the trust sphere with the introduction of
                        the Trusts Act 2019.{" "}
                      </p>
                      <p>
                        {" "}
                        We are passionate about and have a new level of rigour
                        to our trust record keeping, in anticipation of the new
                        requirements by law.
                      </p>
                    </h6>
                    <h5 className="h5-responsive font-weight-md text-left mb-1 primary-text">
                      Review existing trusts
                    </h5>
                    <h6 className="h5-responsive text-left mb-5 font-nanum-eb ">
                      <p>
                        With New Zealand having the greatest numbers of trusts
                        per capita, it is disconcerting knowing an estimated 75%
                        of them could be successfully challenged and not fulfil
                        their purpose of protecting individuals hard earned
                        assets.{" "}
                      </p>
                      <p>
                        Business risk, relationship property claims and legal
                        action are all possible ways an existing trust can be
                        challenged. We are experts in reviewing existing trusts,
                        implementing any necessary changes and ensuring they
                        will hold up and offer full protection, for the reasons
                        they were established, if challenged.
                      </p>
                    </h6>
                    <h5 className="h5-responsive font-weight-md text-left mb-1 primary-text">
                      Estate and Succession Planning
                    </h5>
                    <h6 className="h5-responsive text-left mb-5 font-nanum-eb ">
                      <p>
                        We work with lawyers on this in conjunction with your
                        business and trust requirements
                      </p>
                    </h6>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </div>
          </div>

          <MDBAnimation
            reveal
            type="fadeIn"
            className="slow"
            style={{ backgroundColor: "whitesmoke" }}
          >
            <div className="pt-5 pb-5 padding-lg">
              <MDBContainer className="pt-5 pb-2">
                <MDBCol md="9" className="mx-auto">
                  <h2 className="display-h3 tblue-text font-nanum-eb text-left mb-5">
                    Who should protect their assets with a trust?
                  </h2>
                  <MDBRow className="mx-auto mt-5 mb-5">
                    <h6 className="bullet-point h5-responsive text-left primary-text mb-5 font-nanum-b">
                      <MDBIcon icon="chevron-right" className="mr-3 sky-text" />
                      Single people or those leaving a relationship with assets.
                    </h6>
                    <h6 className="bullet-point h5-responsive text-left primary-text mb-5 font-nanum-b">
                      <MDBIcon icon="chevron-right" className="mr-3 sky-text" />
                      Those individuals that are self employed or employees that
                      have some risk.
                    </h6>
                    <h6 className="bullet-point h5-responsive text-left primary-text mb-5 font-nanum-b">
                      <MDBIcon icon="chevron-right" className="mr-3 sky-text" />
                      Individuals that are getting married, re-married again or
                      settling down with a long-term partner.
                    </h6>
                    <h6 className="bullet-point h5-responsive text-left primary-text mb-5 font-nanum-b">
                      <MDBIcon icon="chevron-right" className="mr-3 sky-text" />
                      Existing couples that are wanting to protect
                      intergenerational wealth for their children's future.
                    </h6>
                  </MDBRow>
                </MDBCol>
              </MDBContainer>
            </div>
          </MDBAnimation>

          <MDBAnimation
            reveal
            type="fadeIn"
            className="slow"
            style={{ backgroundColor: "whitesmoke" }}
          >
            <div className="primary-bg">
              <MDBContainer className="pt-5 pb-5">
                <MDBRow className="pt-5">
                  <MDBCol md="12" className="mx-auto">
                    <h3 className="text-center white-text display-h3 mb-2 font-nanum-eb amiko-bold">
                      Our easy process to a compliant trust.
                    </h3>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </div>
          </MDBAnimation>
          <MDBAnimation
            reveal
            type="fadeIn"
            className="slow"
            style={{ backgroundColor: "whitesmoke" }}
          >
            <div style={{ borderBottom: "solid whitesmoke 4px" }}>
              <MDBRow style={{ padding: "0", margin: "0" }}>
                <MDBCol
                  sm="12"
                  md="6"
                  lg="4"
                  className="no-padding-margin container border-box-trusts"
                  style={{ borderTop: "solid whitesmoke 4px" }}
                >
                  <MDBView>
                    <img
                      src={require("../../assets/img/trust-1.png")}
                      className="img-fluid img-aboutus"
                      alt="tailored approach"
                      style={{ maxHeight: "220px" }}
                    />
                    <div className="center-title">
                      <MDBIcon
                        icon="search"
                        className="primary-text mb-3"
                        size="2x"
                      />
                      <h3 className="h5-responsive font-weight-md primary-text font-nanum-eb">
                        Step 1.
                      </h3>
                      <h6 className="h5-responsive font-weight-md primary-text font-nanum-eb">
                        We meet to understand your needs
                      </h6>
                    </div>
                  </MDBView>
                </MDBCol>

                <MDBCol
                  sm="12"
                  md="6"
                  lg="4"
                  className="no-padding-margin container border-box-trusts"
                  style={{ borderTop: "solid whitesmoke 4px" }}
                >
                  <MDBView>
                    <img
                      src={require("../../assets/img/trust-2.png")}
                      className="img-fluid img-aboutus"
                      alt="problem solvers"
                      style={{ maxHeight: "220px" }}
                    />
                    <div className="center-title">
                      <MDBIcon
                        icon="envelope-open"
                        className="primary-text mb-3"
                        size="2x"
                      />
                      <h3 className="h5-responsive font-weight-md primary-text font-nanum-eb">
                        Step 2.
                      </h3>
                      <h6 className="h5-responsive font-weight-md primary-text font-nanum-eb">
                        We email the draft trust deed to you
                      </h6>
                    </div>
                  </MDBView>
                </MDBCol>

                {/* <MDBCol
                  sm="12"
                  md="6"
                  lg="4"
                  className="no-padding-margin container border-box-trusts"
                  style={{ borderTop: "solid whitesmoke 4px" }}
                >
                  <MDBView>
                    <img
                      src={require("../../assets/img/trust-3.png")}
                      className="img-fluid img-aboutus"
                      alt="proven in our field"
                      style={{ maxHeight: "220px" }}
                    />
                    <div className="center-title">
                      <MDBIcon
                        icon="pen"
                        className="primary-text mb-3"
                        size="2x"
                      />
                      <h3 className="h5-responsive font-weight-md primary-text font-nanum-eb">
                        Step 3.
                      </h3>
                      <h6 className="h5-responsive font-weight-md primary-text font-nanum-eb">
                        We sign off the trust deed and Will instructions
                      </h6>
                    </div>
                  </MDBView>
                </MDBCol> */}
                <MDBCol
                  sm="12"
                  md="6"
                  lg="4"
                  className="no-padding-margin container border-box-trusts"
                  style={{ borderTop: "solid whitesmoke 4px" }}
                >
                  <MDBView>
                    <img
                      src={require("../../assets/img/trust-4.png")}
                      className="img-fluid img-aboutus"
                      alt="proven in our field"
                      style={{ maxHeight: "220px" }}
                    />
                    <div className="center-title">
                      <MDBIcon
                        icon="shield-alt"
                        className="primary-text mb-3"
                        size="2x"
                      />
                      <h3 className="h5-responsive font-weight-md primary-text font-nanum-eb">
                        Step 3.
                      </h3>
                      <h6 className="h5-responsive font-weight-md primary-text font-nanum-eb">
                        We complete the transfer of assets to your trust
                      </h6>
                    </div>
                  </MDBView>
                </MDBCol>
                <MDBCol
                  sm="12"
                  md="6"
                  lg="6"
                  className="no-padding-margin container border-box-trusts"
                  style={{ borderTop: "solid whitesmoke 4px" }}
                >
                  <MDBView>
                    <img
                      src={require("../../assets/img/trust-5.png")}
                      className="img-fluid img-aboutus"
                      alt="proven in our field"
                      style={{ maxHeight: "220px" }}
                    />
                    <div className="center-title">
                      <MDBIcon
                        icon="gavel"
                        className="primary-text mb-3"
                        size="2x"
                      />
                      <h3 className="h5-responsive font-weight-md primary-text font-nanum-eb">
                        Step 4.
                      </h3>
                      <h6 className="h5-responsive font-weight-md primary-text font-nanum-eb">
                        We meet to finalise compliance
                      </h6>
                    </div>
                  </MDBView>
                </MDBCol>
                <MDBCol
                  sm="12"
                  md="12"
                  lg="6"
                  className="no-padding-margin container border-box-trusts"
                  style={{ borderTop: "solid whitesmoke 4px" }}
                >
                  <MDBView>
                    <img
                      src={require("../../assets/img/trust-6.png")}
                      className="img-fluid img-aboutus"
                      alt="proven in our field"
                      style={{ maxHeight: "220px" }}
                    />
                    <div className="center-title">
                      <MDBIcon
                        icon="user-shield"
                        className="primary-text mb-3"
                        size="2x"
                      />
                      <h3 className="h5-responsive font-weight-md primary-text font-nanum-eb">
                        Step 5.
                      </h3>
                      <h6 className="h5-responsive font-weight-md primary-text font-nanum-eb">
                        We maintain trust compliance annually
                      </h6>
                    </div>
                  </MDBView>
                </MDBCol>
              </MDBRow>
            </div>
          </MDBAnimation>

          <MDBAnimation reveal type="fadeIn" className="slow">
            <div className="padding-lg">
              <div className="pt-5 pb-5">
                <MDBContainer>
                  <MDBRow className="mx-auto">
                    <MDBCol md="9" className="mx-auto">
                      <h2 className="display-h2 tblue-text font-nanum-eb text-left mb-5 mt-2">
                        Why we are different.
                      </h2>

                      <h5 className="h5-responsive font-weight-md text-left mb-1 primary-text">
                        Trusts are all we do.
                      </h5>
                      <h6 className="h5-responsive text-left mb-5 font-nanum-eb ">
                        We have a dedicated trust division that’s sole focus
                        trusts and the associated succession planning. This
                        enables us to be leaders in our field as this is our
                        expertise and our sole focus.
                      </h6>
                      <h5 className="h5-responsive font-weight-md text-left mb-1 primary-text">
                        We create partnerships with our clients.
                      </h5>
                      <h6 className="h5-responsive text-left mb-5 font-nanum-eb ">
                        We genuinely care about our clients, are committed to
                        educating them around trusts, and ensuring they are
                        involved and understand the process from start to
                        finish.
                      </h6>
                    </MDBCol>
                  </MDBRow>
                </MDBContainer>
              </div>
            </div>
          </MDBAnimation>
          {/* <div className="contact-div text-left">
                    <MDBContainer className="mt-5 mb-5">
                        <MDBRow className="mx-auto">
                            <MDBCol md="7">
                                <h3 className="tblue-text mb-4 font-nanum-eb font-weight-md">Get in touch with Zara Tonks</h3>
                                <h6 className="mb-4 font-nanum-eb font-weight-md">Contact our Trust Director for a no obligation consultation to find out more about how we can help you.</h6>

                                <MDBRow className="font-nanum-b mb-3">
                                    <MDBCol md="2" lg="2" className="mb-0 m-lr-auto">
                                        <p>
                                            <b className="font-quatBold">Phone</b>
                                        </p>
                                    </MDBCol>
                                    <MDBCol md="10" lg="10" className="mb-0 m-lr-auto">
                                        <p>
                                            <a className="secondary-text" href="tel:+6433666087">+64 3 366 6087 </a>
                                        </p>
                                    </MDBCol>
                                    <MDBCol md="2" lg="2" className="mb-0 m-lr-auto">
                                        <p>
                                            <b className="font-quatBold">Email</b>
                                        </p>
                                    </MDBCol>
                                    <MDBCol md="10" lg="10" className="mb-0 m-lr-auto">
                                        <p>
                                            <a className="secondary-text" href="mailto:zkt@neovia.co.nz">zkt@neovia.co.nz</a>
                                        </p>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <div className="text-lg-left text-center">
                                        <MDBBtn color="primary" className="min-220px"><a className="white-text" href="mailto:zkt@neovia.co.nz">Send Zara an email</a></MDBBtn>
                                    </div>
                                    <div className="text-lg-left text-center">
                                        <MDBBtn outline color="primary" onClick={() => this.props.history.push('/contact-us')}>Book an appointment</MDBBtn>
                                    </div>
                                </MDBRow>
                            </MDBCol>
                            <MDBCol md="5" className="text-center mt-lg-0 mt-4">
                                <img src={require('../../../src/assets/img/Zara.png')} className="img-fluid contact-card" />
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                </div> */}
          {/* 
                <div className="home-banner page-header header-filter clear-filter light-filter secondary-text">
                    <MDBMask overlay="black-strong" className="w-100 pt-5 pb-5">
                        <MDBCol md="10" lg="9" xl="8" className="mx-auto">
                            <MDBContainer className="pt-5 pb-5 text-left">
                                <div className="h3-responsive mb-4 mt-2 font-nanum-eb">
                                    We work closely with our clients to build enduring relationships that facilitate their success and ongoing prosperity.
                                </div>
                                <MDBBtn outline color="primary">Book an appointment</MDBBtn>
                            </MDBContainer>
                        </MDBCol>
                    </MDBMask>
                </div> */}
        </div>
      </HelmetProvider>
    );
  }
}

export default withRouter(Trusts);

import {
  MDBAnimation,
  MDBCardBody,
  MDBCardTitle,
  MDBCol,
  MDBIcon,
  MDBRow,
} from "mdbreact";
import moment from "moment";
import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Link, withRouter } from "react-router-dom";
import DataRequestor, { GET, STRAPI_URL } from "../../data/DataRequestor";
import ScrollToTop from "../Nav/ScrollToTop";

class AllJobVacancies extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      vacancies: props.vacancies,
    };
  }

  render() {
    return (
      <MDBCol md="5" lg="5" xl="4" className="mx-auto text-left pb-4">
        <div className="pr-lg-5">
          <h4 className="font-weight-md primary-text pt-serif h6-responsive">
            All Job Vacancies
          </h4>
          <hr className="mt-3 mb-4" />
          {this.state.vacancies.map((v, i) => {
            const key = `job-vacancy-list-item-${v.id}`;
            return (
              <JobVacancyListItem
                key={key}
                id={v.id}
                title={v.Title}
                vacancyLocation={v.location?.location}
              />
            );
          })}
        </div>
      </MDBCol>
    );
  }
}

class JobVacancyListItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.id,
      title: props.title,
      vacancyLocation: props.vacancyLocation,
    };
  }

  render() {
    return (
      <div className="pt-3 pb-3 vacancy-list-item">
        <Link to={`/job-vacancy/${this.state.id}`}>
          <MDBCardTitle className="text-left sub-title pl-0 pt-0 pb-0 m-0">
            <MDBRow>
              <MDBCol md="12" className="mx-auto">
                <div className="font-weight-md primary-text pt-serif center h6-responsive">
                  {this.state.title}
                </div>
              </MDBCol>
            </MDBRow>
          </MDBCardTitle>
          <div className="font-weight-md primary-text pt-serif text-left h6-responsive mt-3 sky-text">
            {this.state.vacancyLocation}
          </div>
        </Link>
      </div>
    );
  }

  // render() {
  //   return (
  //     <div className="pt-3 pb-3 vacancy-list-item">
  //       <Link to={`/job-vacancy/${this.state.id}`}>
  //         <div>
  //           <h2 className="h4-responsive vacancy-list-item-title uppercase font-weight-thin amiko-semibold text-left mb-3 tblue-text">
  //             {this.state.title}
  //           </h2>
  //           <h3 className="h5-responsive font-weight-thin amiko-semibold mb-1 text-left sky-text">
  //             {this.state.vacancyLocation}
  //           </h3>
  //         </div>
  //       </Link>
  //     </div>
  //   );
  // }
}

class JobVacanciesCards extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      vacancies: props.vacancies,
    };
  }

  render() {
    return (
      <MDBCol md="6" lg="7" xl="8">
        <MDBRow>
          {this.state.vacancies.map((v, i) => {
            const key = `job-vacancy-card-${v.id}`;
            return (
              <JobVacancyCard
                key={key}
                id={v.id}
                title={v.Title}
                vacancyLocation={v.location?.location}
              />
            );
          })}
        </MDBRow>
      </MDBCol>
    );
  }
}

class JobVacanciesNone extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      vacancies: props.vacancies,
    };
  }

  render() {
    return (
      <MDBCol md="12">
        <MDBRow>
          <p className="display-h4 text-left font-weight-semibold amiko-bold">
            We don’t have any current vacancies, but we’re always looking for
            good people to join us. If you’re interested in working with us,
            please send your cover letter and cv to careers@neovia.co.nz.{" "}
          </p>
        </MDBRow>
      </MDBCol>
    );
  }
}

class JobVacancyCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.id,
      title: props.title,
      vacancyLocation: props.vacancyLocation,
    };
  }

  render() {
    return (
      <MDBCol md="12" lg="12" xl="6">
        <Link to={`/job-vacancy/${this.state.id}`}>
          <div className="text-left mb-2 card-border">
            <MDBCardTitle className="text-left sub-title pb-2">
              <MDBRow>
                <MDBCol md="12" className="mx-auto">
                  <div className="font-weight-md primary-text pt-serif h6-responsive pt-4">
                    {this.state.title}
                  </div>
                </MDBCol>
              </MDBRow>
            </MDBCardTitle>
            <MDBCardBody className="pt-0 ml-2 mr-2 small-text pt-serif">
              <div className="font-weight-md primary-text pt-serif h6-responsive pb-2 sky-text">
                {this.state.vacancyLocation}
              </div>
              {/* <hr className="mt-3 mb-4 width-full" /> */}

              <div className="mt-4 h5-responsive pointer text-small font-weight-md pt-4 read-more">
                Learn more <MDBIcon fa icon="arrow-right" className="ml-2" />
              </div>
            </MDBCardBody>
            {/* <div><MDBBtn outline color="grey" className="ml-lg-4 mb-4">Learn more</MDBBtn></div> */}
          </div>
        </Link>
      </MDBCol>
    );
  }

  // render() {
  //   return (
  //     <MDBCol md="12" lg="12" xl="6">
  //       <Link to={`/job-vacancy/${this.state.id}`}>
  //         <div className="vacancy-card">
  //           <div className="vacancy-card-content">
  //             <h2 className="h4-responsive vacancy-card-content-title uppercase font-weight-thin amiko-semibold text-left mb-3 tblue-text">
  //               {this.state.title}
  //             </h2>
  //             <h3 className="h5-responsive font-weight-thin amiko-semibold mb-1 text-left sky-text">
  //               {this.state.vacancyLocation}
  //             </h3>
  //             <hr className="mt-3 mb-1 width-full" />
  //             <div className="vacancy-card-content-actions">
  //               <button
  //                 type="button"
  //                 className="text-button text-left h5-responsive uppercase tblue-text amiko-regular font-weight-thin"
  //               >
  //                 View job details
  //               </button>
  //             </div>
  //           </div>
  //         </div>
  //       </Link>
  //     </MDBCol>
  //   );
  // }
}

class JobVacancies extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      requestor: new DataRequestor(),
      title: "",
      bgImageUrl: "",
      vacancies: [],
    };
  }

  componentDidMount() {
    const pageDataUrl = `${STRAPI_URL}/job-vacancies`;
    this.state.requestor.fetch(this, pageDataUrl, GET, null, "page");

    const date = moment().format("YYYY-MM-DD");
    const vacanciesUrl = `${STRAPI_URL}/vacancies?application_start_lte=${date}&application_end_gte=${date}`;
    this.state.requestor.fetch(this, vacanciesUrl, GET, null, "vacancies");
  }

  fetchSuccess(data, id) {
    if (id === "page") {
      this.setState({
        title: data.title,
        bgImageUrl: data.cover_image?.url,
      });
    } else if (id === "vacancies") {
      this.setState({
        vacancies: data,
      });
    }
  }

  render() {
    return (
      <HelmetProvider>
        <div className="min-h-90vh">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Job Vacancies | Neovia Advisory | Financial Solutions</title>
            <link
              rel="canonical"
              href="https://www.neovia.co.nz/job-vacancies"
            />
          </Helmet>
          <ScrollToTop />
          <div
            className="job-vacancies-header d-flex justify-content-center align-items-center page-header header-filter clear-filter bg-gradient-overlay"
            style={{
              backgroundImage: `url(${this.state.bgImageUrl || ""})`,
            }}
          >
            <div className="header-text">
              <div className="mt-4 center">
                <h2 className="h4-responsive text-left font-weight-md pl-lg-2 secondary-text font-nanum-b sf-compact">
                  Work with us
                </h2>
                <h1 className="h1-responsive text-left font-weight-md pl-lg-2 font-nanum-b">
                  {this.state.title}
                </h1>
              </div>
            </div>
          </div>
          <div id="job-vacancies">
            <MDBAnimation reveal type="fadeIn" duration={350}>
              <div className="padding-lg">
                <div className="container-custom p-4 p-sm-5 p-md-4 p-lg-5 pt-lg-5 pb-lg-5">
                  {this.state.vacancies && this.state.vacancies.length > 0 ? (
                    <MDBRow className="mt-5 mb-5">
                      <AllJobVacancies vacancies={this.state.vacancies} />
                      <JobVacanciesCards vacancies={this.state.vacancies} />
                    </MDBRow>
                  ) : (
                    <JobVacanciesNone />
                  )}
                  <div className="pt-md-5 mb-md-5 text-left">
                    <hr className="mt-5 mb-4 width-full" />
                    <a
                      href="mailto:careers@neovia.co.nz"
                      className="h4-responsive tblue-text amiko-regular font-weight-thin "
                    >
                      careers@neovia.co.nz
                    </a>
                  </div>
                </div>
              </div>
            </MDBAnimation>
          </div>
        </div>
      </HelmetProvider>
    );
  }
}

export default withRouter(JobVacancies);

import { MDBAnimation, MDBBtn, MDBCol, MDBContainer, MDBRow } from "mdbreact";
import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import ReactHtmlParser from "react-html-parser";
import { Link, withRouter } from "react-router-dom";
import DataRequestor, { GET, STRAPI_URL } from "../../data/DataRequestor";
import ScrollToTop from "../Nav/ScrollToTop";

class JobVacancy extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      requestor: new DataRequestor(),
      vacancy: null,
      error: null,
    };
  }

  componentDidMount() {
    const pathSplit = window.location.pathname.split("/");
    const id = pathSplit[pathSplit.length - 1];

    const vacancyDataUrl = `${STRAPI_URL}/vacancies/${id}`;
    this.state.requestor.fetch(this, vacancyDataUrl, GET, null, "vacancy");
  }

  fetchSuccess(data, id) {
    console.log(data);
    this.setState({ vacancy: data });
  }

  fetchFailed(error) {
    console.log(error);
    this.setState({ error: error });
  }

  render() {
    return (
      <HelmetProvider>
        <div className="min-h-90vh">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Job Vacancies | Neovia Advisory | Financial Solutions</title>
            <link
              rel="canonical"
              href="https://www.neovia.co.nz/job-vacancies"
            />
          </Helmet>
          <ScrollToTop />
          <div className="job-vacancy-header d-flex justify-content-center align-items-center page-header header-filter clear-filter">
            <MDBContainer>
              <div className="text-left">
                <h3 className=" h4-responsive font-weight-thin amiko-bold mb-1 text-center sky-text">
                  {this.state.vacancy?.location?.location}
                </h3>
                <h2 className=" h1-responsive font-weight-thin amiko-bold mb-3 text-center tblue-text">
                  {this.state.vacancy?.Title}
                </h2>
              </div>
            </MDBContainer>
          </div>
          <div id="job-vacancy">
            <MDBAnimation reveal type="fadeIn" duration={350}>
              <div className="padding-lg">
                {this.state.vacancy ? (
                  <>
                    <MDBRow>
                      <MDBCol lg="6">
                        <div className="text-left h5-responsive vacancy-body tblue-text">
                          {ReactHtmlParser(this.state.vacancy?.body || "")}
                        </div>
                      </MDBCol>
                      <MDBCol lg="6">
                        <div className="vacancy-video-container">
                          {this.state.vacancy.video ? (
                            <div
                              style={{
                                position: "relative",
                                overflow: "hidden",
                                paddingTop: "58%",
                              }}
                            >
                              <video
                                controls
                                id="job-vacancy-video"
                                className=""
                                style={{
                                  position: "absolute",
                                  top: "0",
                                  left: "0",
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                  overflow: "hidden",
                                }}
                              >
                                <source
                                  src={this.state.vacancy.video.url}
                                  type="video/mp4"
                                />
                              </video>
                            </div>
                          ) : (
                            <hr className="mt-3 mb-1 width-full" />
                          )}
                        </div>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol lg="6">
                        <div className="vacancy-body job-vacancy-apply-now-container">
                          <Link
                            to={`/job-vacancy-application/${this.state.vacancy.id}`}
                          >
                            <MDBBtn
                              className="sf-pro white-text"
                              color="info"
                              size="lg"
                              style={{ minWidth: "220px", marginLeft: 0 }}
                            >
                              Apply now
                            </MDBBtn>
                            {/* <button
                            type="button"
                            className="job-vacancy-apply-now-btn text-left display-h5 uppercase amiko-regular font-weight-thin"
                          >
                            Apply now
                          </button> */}
                          </Link>
                        </div>
                      </MDBCol>
                    </MDBRow>
                  </>
                ) : (
                  <MDBContainer className="d-flex">
                    <MDBRow>
                      <MDBCol lg="12">
                        <p className="display-h4 text-left mt-5 mb-5 font-weight-semibold amiko-bold">
                          We are unable to find the job vacancy you're looking
                          for.{" "}
                          <Link to={"/job-vacancies"} className="sky-text">
                            Return to all job vacancies.
                          </Link>
                        </p>
                      </MDBCol>
                    </MDBRow>
                  </MDBContainer>
                )}
              </div>
            </MDBAnimation>
          </div>
        </div>
      </HelmetProvider>
    );
  }
}

export default withRouter(JobVacancy);

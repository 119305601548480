import { MDBAnimation, MDBBtn, MDBCol, MDBContainer, MDBRow } from "mdbreact";
import React, { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { withRouter } from "react-router-dom";
import { STRAPI_URL } from "../../data/DataRequestor";
import { FAILED_COLOR, SK, SUCCESS_COLOR } from "../../utils/constants";
import { axRequest, METHOD_POST } from "../../utils/request";
import ScrollToTop from "../Nav/ScrollToTop";
import "./style.css";

const ContactUsPage = () => {
  const initData = {
    identifier: SK,
    name: "",
    phone: "",
    email: "",
    subject: "",
    message: "",
    to_email: "",
    extra: "",
  };
  const [processing, setProcessing] = useState(false);
  const [sendMessage, setSendMessage] = useState("");
  const [color, setColor] = useState("");
  const [data, setData] = useState(initData);
  const [locations, setLocations] = useState(null);

  useEffect(() => {
    let active = true;

    const getLocations = async () => {
      try {
        const res = await fetch(STRAPI_URL + "/locations", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (res.status === 200) {
          const resJson = await res.json();
          if (resJson && resJson.length > 0) {
            if (active) {
              setLocations(resJson);
            }
            return resJson;
          } else {
            setLocations([]);
            return [];
          }
        }

        return [];
      } catch (e) {
        return [];
      }
    };

    getLocations();

    return () => {
      active = false;
    };
  }, []);

  const submitHandler = async (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    let inputs = event.nativeEvent.target;
    for (let i = 0; i < inputs.length; i++) {
      let input = inputs[i];

      if (input.name === "extra") continue;

      if (input != null && !input.validity.valid) {
        return;
      }
    }
    setProcessing(true);
    setSendMessage("");

    let response = await axRequest(null, "/contact", METHOD_POST, data);
    console.log(response);
    if (response && response.success) {
      setColor(SUCCESS_COLOR);
      setSendMessage("Thank you for your message, we will be in touch soon.");
      resetForm();
    } else {
      setColor(FAILED_COLOR);
      setSendMessage("Oops, something went wrong, please try again!");
    }
    setProcessing(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
  };

  const resetForm = () => {
    document.getElementById("contact-form").classList.remove("was-validated");
    setData(initData);
  };

  return (
    <HelmetProvider>
      <div className="mx-auto">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Contact Us | Neovia Advisory</title>
          {/* <description>We offer a full range of property, business and personal legal services to a diverse client base.</description> */}
          <link rel="canonical" href="https://www.neovia.co.nz/contact-us" />
        </Helmet>
        <ScrollToTop />
        <div className="contact-header d-flex justify-content-center align-items-center page-header header-filter clear-filter bg-gradient-overlay">
          <div className="header-text">
            <h2 className="h4-responsive text-left center font-weight-md mb-4 pl-lg-2 secondary-text font-nanum-b sf-compact">
              Contact Us
            </h2>
            <h1 className="h1-responsive text-left center font-weight-md mb-4 pl-lg-2 font-nanum-b">
              Get in touch with us for a brighter financial future.
            </h1>
          </div>
        </div>
        <div id="about">
          <div id="contact">
            <MDBContainer className="mt-5 padding-lg">
              <MDBRow className="secondary-text mb-5">
                <MDBCol
                  md="12"
                  lg="6"
                  className="text-left font-quatBold primary-text mb-5"
                >
                  {/* <h5 className='h3-responsive mb-4 font-nanum-eb'>Contact Details</h5> */}
                  <MDBRow>
                    <MDBCol md="12">
                      <h2 className="text-left h3-responsive mx-auto mb-4 font-nanum-eb">
                        Send us a message
                      </h2>
                      <form
                        id="contact-form"
                        className="needs-validation"
                        onSubmit={submitHandler}
                        noValidate
                      >
                        {sendMessage && sendMessage.length > 0 ? (
                          <MDBRow>
                            <MDBCol
                              style={{
                                minHeight: "45px",
                                padding: "5px 0px 25px 0px",
                                fontSize: "14px",
                                textAlign: "center",
                              }}
                            >
                              <MDBAnimation type="bounceIn" duration="500ms">
                                <div
                                  className="font-quatRegular"
                                  style={{
                                    padding: "15px 5px 0px 5px",
                                    fontWeight: 500,
                                    fontSize: "20px",
                                    color: color,
                                  }}
                                >
                                  {sendMessage}
                                </div>
                              </MDBAnimation>
                            </MDBCol>
                          </MDBRow>
                        ) : null}
                        {locations && (
                          <div className="form-group">
                            <label htmlFor="to_email" className="">
                              Local branch
                            </label>
                            <select
                              name="to_email"
                              value={data.to_email}
                              className="form-control"
                              onChange={handleChange}
                              required
                            >
                              <option selected disabled hidden value="">
                                -- Select Your Local Branch --
                              </option>
                              {locations.map((l) => (
                                <option key={l.emails} value={l.emails}>
                                  {l.location}
                                </option>
                              ))}
                            </select>
                            <div className="invalid-feedback">Required.</div>
                          </div>
                        )}
                        <div className="form-group">
                          <label htmlFor="name" className="">
                            Your name
                          </label>
                          <input
                            type="text"
                            id="name"
                            name="name"
                            className="form-control"
                            value={data.name}
                            onChange={handleChange}
                            disabled={processing}
                            required
                          />
                          <div className="invalid-feedback">Required.</div>
                        </div>
                        <br />
                        <div className="form-group">
                          <label htmlFor="email" className="">
                            Your email
                          </label>
                          <input
                            type="email"
                            id="email"
                            name="email"
                            className="form-control"
                            value={data.email}
                            onChange={handleChange}
                            disabled={processing}
                            required
                          />
                          <div className="invalid-feedback">Required.</div>
                        </div>
                        <br />
                        <div className="form-group">
                          <label htmlFor="phone" className="">
                            Your phone number
                          </label>
                          <input
                            type="tel"
                            id="phone"
                            name="phone"
                            pattern="^(0\d\d{7}|02\d\d{6,12}|0800\d{5,12})$"
                            className="form-control"
                            value={data.phone}
                            onChange={handleChange}
                            disabled={processing}
                            required
                          />
                          <div className="invalid-feedback">Required.</div>
                        </div>
                        <br />
                        <div className="form-group">
                          <label htmlFor="subject" className="">
                            Subject
                          </label>
                          <input
                            type="text"
                            id="subject"
                            name="subject"
                            className="form-control"
                            value={data.subject}
                            onChange={handleChange}
                            disabled={processing}
                            required
                          />
                          <div className="invalid-feedback">Required.</div>
                        </div>
                        <br />
                        <div className="form-group">
                          <label htmlFor="message" className="">
                            Your message
                          </label>
                          <textarea
                            type="text"
                            id="message"
                            name="message"
                            className="form-control"
                            rows="3"
                            value={data.message}
                            onChange={handleChange}
                            disabled={processing}
                            required
                          />
                          <div className="invalid-feedback">Required.</div>
                        </div>
                        <div className="form-group" style={{ display: "none" }}>
                          <label htmlFor="message" className="">
                            Extra Info
                          </label>
                          <textarea
                            type="text"
                            id="extra"
                            name="extra"
                            className="form-control"
                            rows="3"
                            value={data.extra}
                            onChange={handleChange}
                            disabled={processing}
                            required
                          />
                          <div className="invalid-feedback">Required.</div>
                        </div>
                        <div className="text-center mt-4">
                          <MDBBtn
                            color="primary"
                            type="submit"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginLeft: "auto",
                              marginRight: "auto",
                            }}
                            disabled={processing}
                          >
                            Send message
                            {!processing ? null : (
                              <div
                                className="spinner-border"
                                style={{
                                  width: "25px",
                                  height: "25px",
                                  marginLeft: "10px",
                                }}
                                role="status"
                              >
                                <span className="sr-only">Loading...</span>
                              </div>
                            )}
                          </MDBBtn>
                        </div>
                      </form>
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
                <MDBCol
                  md="12"
                  lg="6"
                  className="text-left mx-auto pl-lg-5 primary-text mb-5"
                >
                  <MDBCol md="12">
                    <h2 className="text-left h3-responsive mx-auto mb-4 font-nanum-eb">
                      Our Office Locations
                    </h2>
                  </MDBCol>
                  <MDBCol md="12">
                    <div className="sf-compact text-left h4-responsive mx-auto mb-4 font-nanum-eb">
                      Christchurch City
                    </div>
                    <p>
                      <i className="fa fa-phone mr-3" />
                      <a href="tel:033666087">03 366 6087</a>
                    </p>
                    <p style={{ display: "flex" }}>
                      <i className="fa fa-envelope mr-3 mt-1" />
                      <div>
                        PO Box 1129
                        <br /> Christchurch 8140
                      </div>
                    </p>

                    <MDBCol
                      md="10"
                      lg="10"
                      className="mb-0 m-lr-auto mb-4 pl-0"
                    >
                      <p style={{ display: "flex" }}>
                        <i className="fa fa-map-marker-alt mr-3 mt-1" />
                        <div>
                          BOXed Quarter Level 1, 270 St Asaph Street
                          <br />
                          <span style={{ whiteSpace: "nowrap" }}>
                            Christchurch Central 8011
                          </span>
                        </div>
                      </p>
                    </MDBCol>
                    <div className="map-container">
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2892.359277022619!2d172.6402116157674!3d-43.536552190267685!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d318a18c8db149d%3A0xa41132f46adea38!2sBoxed%20Quarter!5e0!3m2!1sen!2snz!4v1612349054269!5m2!1sen!2snz"
                        width="100%"
                        height="100%"
                        frameborder="0"
                        style={{ border: 0 }}
                        allowfullscreen=""
                        aria-hidden="false"
                        tabindex="0"
                      />
                    </div>
                  </MDBCol>
                  <MDBCol md="12">
                    <div className="sf-compact text-left h4-responsive mx-auto mb-4 font-nanum-eb">
                      Lincoln
                    </div>
                    <p>
                      <i className="fa fa-phone mr-3" />
                      <a href="tel:033252409">03 325 2409</a>
                    </p>
                    <p style={{ display: "flex" }}>
                      <i className="fa fa-envelope mr-3 mt-1" />
                      <div>
                        PO Box 69058
                        <br /> Lincoln 7640
                      </div>
                    </p>
                    <MDBCol
                      md="10"
                      lg="10"
                      className="mb-0 m-lr-auto mb-4 pl-0"
                    >
                      <p style={{ display: "flex" }}>
                        <i className="fa fa-map-marker-alt mr-3 mt-1" />
                        <div>
                          Unit 14, 8 Vernon Drive
                          <br />
                          Lincoln 7608
                        </div>
                      </p>
                    </MDBCol>
                    <div className="map-container">
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2887.3107303073853!2d172.47260771644883!3d-43.64170307912173!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d321bdf66ba62e7%3A0xc9d8b139bd0a9602!2s8%20Vernon%20Drive%2C%20Lincoln%207608!5e0!3m2!1sen!2snz!4v1637103435015!5m2!1sen!2snz"
                        width="100%"
                        height="100%"
                        frameborder="0"
                        style={{ border: 0 }}
                        allowfullscreen=""
                        aria-hidden="false"
                        tabindex="0"
                      ></iframe>{" "}
                    </div>
                  </MDBCol>
                  <MDBCol md="12">
                    <div className="sf-compact text-left h4-responsive mx-auto mb-4 font-nanum-eb">
                      Auckland
                    </div>
                    <p>
                      <i className="fa fa-phone mr-3" />
                      <a href="tel:099210232">09 921 0232</a>
                    </p>
                    <p style={{ display: "flex" }}>
                      <i className="fa fa-envelope mr-3 mt-1" />
                      <div>
                        PO Box 1963, Shortland Street
                        <br /> Auckland 1140
                      </div>
                    </p>
                    <MDBCol
                      md="10"
                      lg="10"
                      className="mb-0 m-lr-auto mb-4 pl-0"
                    >
                      <p style={{ display: "flex" }}>
                        <i className="fa fa-map-marker-alt mr-3 mt-1" />
                        <div>
                          Level 10, 34 Shortland Street
                          <br /> Auckland 1010
                        </div>
                      </p>
                    </MDBCol>
                    <div className="map-container">
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3192.8304879261054!2d174.76557571634336!3d-36.8465355799391!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d0d47fb85ec8e99%3A0x7febf41bbc4f8fa5!2s34%20Shortland%20Street%2C%20Auckland%20CBD%2C%20Auckland%201010!5e0!3m2!1sen!2snz!4v1637103528613!5m2!1sen!2snz"
                        width="100%"
                        height="100%"
                        frameborder="0"
                        style={{ border: 0 }}
                        allowfullscreen=""
                        aria-hidden="false"
                        tabindex="0"
                      ></iframe>
                    </div>
                  </MDBCol>
                  <MDBCol md="12">
                    <div className="sf-compact text-left h4-responsive mx-auto mb-4 font-nanum-eb">
                      Tauranga
                    </div>
                    <p>
                      <i className="fa fa-phone mr-3" />
                      <a href="tel:079284818">07 928 4818</a>
                    </p>
                    <p style={{ display: "flex" }}>
                      <i className="fa fa-envelope mr-3 mt-1" />
                      <div>
                        PO Box 13314, Tauranga Central
                        <br /> Tauranga 3141
                      </div>
                    </p>
                    <MDBCol
                      md="10"
                      lg="10"
                      className="mb-0 m-lr-auto mb-4 pl-0"
                    >
                      <p style={{ display: "flex" }}>
                        <i className="fa fa-map-marker-alt mr-3 mt-1" />
                        <div>
                          Level 1, 89 Grey Street
                          <br /> Tauranga 3142
                        </div>
                      </p>
                    </MDBCol>
                    <div className="map-container">
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3157.422041548309!2d176.1656348158022!3d-37.686286279775715!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d6ddbdc5a065395%3A0x4dac2538d740558b!2s89%20Grey%20Street%2C%20Tauranga%203110!5e0!3m2!1sen!2snz!4v1649045416665!5m2!1sen!2snz"
                        width="100%"
                        height="100%"
                        frameborder="0"
                        style={{ border: 0 }}
                        allowfullscreen=""
                        aria-hidden="false"
                        tabindex="0"
                      ></iframe>
                    </div>
                  </MDBCol>
                  <MDBCol md="12">
                    <div className="sf-compact text-left h4-responsive mx-auto mb-4 font-nanum-eb">
                      Rolleston
                      <span style={{ fontSize: 12 }}>(Wednesday's Only)</span>
                    </div>
                    <MDBCol
                      md="10"
                      lg="10"
                      className="mb-0 m-lr-auto mb-4 pl-0"
                    >
                      <p>
                        <i className="fa fa-phone mr-3" />
                        <a href="tel:033252409">03 325 2409</a>
                      </p>
                      <p style={{ display: "flex" }}>
                        <i className="fa fa-map-marker-alt mr-3 mt-1" />
                        <div>
                          68 Rolleston Drive
                          <br /> Rolleston 7614
                        </div>
                      </p>
                    </MDBCol>
                    <div className="map-container">
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2889.5647940528625!2d172.38389701576932!3d-43.59478059402283!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d32034a81d99607%3A0x41ce049cccbce362!2s68%20Rolleston%20Drive%2C%20Rolleston%207614!5e0!3m2!1sen!2snz!4v1612349610804!5m2!1sen!2snz"
                        width="100%"
                        height="100%"
                        frameborder="0"
                        style={{ border: 0 }}
                        allowfullscreen=""
                        aria-hidden="false"
                        tabindex="0"
                      ></iframe>{" "}
                    </div>
                  </MDBCol>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
            {/* <div>
                            <iframe className="map" title="Neovia Advisory Christchurch Map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11650.796707927037!2d172.64185177435283!3d-43.531987475890816!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d318a89fdff9897%3A0x4400ce078483bdea!2sNeovia%20Group%20Limited!5e0!3m2!1sen!2snz!4v1605437718242!5m2!1sen!2snz" width="100%" height="100%" frameBorder="0" style={{ border: "0" }} allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>                </div> */}
          </div>
        </div>
      </div>
    </HelmetProvider>
  );
};

export default withRouter(ContactUsPage);

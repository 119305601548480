import {
  MDBAnimation,
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBView,
} from "mdbreact";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { GrClose } from "react-icons/gr";
import { withRouter } from "react-router-dom";
import { STRAPI_URL } from "../../data/DataRequestor";
import { urlEncodeTitle } from "../../helpers/UrlEncode";
import { FORMS_URL } from "../../utils/constants";

import {
  COMPONENT_TYPE_ENUM,
  getFormSection,
  updateForm,
} from "../Checklist/ChecklistHelpers";
import { generatePDF } from "../Checklist/PDF/Form";
import ScrollToTop from "../Nav/ScrollToTop";

import pdfMake from "pdfmake/build/pdfmake";

import moment from "moment";
import { Reload } from "../Loader/Reload";
import { SpinnerCustom } from "../Loader/SpinnerCustom";
import "./style.css";

const locationsUrl = STRAPI_URL + "/locations";

const Resources = (props) => {
  const react = React;
  // const setCurrentForm = useChecklistStore((state) => state.clearForm);
  // const clearDataState = useFormDataStore((state) => state.clearData);
  const [forms, setForms] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [locations, setLocations] = useState([]);
  const [selectedForm, setSelectedForm] = useState(null);

  const questionnaires = useRef(null);

  const toggleModal = (form) => {
    if (selectedForm) {
      setSelectedForm(null);
    } else {
      setSelectedForm(form);
    }
  };

  const load = useCallback(() => {
    try {
      setLoading(true);
      setError(null);
      getLocations();
      getForms();
    } catch (e) {
      setError(true);
    }
    setLoading(false);
  });

  const getLocations = async () => {
    const res = await fetch(locationsUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (res.status === 200) {
      const resJson = await res.json();
      if (resJson && resJson.length > 0) {
        setLocations(resJson);
      }
    }
  };

  const getForms = async () => {
    const res = await fetch(FORMS_URL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (res.status === 200) {
      const resJson = await res.json();
      if (resJson && resJson.length > 0) {
        setForms(resJson);
      }
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    try {
      const anchor = window.location.href.slice(
        window.location.href.indexOf("#")
      );
      if (anchor && anchor === "#questionnaires") {
        questionnaires.current.scrollIntoView({ behavior: "smooth" });
      } else {
        window.scrollTo({ top: 0, left: 0, behavior: "auto" });
      }
    } catch (e) {
      // TODO: add alert popup
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window?.location?.href]);

  // useEffect(() => {
  //   clearFormState();
  //   clearDataState();
  // }, [clearDataState, clearFormState]);

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCompleteOnlineClick = () => {
    try {
      const url = `/questionnaires/${urlEncodeTitle(selectedForm?.name)}`;
      window.open(url, "_blank");
      setSelectedForm(null);
    } catch (e) {
      // TODO: add alert popup
    }
  };

  const pdfName = (name) => {
    if (!name) return null;

    const nameSplit = name.toLowerCase().split(" ");

    return nameSplit.join("_");
  };

  const generateDownloadableForm = async (form) => {
    const docDefinition = generatePDF(form, true);
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    const now = moment();
    pdfDocGenerator.getBlob((blob) => {
      var a = document.createElement("a");
      // force browser to act as though this is a binary file, thereby allowing for the real file name to display in the url
      var file = new Blob([blob], {
        type: "application/octet-stream",
      });
      a.href = URL.createObjectURL(file);
      a.target = "_blank";
      // the custom file name
      a.download = `neovia_${pdfName(form.name)}_${now.format(
        "ddd-DD-MMM-yyyy"
      )}.pdf`;
      a.click();
    });
    await new Promise((res) =>
      setTimeout(() => {
        res();
      }, 1000)
    );
  };

  const handleDownloadPdfClick = async () => {
    try {
      const repeatableSections = [];
      for (let i = 0; i < selectedForm?.sections?.length; i++) {
        const s = selectedForm?.sections[i];

        if (s.__component === COMPONENT_TYPE_ENUM.REPEATABLE_GROUP && s.id) {
          const res = await getFormSection(s.id);
          if (res) {
            s.relatedFormName = res.name;
            repeatableSections.push(res);
          }
        }
      }
      const builtForm = await updateForm(selectedForm, locations);
      await generateDownloadableForm(builtForm);
      repeatableSections.forEach(async (s) => {
        await generateDownloadableForm(s);
      });
      setSelectedForm(null);
    } catch (e) {
      // TODO: add alert popup
    }
  };

  return (
    <HelmetProvider>
      {" "}
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Resources | Neovia Advisory</title>
          <link
            rel="canonical"
            href="https://www.neovia.co.nz/accounting-resources"
          />
        </Helmet>
        <ScrollToTop />
        <div className="resources-header d-flex justify-content-center align-items-center page-header header-filter clear-filter bg-gradient-overlay">
          <div className="header-text">
            <h2 className="h4-responsive text-left center font-weight-md mb-4 pl-lg-2 secondary-text font-nanum-b sf-compact">
              Resources
            </h2>
            <h1 className="h1-responsive text-left center font-weight-md mb-4 pl-lg-2 font-nanum-b">
              Explore our financial resources.
            </h1>
          </div>
        </div>
        <div id="about">
          <MDBAnimation reveal type="fadeIn" className="slow">
            <div>
              <MDBRow center style={{ padding: "0", margin: "0" }}>
                <MDBCol
                  sm="12"
                  md="12"
                  lg="4"
                  className="no-padding-margin container"
                >
                  <MDBView className="blue-bg">
                    <a
                      href="https://www.ird.govt.nz/index/calculators-and-tools"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="tblue-text"
                    >
                      <img
                        src={require("../../assets/img/calculator.png")}
                        className="img-fluid img-resources"
                        alt="tailored approach"
                      />
                      <div className="resource-image-title">
                        <h2 className="h3-responsive white-text font-nanum-eb">
                          Calculators
                        </h2>
                        {/* <h6 className="h6-responsive font-nanum-b">Provide a personalized and individual approach. */}
                        {/* </h6> */}
                      </div>
                    </a>
                  </MDBView>
                </MDBCol>

                <MDBCol
                  sm="12"
                  md="12"
                  lg="4"
                  className="no-padding-margin container"
                >
                  <MDBView className="blue-bg">
                    <a
                      href="https://www.ird.govt.nz/index/key-dates"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="white-text"
                    >
                      <img
                        src={require("../../assets/img/dates.png")}
                        className="img-fluid img-resources"
                        alt="problem solvers"
                      />
                      <div className="resource-image-title">
                        <h2 className="h3-responsive white-text font-nanum-eb">
                          Key Dates
                        </h2>
                        {/* <h6 className="h6-responsive font-nanum-b">Solve problems, provide quality solutions, and seek new opportunities to improve our clients’ business performance.
                                        </h6> */}
                      </div>
                    </a>
                  </MDBView>
                </MDBCol>
                <MDBCol
                  sm="12"
                  md="12"
                  lg="4"
                  className="no-padding-margin container primary-bg"
                >
                  <div style={{ minHeight: 250 }}>
                    <div className="center-title">
                      <h2 className="h3-responsive font-nanum-eb mb-4 white-text">
                        Need help?
                      </h2>
                      <MDBBtn
                        size="lg"
                        outline
                        color="white"
                        onClick={() => this.props.history.push("/contact-us")}
                      >
                        Get in touch
                      </MDBBtn>
                    </div>
                  </div>
                </MDBCol>
              </MDBRow>
            </div>
          </MDBAnimation>
        </div>
        <div id="questionnaires" ref={questionnaires} className="mt-5">
          <MDBContainer>
            <h2 className="display-h3 font-weight-md mb-4 tblue-text font-nanum-eb">
              Questionnaires & Checklists
            </h2>
            <div className=" mb-5 h3-responsive secondary-text">
              Let's get started.
            </div>
            <p>
              Thank you for taking the time to complete the questionnaires.
              Please attempt to answer every question. The more information you
              can provide us, the more time we will both save.
            </p>
            <p className="pb-5">
              You may be required to complete more than one type of
              questionnaire depending on the entities that are applicable to
              you. If you are unsure if this form applies to you, or how many
              forms you are required to complete, please contact us.
            </p>
            <hr
              style={{ backgroundColor: "var(--info-color)" }}
              className="mt-5 mb-5"
            />
            <div>
              <MDBRow>
                {loading && <SpinnerCustom size="30px" />}
                {!loading && error && (
                  <Reload
                    message="We failed to load our questionnaires & checklists, please try again."
                    callback={load}
                  />
                )}
                {!error &&
                  forms &&
                  forms.map((form, i) => (
                    <MDBCol key={i} md="4" className="mb-5">
                      <div
                        className="text-left"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                        key={i}
                      >
                        <div
                          className="secondary-text"
                          style={{ marginBottom: "0.5em", textAlign: "center" }}
                        >
                          {form?.name}
                        </div>
                        {form?.description && (
                          <p className="mt-2" style={{ width: 250 }}>
                            {form?.description}
                          </p>
                        )}
                        <MDBBtn
                          className="orange-btn text-bold"
                          style={{ marginLeft: 0, minWidth: "180px" }}
                          onClick={() => toggleModal(form)}
                        >
                          {form?.button_label
                            ? form?.button_label
                            : "Online Checklist"}
                        </MDBBtn>
                      </div>
                    </MDBCol>
                  ))}
              </MDBRow>
            </div>
            <hr
              style={{ backgroundColor: "var(--info-color)" }}
              className="mt-5 mb-5"
            />
          </MDBContainer>
        </div>
        <div className="padding-lg">
          <MDBContainer>
            <h2 className="display-h3 font-weight-md text-left mb-4 tblue-text font-nanum-eb">
              Our partners
            </h2>
            <div className=" mb-5 text-left h4-responsive">
              Businesses we work with in partnership to support clients, save
              them money and make life easier.
            </div>
            <MDBAnimation reveal type="fadeIn" className="slow pt-4 pb-4">
              <MDBRow className="text-left">
                <MDBCol md="12">
                  <div className="h5-responsive font-weight-md mb-3  font-nanum-eb">
                    Tax Management NZ
                  </div>
                </MDBCol>
                <MDBCol md="12">
                  <div className="h5-responsive font-weight-md mb-3  font-nanum-eb">
                    SmartAR
                  </div>
                </MDBCol>
                <MDBCol md="12">
                  <div className="h5-responsive font-weight-md mb-3  font-nanum-eb">
                    Accountancy Insurance
                  </div>
                </MDBCol>
                <MDBCol md="12">
                  <div className="h5-responsive font-weight-md mb-3  font-nanum-eb">
                    ACCelerate
                  </div>
                </MDBCol>
              </MDBRow>
            </MDBAnimation>
          </MDBContainer>
        </div>
      </div>
      {selectedForm && (
        <div
          style={{
            position: "fixed",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            top: 0,
            right: 0,
            width: "100%",
            height: "100vh",
            backgroundColor: "rgba(0,0,0,0.5)",
            zIndex: 990,
          }}
        >
          <div
            style={{
              position: "relative",
              minWidth: "200px",
              // minHeight: "100px",
              backgroundColor: "#FFF",
              padding: "20px",
              paddingTop: "30px",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "20px",
                right: "20px",
                fontWeight: 600,
                cursor: "pointer",
              }}
              onClick={() => setSelectedForm(null)}
            >
              <GrClose />
            </div>
            <div className="secondary-text" style={{ marginBottom: "1.25rem" }}>
              {selectedForm?.name}
            </div>
            {selectedForm?.description && (
              <p className="mt-2">{selectedForm?.description}</p>
            )}
            <div>
              <MDBBtn
                className="orange-btn text-bold"
                style={{ marginLeft: 0 }}
                onClick={() => handleCompleteOnlineClick()}
              >
                Complete Online
              </MDBBtn>
              <MDBBtn
                className="orange-btn text-bold"
                style={{ marginLeft: 0 }}
                onClick={async () => handleDownloadPdfClick()}
              >
                Download PDF
              </MDBBtn>
            </div>
          </div>
        </div>
      )}
    </HelmetProvider>
  );
};

export default withRouter(Resources);

import React from 'react'

export const Spinner = () => (
  <div
    className='spinner-border'
    role='status'
    style={{
      height: '15px',
      width: '15px',
      verticalAlign: 'middle',
      marginLeft: '5px',
      marginBottom: '2px',
      color: 'white !important'
    }}
  >
    <span className='sr-only'></span>
  </div>
)

import {
  MDBAnimation,
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBMask,
  MDBRow,
} from "mdbreact";
import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { withRouter } from "react-router-dom";
import DataRequestor, { GET, STRAPI_URL } from "../../data/DataRequestor";
import ScrollToTop from "../Nav/ScrollToTop";
// import ReactGA from 'react-ga';

import "./style.css";

class Strategic extends React.Component {
  // componentDidMount() {
  //     ReactGA.initialize('UA-103173484-2');
  //     ReactGA.pageview('/data-company');
  // }

  constructor(props) {
    super(props);
    this.state = {
      requestor: new DataRequestor(),
      data: [],
    };
  }

  componentDidMount() {
    const adamUrl = STRAPI_URL + "/team-members/15";
    this.state.requestor.fetch(this, adamUrl, GET);
  }

  fetchSuccess(data) {
    if (data) {
      this.setState({
        data: data,
      });
    }
  }

  render() {
    return (
      <HelmetProvider>
        <div>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Strategic Advisory | Neovia Advisory</title>
            <link
              rel="canonical"
              href="https://www.neovia.co.nz/strategic-advisory"
            />
          </Helmet>
          <ScrollToTop />
          <div className="strategic-header d-flex justify-content-center align-items-center page-header header-filter clear-filter bg-gradient-overlay">
            <div className="header-text">
              <h2 className="h4-responsive text-left center font-weight-md mb-4 pl-lg-2 secondary-text font-nanum-b sf-compact">
                Strategic Advisory
              </h2>
              <h1 className="h1-responsive text-left center font-weight-md mb-4 pl-lg-2 font-nanum-b">
                Navigate your business through changing times.
              </h1>
            </div>
          </div>
          <div id="about">
            <div className="padding-lg">
              <MDBAnimation reveal type="fadeIn" className="slow">
                <MDBContainer className="pt-5 pb-5">
                  <MDBRow>
                    <MDBCol md="5" className="mx-auto text-center pb-4">
                      <h2 className="display-h2 font-weight-md text-lg-right text-left mb-3 tblue-text font-nanum-eb">
                        Set your business up for success.
                      </h2>
                      <h6 className="h5-responsive text-lg-right text-left font-space-xs mt-lg-5 font-nanum-eb sf-pro-tight">
                        We help you create a high-performance, profitable
                        business with our strategic advisory.
                      </h6>
                    </MDBCol>
                    <MDBCol
                      md="7"
                      className="mx-auto mt-lg-5 pl-lg-5 text-right"
                    >
                      <div className="mt-lg-5">
                        <h6 className="h5-responsive font-weight-md text-left mb-2 font-space-xs">
                          Navigate your business through challenging times and
                          set opportunities for growth and improved performance.
                          For any number of reasons either within your control
                          or outside, businesses are continually facing
                          challenges that can leave them struggling and looking
                          for guidance, expertise and solutions.
                        </h6>
                        <h6 className="h5-responsive text-left font-space-xs">
                          At Neovia we have a desire to partner with our
                          clients, to be part of their business and to provide
                          the strategic advisory support that’s required to keep
                          the business performing optimally.
                        </h6>
                        {/* <MDBBtn outline color="primary" className="mt-4" onClick={() => this.props.history.push('/financial-experts')} >Meet our team</MDBBtn> */}
                      </div>
                    </MDBCol>
                  </MDBRow>
                </MDBContainer>
              </MDBAnimation>
            </div>
          </div>

          <MDBAnimation reveal type="fadeIn" className="slow">
            <div className="strategic-banner-1 page-header header-filter clear-filter blue-filter secondary-text">
              <MDBMask overlay="black-strong" className="w-100 pt-5 pb-5">
                <MDBCol md="10" lg="9" xl="8" className="mx-auto">
                  <MDBContainer className="pt-5 pb-5 text-left">
                    <div className="h3-responsive mb-4 mt-2 font-nanum-eb white-text">
                      Neovia can help owners and managers provide strategies to
                      turn a business around so it’s a viable long-term
                      investment.
                    </div>
                    <h6
                      className="white-text mb-4 font-weight-md"
                      style={{ letterSpacing: "0.5px" }}
                    >
                      In some cases, we can prepare a business for sale or
                      winding-up, if there are no other options.
                    </h6>
                  </MDBContainer>
                </MDBCol>
              </MDBMask>
            </div>
          </MDBAnimation>

          <MDBAnimation reveal type="fadeIn" className="slow">
            <div className="padding-lg">
              <div className="pt-lg-5 pb-lg-5">
                <MDBContainer className="pt-2">
                  <MDBRow className="mx-auto">
                    <MDBCol md="12">
                      <h2 className="display-h3 tblue-text font-nanum-eb text-left mb-5">
                        What we do.
                      </h2>
                      <h6 className="h4-responsive text-left primary-text mb-5 font-nanum-eb">
                        To help create a high-performance, profitable business
                        we can help you with:
                      </h6>
                    </MDBCol>
                    <MDBCol md="7" className="mx-auto">
                      <div className="font-nanum-b">
                        <h6 className="bullet-point h5-responsive text-left primary-text mb-5 font-nanum-b">
                          <MDBIcon
                            icon="chevron-right"
                            className="mr-3 sky-text"
                          />
                          Business performance assessment and review.
                        </h6>
                        <h6 className="bullet-point h5-responsive text-left primary-text mb-5 font-nanum-b">
                          <MDBIcon
                            icon="chevron-right"
                            className="mr-3 sky-text"
                          />
                          Strategic business and people planning.
                        </h6>
                        <h6 className="bullet-point h5-responsive text-left primary-text mb-5 font-nanum-b">
                          <MDBIcon
                            icon="chevron-right"
                            className="mr-3 sky-text"
                          />
                          Company culture and engagement.
                        </h6>
                        <h6 className="bullet-point h5-responsive text-left primary-text mb-5 font-nanum-b">
                          <MDBIcon
                            icon="chevron-right"
                            className="mr-3 sky-text"
                          />
                          Employee performance review and improvement.
                        </h6>
                        <h6 className="bullet-point h5-responsive text-left primary-text mb-5 font-nanum-b">
                          <MDBIcon
                            icon="chevron-right"
                            className="mr-3 sky-text"
                          />
                          Financial projections and cash flow forecasting.
                        </h6>
                        <h6 className="bullet-point h5-responsive text-left primary-text mb-5 font-nanum-b">
                          <MDBIcon
                            icon="chevron-right"
                            className="mr-3 sky-text"
                          />
                          Business and share valuations.
                        </h6>
                        <h6 className="bullet-point h5-responsive text-left primary-text mb-5 font-nanum-b">
                          <MDBIcon
                            icon="chevron-right"
                            className="mr-3 sky-text"
                          />
                          Business sale and purchase including due diligence.
                        </h6>
                        <h6 className="bullet-point h5-responsive text-left primary-text mb-5 font-nanum-b">
                          <MDBIcon
                            icon="chevron-right"
                            className="mr-3 sky-text"
                          />
                          Finance applications and structure.
                        </h6>
                        <h6 className="bullet-point h5-responsive text-left primary-text mb-5 font-nanum-b">
                          <MDBIcon
                            icon="chevron-right"
                            className="mr-3 sky-text"
                          />
                          Business mentoring and coaching.
                        </h6>
                        <h6 className="bullet-point h5-responsive text-left primary-text mb-5 font-nanum-b">
                          <MDBIcon
                            icon="chevron-right"
                            className="mr-3 sky-text"
                          />
                          Apply continuous improvement tools such as Spotlight
                          reporting to drive efficiency and operational
                          performance improvement.
                        </h6>
                      </div>
                    </MDBCol>
                    <MDBCol md="5" className="mx-auto">
                      <div className="center text-center">
                        <img
                          src={"/loader/new/swirl.svg"}
                          alt="neovia swirl"
                          className="swirl-small img-fluid mb-5"
                        />
                      </div>
                    </MDBCol>
                  </MDBRow>
                </MDBContainer>
              </div>
            </div>
          </MDBAnimation>

          <div className="strategic-banner-2 page-header header-filter clear-filter blue-filter white-text">
            <MDBMask overlay="black-strong" className="w-100 pt-5 pb-5">
              <MDBCol md="10" lg="9" xl="8" className="mx-auto">
                <MDBContainer className="pt-5 pb-5 text-left">
                  <div className="h3-responsive mb-4 mt-2 font-nanum-eb">
                    Watch your business performance improve with advice and
                    support from our Strategic Advisory team.
                  </div>
                  {/* <MDBBtn outline color="white" onClick={() => this.props.history.push('/contact-us')}>Book an appointment</MDBBtn> */}
                </MDBContainer>
              </MDBCol>
            </MDBMask>
          </div>

          <div className="contact-div text-left">
            <MDBContainer className="mt-5 mb-5">
              <MDBRow className="mx-auto">
                <MDBCol md="7">
                  <h3 className="tblue-text mb-4 font-nanum-eb font-weight-md">
                    Get in touch with Jo McMaster-Finch
                  </h3>
                  <h6 className="mb-4 font-nanum-eb font-weight-md">
                    Contact our Strategic Advisor for a no obligation
                    consultation to find out more about how we can help you.
                  </h6>

                  <MDBRow className="font-nanum-b mb-3">
                    <MDBCol md="2" lg="2" className="mb-0 m-lr-auto">
                      <p>
                        <b className="font-quatBold">Phone</b>
                      </p>
                    </MDBCol>
                    <MDBCol md="10" lg="10" className="mb-0 m-lr-auto">
                      <p>
                        <a className="secondary-text" href="tel:+6433666087">
                          +64 3 366 6087{" "}
                        </a>
                      </p>
                    </MDBCol>
                    <MDBCol md="2" lg="2" className="mb-0 m-lr-auto">
                      <p>
                        <b className="font-quatBold">Email</b>
                      </p>
                    </MDBCol>
                    <MDBCol md="10" lg="10" className="mb-0 m-lr-auto">
                      <p>
                        <a
                          className="secondary-text"
                          href="mailto:jo@neovia.co.nz"
                        >
                          jo@neovia.co.nz
                        </a>
                      </p>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <div className="text-lg-left text-center">
                      <MDBBtn color="primary" className="min-220px">
                        <a className="white-text" href="mailto:jo@neovia.co.nz">
                          Send Jo an email
                        </a>
                      </MDBBtn>
                    </div>
                    <div className="text-lg-left text-center">
                      <MDBBtn
                        outline
                        color="primary"
                        onClick={() => this.props.history.push("/contact-us")}
                      >
                        Book an appointment
                      </MDBBtn>
                    </div>
                  </MDBRow>
                </MDBCol>
                <MDBCol md="5" className="text-center mt-lg-0 mt-4">
                  {this.state.data &&
                  this.state.data.image &&
                  this.state.data.image.url ? (
                    <img
                      src={this.state.data.image.url}
                      className="img-fluid contact-card"
                    />
                  ) : (
                    <img
                      src={require("../../../src/assets/img/Jo.jpg")}
                      className="img-fluid contact-card"
                    />
                  )}
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </div>
        </div>
      </HelmetProvider>
    );
  }
}

export default withRouter(Strategic);

import create from "zustand";
import { persist } from "zustand/middleware";

const clearFormData = (data) => {
  const clearedData = { ...data };
  Object.keys(data).forEach((k) => {
    clearedData[k] = "";
  });
  return clearedData;
};

const updateValueByKey = (key, value, data) => {
  let d = { ...data };
  d[key] = value;

  return d;
};

export const useFormDataStore = create(
  persist(
    (set, get) => ({
      data: [],
      getData: (id) =>
        get((state) => {
          try {
            const index = state.data.findIndex((f) => f.id === id);
            if (index !== -1) {
              const storedFormData = state.data[index];
              return storedFormData.form;
            }
          } catch (e) {}
        }),
      setData: (id, form) =>
        set((state) => {
          try {
            const index = state.data.findIndex((f) => f.id === id);
            if (index !== -1) {
              // const formData = [...state.data];
              // formData[index] = { id: id, form: form };
              // return { data: formData };
            } else {
              return { data: [...state.data, { id: id, form: form }] };
            }
          } catch (e) {}
        }),
      // addData: (id, data) =>
      //   set((state) => ({ data: { ...state.data, ...data } })),
      updateValue: (id, key, value) =>
        set((state) => {
          try {
            const index = state.data.findIndex((f) => f.id === id);
            if (index !== -1) {
              const formData = [...state.data];
              const updatedFormData = updateValueByKey(
                key,
                value,
                formData[index].form
              );

              formData[index] = { id: id, form: updatedFormData };
              return { data: formData };
            }
          } catch (e) {}
        }),
      clearForm: (id) =>
        set((state) => {
          const index = state.data.findIndex((d) => d.id === id);
          if (index !== -1) {
            try {
              let updatedData = [...state.data];
              updatedData.splice(index, 1);
              return { data: updatedData };
            } catch (e) {}
          }
        }),
      clearData: () => set((state) => ({ data: [] })),
    }),
    {
      name: "neovia-forms-data",
      getStorage: () => localStorage,
      version: 5,
    }
  )
);

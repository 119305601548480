import { MDBAnimation, MDBBtn, MDBCol, MDBContainer, MDBRow } from "mdbreact";
import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Link, withRouter } from "react-router-dom";
import DataRequestor, { GET, STRAPI_URL } from "../../data/DataRequestor";
import ScrollToTop from "../Nav/ScrollToTop";

const FILE_SIZE_LIMIT = 10000000;

const applicationCreateUrl = STRAPI_URL + "/vacancy-applications";

const fileToBlob = async (file) =>
  new Blob([new Uint8Array(await file.arrayBuffer())], { type: file.type });

class ApplicationForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.vacancyId,
      vacancyTitle: props.vacancyTitle,
      data: {
        name: {
          value: "",
          valid: false,
        },
        email: {
          value: "",
          valid: false,
        },
        phone: {
          value: "",
          valid: false,
        },
        coverLetter: {
          value: null,
          valid: false,
        },
        cv: {
          value: null,
          valid: false,
        },
      },
      submitted: false,
      submitting: false,
      success: false,
    };
  }

  changeHandler(val, type, id) {
    const obj = { ...this.state.data };
    obj[id] = {
      value: val,
      valid: this.validateValue(val, type),
    };
    console.log(obj);
    this.setState({ data: obj });
  }

  async onSubmit(e) {
    if (this.state.submitting) return;

    this.setState({ submitted: true, submitting: true });
    const data = { ...this.state.data };

    for (var key in data) {
      if (data.hasOwnProperty(key)) {
        let isValid = data[key].valid;
        if (!isValid) {
          this.setState({ submitting: false });
          return;
        }
      }
    }

    const formData = new FormData();

    formData.append(
      "data",
      JSON.stringify({
        name: data.name.value,
        email: data.email.value,
        phone: data.phone.value,
        vacancy: { id: this.state.id },
      })
    );

    formData.append(
      `files.file_uploads`,
      await fileToBlob(data.coverLetter.value),
      data.coverLetter.value.name
    );
    formData.append(
      `files.file_uploads`,
      await fileToBlob(data.cv.value),
      data.cv.value.name
    );

    const res = await fetch(applicationCreateUrl, {
      method: "POST",
      body: formData,
    });

    if (res.status === 200) {
      this.setState({ success: true });
    } else {
      window.alert("Something went wrong, Try again!");
    }
  }

  validateFile(file) {
    if (!file) return;
    if (file.size > FILE_SIZE_LIMIT) {
      window.alert(
        "File size must be less than 10mb. Please choose a different file"
      );
    } else {
      return file;
    }
    return null;
  }

  validateLabel(val, type) {
    if (type === "email") {
      return val && val.length > 0
        ? val.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
          ? ""
          : "Invalid email"
        : "Required";
    } else if (type === "phone") {
      return val && val.length > 0
        ? val.replace(/\s/g, "").match(/^\d{8,15}$/)
          ? ""
          : "Invalid phone number"
        : "Required";
    } else if (type === "file") {
      return val && val instanceof File ? "" : "Required";
    } else if (type === "text") {
      return val && val.length > 0 ? "" : "Required";
    }
    return "Required";
  }

  validateValue(val, type) {
    if (type === "email") {
      return val && val.length > 0
        ? val.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
          ? true
          : false
        : false;
    } else if (type === "phone") {
      return val && val.length > 0
        ? val.replace(/\s/g, "").match(/^\d{8,15}$/)
          ? true
          : false
        : false;
    } else if (type === "file") {
      return val && val instanceof File ? true : false;
    } else if (type === "text") {
      return val && val.length > 0 ? true : false;
    }
    return false;
  }

  render() {
    return this.state.success ? (
      <JobApplicationSent />
    ) : (
      <MDBCol lg="6" className="justify-end">
        <div className="vacancy-application-body tblue-text">
          <div className="text-left">
            <h3 className="h4-responsive amiko-bold mb-3 text-left">
              Apply now
            </h3>
            <p className="h5-responsive mb-3 amiko-regular text-left">
              Complete your application by filling in the form below. Attach
              your cover letter and CV, or email us directly at&nbsp;
              <a
                href={`mailto:careers@neovia.co.nz?subject=Application | ${this.state.vacancyTitle}`}
              >
                careers@neovia.co.nz
              </a>
              .
            </p>
            <div className="pt-3">
              <div className="job-application-form-group">
                <input
                  autoComplete="off"
                  type="text"
                  className="job-application-form-control tblue-text"
                  id="applicant-name"
                  placeholder="Name"
                  value={this.state.data.name.value}
                  required
                  onChange={(e) =>
                    this.changeHandler(e.target.value, "text", "name")
                  }
                  disabled={this.submitting}
                />
                <label
                  className="job-application-form-label"
                  htmlFor="applicant-name"
                >
                  {this.state.submitted && (
                    <span>
                      {this.validateLabel(this.state.data.name.value, "text")}
                    </span>
                  )}
                </label>
              </div>
              <div className="job-application-form-group">
                <input
                  autoComplete="off"
                  type="email"
                  className="job-application-form-control tblue-text"
                  id="applicant-email"
                  placeholder="Email"
                  value={this.state.data.email.value}
                  required
                  onChange={(e) =>
                    this.changeHandler(e.target.value, "email", "email")
                  }
                  disabled={this.submitting}
                />
                <label
                  className="job-application-form-label"
                  htmlFor="applicant-email"
                >
                  {this.state.submitted && (
                    <span>
                      {this.validateLabel(this.state.data.email.value, "email")}
                    </span>
                  )}
                </label>
              </div>
              <div className="job-application-form-group">
                <input
                  autoComplete="off"
                  type="tel"
                  className="job-application-form-control tblue-text"
                  id="applicant-phone"
                  placeholder="Phone"
                  value={this.state.data.phone.value}
                  required
                  onChange={(e) =>
                    this.changeHandler(e.target.value, "phone", "phone")
                  }
                  disabled={this.submitting}
                />
                <label
                  className="job-application-form-label"
                  htmlFor="applicant-phone"
                >
                  {this.state.submitted && (
                    <span>
                      {this.validateLabel(this.state.data.phone.value, "phone")}
                    </span>
                  )}
                </label>
              </div>
              <div className="job-application-form-group">
                <div className="job-application-file-input-container">
                  <input
                    id="applicant-cover-letter"
                    type="file"
                    accept="application/pdf"
                    className="job-application-file-input tblue-text   display: none;"
                    required
                    onChange={(e) =>
                      this.changeHandler(
                        this.validateFile(e.target.files[0]),
                        "file",
                        "coverLetter"
                      )
                    }
                    disabled={this.submitting}
                  />

                  <label
                    className={`job-application-file-input-label ${
                      this.state.data.coverLetter.value ? "tblue-text" : ""
                    }`}
                    htmlFor="applicant-cover-letter"
                  >
                    {this.state.data.coverLetter.value ? (
                      <span>{this.state.data.coverLetter.value.name}</span>
                    ) : (
                      <span>Attach Cover Letter (.pdf)</span>
                    )}
                  </label>
                </div>
                <label
                  className="job-application-form-label"
                  htmlFor="applicant-cover-letter"
                >
                  {this.state.submitted && (
                    <span>
                      {this.validateLabel(
                        this.state.data.coverLetter.value,
                        "file"
                      )}
                    </span>
                  )}
                </label>
              </div>
              <div className="job-application-form-group">
                <div className="job-application-file-input-container">
                  <input
                    id="applicant-cv"
                    type="file"
                    accept="application/pdf"
                    className="job-application-file-input tblue-text"
                    required
                    onChange={(e) =>
                      this.changeHandler(
                        this.validateFile(e.target.files[0]),
                        "file",
                        "cv"
                      )
                    }
                    disabled={this.submitting}
                  />

                  <label
                    className={`job-application-file-input-label ${
                      this.state.data.cv.value ? "tblue-text" : ""
                    }`}
                    htmlFor="applicant-cv"
                  >
                    {this.state.data.cv.value ? (
                      <span>{this.state.data.cv.value.name}</span>
                    ) : (
                      <span>Attach CV (.pdf)</span>
                    )}
                  </label>
                </div>
                <label
                  className="job-application-form-label"
                  htmlFor="applicant-cv"
                >
                  {this.state.submitted && (
                    <span>
                      {this.validateLabel(this.state.data.cv.value, "file")}
                    </span>
                  )}
                </label>
              </div>
              <div className="job-vacancy-apply-submit-btn-container pt-5">
                <MDBBtn
                  className="sf-pro white-text"
                  color="info"
                  size="lg"
                  style={{ minWidth: "220px", marginLeft: 0 }}
                  onClick={() => this.onSubmit()}
                >
                  {this.state.submitting ? (
                    <div
                      className="spinner-border"
                      role="status"
                      style={{
                        height: "1.5rem",
                        width: "1.5rem",
                        color: "white !important",
                      }}
                    >
                      <span className="sr-only"></span>
                    </div>
                  ) : (
                    "Submit application"
                  )}
                </MDBBtn>
                {/* <button
                  type="button"
                  className="job-vacancy-apply-now-btn text-left uppercase amiko-regular font-weight-thin"
                  onClick={() => this.onSubmit()}
                >
                  {this.state.submitting ? (
                    <div
                      className="spinner-border"
                      role="status"
                      style={{
                        height: "1.5rem",
                        width: "1.5rem",
                        color: "white !important",
                      }}
                    >
                      <span className="sr-only"></span>
                    </div>
                  ) : (
                    "Submit application"
                  )}
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </MDBCol>
    );
  }
}

const JobApplicationSent = () => {
  return (
    <MDBCol lg="6">
      <div className="vacancy-application-sent-body tblue-text">
        <h2 className="display-h5 mb-3 amiko-semibold text-center">
          Thank you for submitting your application. We will be in touch.
        </h2>
      </div>
    </MDBCol>
  );
};

class JobVacancyApplication extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      requestor: new DataRequestor(),
      vacancy: null,
      error: null,
    };
  }

  componentDidMount() {
    const pathSplit = window.location.pathname.split("/");
    const id = pathSplit[pathSplit.length - 1];

    const vacancyDataUrl = `${STRAPI_URL}/vacancies/${id}`;
    this.state.requestor.fetch(this, vacancyDataUrl, GET, null, "vacancy");
  }

  fetchSuccess(data, id) {
    console.log(data);
    this.setState({ vacancy: data });
  }

  fetchFailed(error) {
    console.log(error);
    this.setState({ error: error });
  }

  render() {
    return (
      <HelmetProvider>
        <div className="min-h-90vh">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Job Vacancies | Neovia Advisory | Financial Solutions</title>
            <link
              rel="canonical"
              href="https://www.neovia.co.nz/job-vacancies"
            />
          </Helmet>
          <ScrollToTop />
          <div className="job-vacancy-header d-flex justify-content-center align-items-center page-header header-filter clear-filter">
            <MDBContainer>
              <div className="text-left">
                <h3 className="h4-responsive font-weight-thin amiko-bold mb-1 text-center sky-text">
                  {this.state.vacancy?.location?.location}
                </h3>
                <h2 className="h1-responsive font-weight-thin amiko-bold mb-3 text-center tblue-text">
                  {this.state.vacancy?.Title}
                </h2>
              </div>
            </MDBContainer>
          </div>
          <div id="job-vacancy-application">
            <MDBAnimation reveal type="fadeIn" duration={350}>
              <div className="padding-lg">
                {this.state.vacancy ? (
                  <>
                    <MDBRow>
                      <ApplicationForm
                        vacancyId={this.state.vacancy.id}
                        vacancyTitle={this.state.vacancy.Title}
                      />
                      <MDBCol lg="6">
                        <div className="vacancy-video-container">
                          <hr className="mt-3 mb-1 width-full" />
                        </div>
                      </MDBCol>
                    </MDBRow>
                  </>
                ) : (
                  <MDBContainer>
                    <MDBRow>
                      <MDBCol lg="12">
                        <p className="display-h4 text-left mt-5 mb-5 font-weight-semibold amiko-bold">
                          We are unable to find the job vacancy you're looking
                          for.{" "}
                          <Link to={"/job-vacancies"} className="sky-text">
                            Return to all job vacancies.
                          </Link>
                        </p>
                      </MDBCol>
                    </MDBRow>
                  </MDBContainer>
                )}
              </div>
            </MDBAnimation>
          </div>
        </div>
      </HelmetProvider>
    );
  }
}

export default withRouter(JobVacancyApplication);

import React, { useEffect } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { useChecklistStore } from "../../../stores/checklist";
import { useFileUploadStore } from "../../../stores/file-upload";
import { useFormDataStore } from "../../../stores/form-data";
import "./file-upload.css";
// 10mb
const FILE_SIZE_LIMIT = 10000000;

export const FileUpload = (props) => {
  const react = React;
  const currentForm = useChecklistStore((state) => state.currentForm);
  const files = useFileUploadStore((state) => state.files);
  const uploadFiles = useFileUploadStore((state) => state.setFiles);
  const updateValue = useFormDataStore((state) => state.updateValue);
  const data = useFormDataStore((state) => state.data);

  const { questionKey, question } = props;

  useEffect(() => {
    updateValue(currentForm.id, questionKey, "");
    uploadFiles(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionKey]);

  useEffect(() => {
    const formData = data.find((d) => d.id === currentForm.id);
    if (
      !formData ||
      !formData.form ||
      !formData.form[questionKey] ||
      !formData.form[questionKey].length === 0
    ) {
      uploadFiles(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onFileUpload = async (event) => {
    if (event.target && event.target.files && event.target.files.length > 0) {
      if ([...event.target.files].some((f) => f.size > FILE_SIZE_LIMIT)) {
        window.alert(
          "File size must be less than 10mb. Please choose a different file"
        );
      } else {
        let f = [];
        if (files) {
          f = [...files];
        }
        f = [...f, ...event.target.files];
        uploadFiles(f);
        updateValue(currentForm.id, questionKey, f.map((f) => f.name).join());
      }
    }
  };

  const onFileRemoved = async (name) => {
    if (files && files.length > 0) {
      const index = files.findIndex((f) => f.name === name);
      let f = [...files];
      if (index !== -1) {
        f.splice(index, 1);
        uploadFiles(f);
        updateValue(currentForm.id, questionKey, f.map((f) => f.name).join());
      }
    }
  };

  return (
    <div className="drop-container">
      <div className="drop">
        <span className="mb-2" style={{ whiteSpace: "pre-line" }}>
          {question}
        </span>
        <label htmlFor={questionKey}>Browse Files</label>
        <input
          // onClick={() => {
          //   // updateValue(questionKey, "");
          //   const updatedFiles = [];
          //   if (files) {
          //     updatedFiles = [...files];
          //   }
          //   updatedFiles = [...updatedFiles, ]
          //   uploadFiles(null);
          // }}
          onChange={onFileUpload}
          id={questionKey}
          type="file"
          accept=".pdf,.csv"
          multiple
          className="file-input"
        />
        <span className="format-text">File formats: .pdf or .csv</span>
        <span className="format-text">10mb Max</span>
        {files && (
          <ul className="text-left mt-3" style={{ listStyleType: "none" }}>
            {[...files].map((f, i) => (
              <li
                key={i}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <IoIosCloseCircleOutline
                  style={{ fontSize: "2rem", paddingRight: "5px" }}
                  onClick={() => onFileRemoved(f.name)}
                />
                {f.name}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

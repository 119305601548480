import { MDBBtn, MDBIcon, MDBTooltip } from "mdbreact";
import React from "react";
import { v4 as uuidv4 } from "uuid";
import { useChecklistStore } from "../../../stores/checklist";
import { useFormDataStore } from "../../../stores/form-data";

export const RadioInput = (props) => {
  const react = React;
  const currentForm = useChecklistStore((state) => state.currentForm);
  const data = useFormDataStore((state) => state.data);
  const {
    questionKey,
    question,
    required,
    questionHint,
    answer,
    options,
    extraDetails,
    changeHandler,
    disabled,
  } = props;

  const formData = data.find((d) => d.id === currentForm.id);
  const defaultValue =
    (formData && formData.form && formData.form[questionKey]) || "";

  return (
    <div className="form-group text-left">
      <fieldset id={questionKey}>
        <legend style={{ fontSize: 16, whiteSpace: "pre-line" }}>
          {question}
          {required ? <span style={{ color: "red" }}>*</span> : ""}
          {questionHint ? (
            <MDBTooltip placement="bottom">
              <MDBBtn className="rm-button-style">
                <MDBIcon far icon="question-circle" />
              </MDBBtn>
              <div>{questionHint}</div>
            </MDBTooltip>
          ) : (
            ""
          )}
        </legend>
        <div style={{ color: "#666666" }}>
          {options.map(({ value }) => {
            const key = uuidv4();

            return (
              <div key={key}>
                <label>
                  <input
                    required={required}
                    onChange={changeHandler}
                    disabled={disabled}
                    id={questionKey}
                    name={questionKey}
                    type="radio"
                    value={value}
                    checked={defaultValue === value}
                  />{" "}
                  {value}
                </label>
              </div>
            );
          })}
        </div>
        {/* {extraDetails.length > 0 && <ChecklistBuilder section={extraDetails[0]} changeHandler={changeHandler} loading={disabled} />} */}
      </fieldset>
    </div>
  );
};

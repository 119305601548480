import create from "zustand";
import { persist } from "zustand/middleware";

export const useChecklistStore = create(
  persist(
    (set, get) => ({
      currentForm: null,
      dynamicForm: null,
      setCurrentForm: (form) => set((state) => ({ currentForm: form })),
      setDynamicForm: (form) => set((state) => ({ dynamicForm: form })),
      clearForm: () =>
        set((state) => ({ currentForm: null, dynamicForm: null })),
    }),
    {
      name: "neovia-forms",
      getStorage: () => localStorage,
      version: 5,
    }
  )
);

// Gradients taken from: https://webgradients.com/
export const TeamMembersData = [
    {
      id: 1,
      name: 'Wayne Bailey',
      qual: 'B.Com., C.A.',
      email: 'ewb@neovia.co.nz',
      phone: '+64 3 366 6087',
      mobile: '+64 21 329 498',
      linkedin: 'https://www.linkedin.com/in/wayne-bailey-15994710/',
      image: require('../../assets/img/Wayne.jpg'),
      title: 'Executive Director',
      blurb: 'With over 40 years as a chartered accountant, Wayne is immensely proud of the role he plays helping clients to achieve realistic solutions to their financial situations.<br/><br/>An expert in corporate restructuring, financial proposals and succession planning, Wayne has been a driving force behind Neovia Advisory’s evolution to a team of near 20 people.<br/><br/>A large part of this growth can be attributed to the culture Wayne has helped create. A self-confessed ‘people-person’, Wayne has an innate ability to foster strong, enduring relationships with clients from all walks of life, taking the time to develop a genuine understanding of both their current position, and their goals and aspirations, before providing advice to maximise their preferred outcomes.<br/><br/>Wayne is family oriented, a keen golfer, and an ardent supporter of music and the arts.',
    },
    {
      id: 2,
      name: 'Adam Coleman',
      phone: '+64 3 366 6087',
      mobile: '+64 27 650 4699',
      email: 'ada@neovia.co.nz',
      linkedin: 'https://www.linkedin.com/in/adam-c-4a138119/',
      image: require('../../assets/img/Adam.jpg'),
      title: 'Director',
      blurb: 'Adam joined Neovia with a decade’s accounting and finance experience having worked with a wide range of clients in a broad range of industries.<br /><br />After graduating from Lincoln University, he worked in Christchurch and Dunedin and gained his CA qualification in 2012. This was done in between playing representative cricket both here and in the UK.<br /><br />His special focus is business advisory, where his approach is to first gain an understanding of a client’s business and then align it with personal goals to develop a plan for success. He bases his advice on constant measurement of performance against these goals, using up to date reporting tools. For Adam, the appeal of Neovia is its unique offering of different experts in accountancy, capital and finance, banking, mortgages and legal, all under one roof.<br /><br />His specialty areas include hospitality, construction, engineering, health professionals and tourism and he takes great pride in tailoring advice and solutions based on the specific client’s requirements, while also building long-lasting relationships.<br /><br />Outside work he and his wife are “outdoors” people who enjoy skiing and travel. He is also the Treasurer for the Friends of the Christchurch Art Gallery.'
    },
    {
      id: 3,
      name: 'Merryn O\'Malley',
      phone: '+64 3 366 6087',
      qual: 'B.B.S., C.A.',
      email: 'mom@neovia.co.nz',
      linkedin: 'https://www.linkedin.com/in/merryn-o-malley-38000a3b/',
      image: require('../../assets/img/Merryn.jpg'),
      title: 'Group Financial Controller',
      blurb: 'Merryn is Neovia Advisory’s Financial Controller and was appointed in 2013 having been part of the company’s business services team since 2007.<br/><br/> During her time at Neovia, Merryn has worked with a wide range of clients from different sectors. While her current role is more management focused, she still manages and provides advice to a small number of clients.<br/><br/>Prior to joining Neovia, Merryn worked with many different organisations in a range of sectors including Coast Health Care (now West Coast District Health Board) where clinical costing, business planning and budgeting were her main priorities.<br/><br/>She also managed the accounts for well-known forestry operator Timberlands West Coast Ltd and was responsible for monthly reporting, budgeting and general management of its accounting system.<br/><br/> Merryn has also worked in the banking, transport and motor vehicle industry. She obtained her BBS in Accountancy through Massey University, studying extramurally while working at Coast Health Care and Timberlands, and then finished her CA qualifications while at Neovia.<br/><br/>Merryn lives on a lifestyle block on the outskirts of Christchurch with her husband and two daughters. She enjoys walking and getting involved with family activities.'
    },
    {
      id: 4,
      name: 'Natalie McIlraith',
      phone: '+64 3 366 6087',
      email: 'nam@neovia.co.nz',
      linkedin: 'https://www.linkedin.com/in/natalie-mcilraith-a537631a0/',
      image: require('../../assets/img/Natalie.jpg'),
      title: 'Senior Advisor',
      blurb: 'Natalie has been with Neovia since 2013 and is part of the busy back-office team managing end of year accounts, tax returns and valuations and is responsible for the accuracy and detail needed to support a wide range of clients.<br /><br />She was brought up on a farm in the UK and went on to study Accounting and Management at the University of East Anglia.  She married a Kiwi and arrived in Christchurch in 2005.<br /><br /> Natalie has a broad range of experience having been the finance manager for a local company supplying construction equipment to companies involved in the Christchurch rebuild and she also worked for another accountancy firm.<br /><br />She’s busy at home managing two young boys and keeps fit playing mid-week hockey for the Avon Club, having played premier grade in the past.   She also boxes occasionally, which contrasts greatly with painting and creating which she enjoys when she has some spare time.'
    },
    {
      id: 5,
      name: 'Geoff Angus',
      phone: '+64 3 366 6087',
      email: 'nam@neovia.co.nz',
      linkedin: 'https://www.linkedin.com/in/geoff-angus-5a026118/',
      image: require('../../assets/img/Natalie.jpg'),
      title: 'Senior Advisor',
      blurb: 'Natalie has been with Neovia since 2013 and is part of the busy back-office team managing end of year accounts, tax returns and valuations and is responsible for the accuracy and detail needed to support a wide range of clients.<br /><br />She was brought up on a farm in the UK and went on to study Accounting and Management at the University of East Anglia.  She married a Kiwi and arrived in Christchurch in 2005.<br /><br /> Natalie has a broad range of experience having been the finance manager for a local company supplying construction equipment to companies involved in the Christchurch rebuild and she also worked for another accountancy firm.<br /><br />She’s busy at home managing two young boys and keeps fit playing mid-week hockey for the Avon Club, having played premier grade in the past.   She also boxes occasionally, which contrasts greatly with painting and creating which she enjoys when she has some spare time.'
    }
  ]
  
import {
  MDBBtn,
  MDBCard,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBRow,
} from "mdbreact";
import React, { useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useHistory, withRouter } from "react-router-dom";
import { usePaymentBranch } from "../../stores/payment-branch";
import ScrollToTop from "../Nav/ScrollToTop";
import PaymentForm from "./PaymentForm";
import "./styles.css";

const PaymentPage = () => {
  const history = useHistory();
  const branch = usePaymentBranch((m) => m.branch);

  useEffect(() => {
    if (branch === null) {
      history.push("/payment-options");
    }
  }, [branch, history]);

  return (
    <HelmetProvider>
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Payment Options | Neovia Advisory</title>
          <link rel="canonical" href="https://www.neovia.co.nz/payment" />
        </Helmet>
        <ScrollToTop />
        <div className="payment-header d-flex justify-content-center align-items-center page-header header-filter clear-filter bg-gradient-overlay">
          <div className="header-text">
            <h2 className="h4-responsive text-left center font-weight-md mb-4 pl-lg-2 secondary-text font-nanum-b sf-compact">
              Pay online
            </h2>
            <h1 className="h1-responsive text-left center font-weight-md mb-4 pl-lg-2 font-nanum-b">
              Credit card payment
            </h1>
          </div>
        </div>
        <div id="about" className="mb-5">
          {branch === "christchurch" && <PaymentForm />}
          {branch === "auckland" && (
            <div>
              <MDBContainer className="pt-5 pb-5">
                <MDBRow>
                  <MDBCol md="8" className="mx-auto text-center pb-4">
                    <MDBCard className="text-left">
                      <h2 className="text-left">Auckland / Tauranga branch</h2>
                      <h5 className="h5-responsive text-left font-space-xs">
                        Proceed to complete your payment.
                      </h5>
                      <MDBRow className="mt-lg-5">
                        <MDBCol className="text-left">
                          <MDBBtn
                            color="secondary"
                            type="button"
                            onClick={() => history.push("/payment-options")}
                          >
                            Cancel
                          </MDBBtn>
                        </MDBCol>
                        <MDBCol className="text-right">
                          <MDBBtn color="primary">
                            <a
                              className="external-link"
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://secure.smartpaymentpage.com/pay.aspx?client=ffedfd89-81a5-47bf-a028-718dc4df49d9"
                            >
                              Proceed to payment
                              <MDBIcon icon="arrow-right" className="ml-2" />
                            </a>
                          </MDBBtn>
                        </MDBCol>
                      </MDBRow>
                    </MDBCard>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </div>
          )}
        </div>
      </div>
    </HelmetProvider>
  );
};

export default withRouter(PaymentPage);

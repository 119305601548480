import { MDBCol, MDBRow } from "mdbreact";
import React from "react";

export const ChecklistLayout = (props) => {
  return (
    <div style={{ height: "100vh" }}>
      <MDBRow className="form-row">
        <MDBCol className="form-sidebar-col" md="3">
          <div
            className="form-sidebar"
            style={{
              background: `linear-gradient(0deg, rgba(36, 67, 107, 0.6), rgba(36, 67, 107, 0.6)), url(${require("../../assets/img/form-bg.jpg")})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <button
              style={{ border: 0, background: "none" }}
              onClick={() => props?.history?.push("/accounting-resources")}
            >
              <img
                className="mb-4"
                src="/loader/new/neovia_full_logo_white.png"
                alt="Neovia"
                style={{ width: "clamp(100px, 70%, 500px)" }}
              />
            </button>
          </div>
        </MDBCol>
        <MDBCol>{props?.children}</MDBCol>
      </MDBRow>
    </div>
  );
};

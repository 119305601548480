import React, { Component } from "react";
import Slider from "react-slick";
import { MDBIcon } from 'mdbreact';
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

export default class TitleSlider extends Component {
    render() {
        const settings = {
            dots: false,
            infinite: true,
            speed: 700,
            slidesToShow: 4,
            slidesToScroll: 1,
            autoplay: true,
            autoPlaySpeed: 700,
            pauseOnHover: false,
            // swipe: true,
            arrows: false,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 800,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        return (
            <div>
                <Slider {...settings}>
                    <div>
                        <MDBIcon icon="check" className="mr-3 sky-text" /> Chartered accountants
                    </div>
                    <div>
                        <MDBIcon icon="check" className="mr-3 sky-text" /> Trust advisors
                    </div>
                    <div>
                        <MDBIcon icon="check" className="mr-3 sky-text" /> Strategic advisors
                    </div>
                    <div>
                        <MDBIcon icon="check" className="mr-3 sky-text" /> Financial controllers
                    </div>
                    <div>
                        <MDBIcon icon="check" className="mr-3 sky-text" /> Property specialists
                    </div>
                </Slider>
            </div>
        );
    }
}
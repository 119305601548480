import { STRAPI_URL } from "../../data/DataRequestor";
import { multiIndexOf } from "../../helpers/ArrayHelper";
export const COMPONENT_TYPE_ENUM = {
  TITLE: "form-inputs.form-divider",
  TEXT: "form-inputs.text-input",
  SELECT: "form-inputs.select-input",
  RADIO: "form-inputs.radio-input",
  MULTICHOICE: "form-inputs.checkbox-input",
  LONGTEXT: "form-inputs.long-text-input",
  SIGNATURE: "form-inputs.digital-signature",
  USER_DETAILS: "form-inputs.user-details",
  PARAGRAPH: "form-inputs.paragraph",
  DATE: "form-inputs.date-input",
  PAYMENT: "form-inputs.payment-method",
  ATTACHMENT: "form-inputs.attachments",
  TABLE: "form-inputs.table-input",
  UPLOAD: "form-inputs.file-upload",
  GROUP: "form-inputs.section-group",
  REPEATABLE_GROUP: "form-inputs.repeatable-section-group",
};

export const updateForm = async (form, locations) => {
  const digitalSignaturePos = form.sections
    .map((e) => e.__component)
    .indexOf(COMPONENT_TYPE_ENUM.SIGNATURE);
  if (digitalSignaturePos !== -1) {
    const digitalComponent = mapDigitalSignatureToFormComponents(
      form.sections[digitalSignaturePos]
    );
    form.sections.splice(digitalSignaturePos, 1, ...digitalComponent);
  }

  const userDetailsPos = form.sections
    .map((e) => e.__component)
    .indexOf(COMPONENT_TYPE_ENUM.USER_DETAILS);
  if (userDetailsPos !== -1) {
    const userDetailsComponent = mapUserDetailsToFormComponents(
      form.sections[userDetailsPos],
      locations
    );
    form.sections.splice(userDetailsPos, 1, ...userDetailsComponent);
  }

  const paymentMethodPos = form.sections
    .map((e) => e.__component)
    .indexOf(COMPONENT_TYPE_ENUM.PAYMENT);
  if (paymentMethodPos !== -1) {
    const paymentDetailsComponent = mapPaymentMethodToFormComponents(
      form.sections[paymentMethodPos]
    );
    form.sections.splice(paymentMethodPos, 1, ...paymentDetailsComponent);
  }

  const attachmentPos = form.sections
    .map((e) => e.__component)
    .indexOf(COMPONENT_TYPE_ENUM.ATTACHMENT);
  if (attachmentPos !== -1) {
    const attachmentsComponent = mapAttachmentToFormComponents(
      form.sections[attachmentPos]
    );
    form.sections.splice(attachmentPos, 1, ...attachmentsComponent);
  }

  const fileUploadPos = form.sections
    .map((e) => e.__component)
    .indexOf(COMPONENT_TYPE_ENUM.UPLOAD);
  if (fileUploadPos !== -1) {
    const fileUploadComponent = mapFileUploadToFromComponents(
      form.sections[fileUploadPos]
    );
    form.sections.splice(fileUploadPos, 1, ...fileUploadComponent);
  }

  const groupedSectionsPositions = form.sections.map((e) => e.__component);
  const groupedSectionsIndexes = multiIndexOf(
    groupedSectionsPositions,
    COMPONENT_TYPE_ENUM.GROUP
  );
  if (groupedSectionsIndexes && groupedSectionsIndexes.length > 0) {
    const values = [];
    for (let i = 0; i < groupedSectionsIndexes.length; i++) {
      const groupedComponent = await mapGroupedSectionsToFormComponents(
        form.sections[groupedSectionsIndexes[i]],
        i
      );
      values.push({ index: groupedSectionsIndexes[i], c: groupedComponent });
    }
    if (values && values.length > 0) {
      for (let i = 0; i < values.length; i++) {
        const index = form.sections
          .map((e) => e.__component)
          .indexOf(COMPONENT_TYPE_ENUM.GROUP);
        form.sections.splice(index, 1, ...values[i].c);
      }
    }
  }

  return form;
};

export const buildFormState = (form) => {
  var formState = {};

  for (const sections of form.sections) {
    if (
      sections.__component !== COMPONENT_TYPE_ENUM.TITLE &&
      sections.__component !== COMPONENT_TYPE_ENUM.PARAGRAPH &&
      sections.__component !== COMPONENT_TYPE_ENUM.ATTACHMENT
    ) {
      formState[sections.questionKey] = "";
    }

    // if (sections.__component !== COMPONENT_TYPE_ENUM.SIGNATURE) {
    //   formState['digital-signature-accept'] = ''
    //   formState['digital-signature-print-name'] = ''
    // }
  }

  return formState;
};

export const buildFormAnswers = async (form, data) => {
  const answers = { ...form };

  for (const sections of answers.sections) {
    if (
      sections.__component !== COMPONENT_TYPE_ENUM.TITLE &&
      sections.__component !== COMPONENT_TYPE_ENUM.PARAGRAPH &&
      sections.__component !== COMPONENT_TYPE_ENUM.ATTACHMENT
    ) {
      if (typeof data[sections.questionKey] !== "undefined") {
        sections.answer = data[sections.questionKey];
      }
    }
  }

  // Dynamically add the repeatable sections and map to answers
  const repeatableSectionsPositions = answers.sections.map(
    (e) => e.__component
  );
  const repeatableSectionsIndexes = multiIndexOf(
    repeatableSectionsPositions,
    COMPONENT_TYPE_ENUM.REPEATABLE_GROUP
  );
  if (repeatableSectionsIndexes && repeatableSectionsIndexes.length > 0) {
    const values = [];
    for (let i = 0; i < repeatableSectionsIndexes.length; i++) {
      const prefix =
        answers.sections[repeatableSectionsIndexes[i]].sectionPrefix;
      const count = answers.sections[repeatableSectionsIndexes[i]].answer;
      for (let ci = parseInt(count) - 1; ci >= 0; ci--) {
        const groupedComponent = await mapGroupedSectionsToFormComponents(
          answers.sections[repeatableSectionsIndexes[i]],
          ci + 1
        );
        const paragraph = {
          __component: COMPONENT_TYPE_ENUM.PARAGRAPH,
          text: `<p><span style="color: rgb(218, 115, 37);">${prefix} ${
            ci + 1
          }</span></p>`,
        };
        groupedComponent.unshift(paragraph);
        values.push({
          index: repeatableSectionsIndexes[i],
          c: groupedComponent,
        });
      }
    }
    if (values && values.length > 0) {
      for (let i = 0; i < values.length; i++) {
        const index = answers.sections
          .map((e) => e.__component)
          .indexOf(COMPONENT_TYPE_ENUM.REPEATABLE_GROUP);
        answers.sections.splice(index + 1, 0, ...values[i].c);
      }
    }
  }

  for (const sections of answers.sections) {
    if (
      sections.__component !== COMPONENT_TYPE_ENUM.TITLE &&
      sections.__component !== COMPONENT_TYPE_ENUM.PARAGRAPH &&
      sections.__component !== COMPONENT_TYPE_ENUM.ATTACHMENT
    ) {
      if (typeof data[sections.questionKey] !== "undefined") {
        sections.answer = data[sections.questionKey];
      }
    }
  }

  // for (const sections of answers.sections) {
  //   if (sections.__component === COMPONENT_TYPE_ENUM.REPEATABLE_GROUP) {
  //     if (sections.answer) {
  //       const component = sections.form_section
  //       const count = parseInt(sections.answer)

  //       const s = await getFormSection(component.id)
  //       if (s) {
  //       }
  //     }
  //   }
  // }

  return answers;
};

export const groupSections = (form) => {
  let fullGroup = [];
  let group = [];
  for (let i = 0; i < form.sections.length; i++) {
    if (i === 0) {
      if (form.sections[i].__component !== COMPONENT_TYPE_ENUM.TITLE) {
        group.push({ __component: COMPONENT_TYPE_ENUM.TITLE, title: "" });
      }
      group.push(form.sections[i]);
      continue;
    }

    if (form.sections[i].__component === COMPONENT_TYPE_ENUM.TITLE) {
      fullGroup.push(group);
      group = [];
    }

    // if (form.sections[i].__component === COMPONENT_TYPE_ENUM.SIGNATURE) {
    //   if (group.length > 0) {
    //     fullGroup.push(group)
    //     group = []
    //   }
    //   const section = mapDigitalSignatureToFormComponents(form.sections[i])
    //   fullGroup.push(section)
    //   continue
    // }

    group.push(form.sections[i]);
  }

  if (group.length > 0) {
    fullGroup.push(group);
  }

  return fullGroup;
};

export const mapDigitalSignatureToFormComponents = (section) => {
  return [
    { __component: COMPONENT_TYPE_ENUM.TITLE, title: section.title },
    {
      __component: COMPONENT_TYPE_ENUM.MULTICHOICE,
      answer: null,
      options: [
        {
          value: section.value,
        },
      ],
      placeholder: null,
      question: section.message,
      questionHint: null,
      questionKey: "digital-signature-accept",
      required: section.required,
    },
    {
      __component: COMPONENT_TYPE_ENUM.TEXT,
      answer: null,
      placeholder: null,
      question: section.action,
      questionHint: null,
      questionKey: "digital-signature-print-name",
      required: section.required,
    },
  ];
};

const mapUserDetailsToFormComponents = (section, locations) => {
  const userDetails = [
    {
      __component: COMPONENT_TYPE_ENUM.TITLE,
      title: section.title,
    },
    {
      __component: COMPONENT_TYPE_ENUM.SELECT,
      answer: null,
      placeholder: "-- Select Your Local Branch --",
      question: "Local Branch",
      questionHint: null,
      questionKey: "user-details-location",
      required: true,
      options: locations
        .sort((a, b) =>
          a.location > b.location ? 1 : b.location > a.location ? -1 : 0
        )
        .map((l) => ({ value: l.location })),
    },
    {
      __component: COMPONENT_TYPE_ENUM.TEXT,
      answer: null,
      placeholder: null,
      question: "Name",
      questionHint: null,
      questionKey: "user-details-name",
      required: true,
    },
    {
      __component: COMPONENT_TYPE_ENUM.TEXT,
      answer: null,
      placeholder: null,
      question: "E-Mail",
      questionHint: null,
      questionKey: "user-details-email",
      required: true,
    },
    {
      __component: COMPONENT_TYPE_ENUM.TEXT,
      answer: null,
      placeholder: null,
      question: "Day Time Contact Number",
      questionHint: null,
      questionKey: "user-details-contact-number",
      required: true,
    },
    {
      __component: COMPONENT_TYPE_ENUM.TEXT,
      answer: null,
      placeholder: null,
      question: "IRD Number (If Known)",
      questionHint: null,
      questionKey: "user-details-ird-number",
      required: false,
    },
  ];

  const addressFields = [
    {
      __component: COMPONENT_TYPE_ENUM.TEXT,
      answer: null,
      placeholder: null,
      question: "Street",
      questionHint: null,
      questionKey: "user-details-address-street",
      required: true,
    },
    {
      __component: COMPONENT_TYPE_ENUM.TEXT,
      answer: null,
      placeholder: null,
      question: "Suburb",
      questionHint: null,
      questionKey: "user-details-address-suburb",
      required: true,
    },
    {
      __component: COMPONENT_TYPE_ENUM.TEXT,
      answer: null,
      placeholder: null,
      question: "Town or City",
      questionHint: null,
      questionKey: "user-details-address-city",
      required: true,
    },
    {
      __component: COMPONENT_TYPE_ENUM.TEXT,
      answer: null,
      placeholder: null,
      question: "Postcode",
      questionHint: null,
      questionKey: "user-details-address-postcode",
      required: true,
    },
  ];

  if (section.include_address) {
    return [...userDetails, ...addressFields];
  }

  return userDetails;
};

export const mapPaymentMethodToFormComponents = (section) => {
  return [
    { __component: COMPONENT_TYPE_ENUM.TITLE, title: section.title },
    {
      __component: COMPONENT_TYPE_ENUM.RADIO,
      answer: null,
      options: [
        {
          value: "Deduct from my Tax Refund",
        },
        {
          value: "Credit Card",
        },
        {
          value: "Pay on invoice",
        },
        {
          value: "Smart AR Fee Funding",
        },
      ],
      placeholder: null,
      question: "Please select one of the following:",
      questionHint: null,
      questionKey: "payment-method",
      required: true,
    },
  ];
};

export const mapAttachmentToFormComponents = (section) => {
  return [
    { __component: COMPONENT_TYPE_ENUM.TITLE, title: section.title },
    { __component: COMPONENT_TYPE_ENUM.PARAGRAPH, text: section.description },
    {
      __component: COMPONENT_TYPE_ENUM.ATTACHMENT,
      files: section.files.map((f) => ({ url: f.url, name: f.name })),
    },
  ];
};

export const mapFileUploadToFromComponents = (section) => {
  return [
    { __component: COMPONENT_TYPE_ENUM.TITLE, title: section.title },
    {
      __component: COMPONENT_TYPE_ENUM.UPLOAD,
      question: section.question,
      questionKey: section.questionKey,
      answer: null,
    },
  ];
};

export const mapGroupedSectionsToFormComponents = async (section, index) => {
  const s = await getFormSection(section.form_section.id);
  if (s) {
    const c = s.sections;
    c.forEach((x) => {
      x.questionKey = x.questionKey + `-${index}`;
    });
    return c;
  }

  return [];
};

export const getFormSection = async (id) => {
  const res = await fetch(STRAPI_URL + "/form-sections/" + id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (res.status === 200) {
    return await res.json();
  }

  return [];
};

import { MDBBtn, MDBCol, MDBContainer, MDBRow } from "mdbreact";
import React from "react";
import Embed from "react-embed";
import { Helmet, HelmetProvider } from "react-helmet-async"; // import LoaderComponent from '../../data/Loader';
import ReactHtmlParser from "react-html-parser";
import { withRouter } from "react-router";
import DataRequestor, {
  ENTITY_BLOG_POSTS,
  GET,
  STRAPI_URL,
} from "../../data/DataRequestor";
import { Logger } from "../../data/Logger";
import { DateHelper } from "../../helpers/DateHelper";
import ScrollToTop from "../Nav/ScrollToTop";
import "./style.css";
// import ReactGA from 'react-ga';

export class SelectedBlogPage extends React.Component {
  logger = new Logger();

  constructor(props) {
    super(props);
    this.state = {
      id: null,
      params: null,
      data: null,
      isLoading: true,
      requestor: new DataRequestor(),
    };
  }

  componentDidMount() {
    this.decodeUrl();
  }

  decodeUrl() {
    let id = 0;
    let params = [];

    let qStr = window.location.search;
    qStr = qStr.replace("?", "");

    params = qStr.split("&");
    if (params && params.length > 0) {
      let pStr = params.find((q) => {
        return q.startsWith("id");
      });
      if (pStr && pStr.length > 0) {
        let pArr = pStr.split("=");
        if (pArr && pArr.length === 2) {
          id = pArr[1];
        }
      }
    }

    this.setState({
      id: id,
      params: params,
    });

    let url = STRAPI_URL + ENTITY_BLOG_POSTS + `/${id}`;
    this.state.requestor.fetch(this, url, GET);
  }

  fetchSuccess(data) {
    console.log(data);
    window.setTimeout(() => {
      this.setState({
        data: data,
        isLoading: false,
      });
    }, 800);
    this.logger.log(`blog fetch success`);
    this.logger.log(data);
    // ReactGA.initialize('UA-103173484-2');
    // ReactGA.pageview(`/view-blog-post:${data.urlSlug}`);
  }

  fetchFailed(error) {
    this.logger.log(error);
  }

  // initializeReactGA() {
  //     ReactGA.initialize('UA-103173484-2');
  //     ReactGA.pageview('/view-blog-post');
  // }

  render() {
    const blog = this.state.data;
    const dateHelper = new DateHelper();
    const regExOpen = new RegExp(/"&lt;iframe"/g);
    const regExClose = new RegExp(/"/g);

    const blogBody = blog
      ? blog.body
          .split("&lt;iframe")
          .join("<iframe")
          .split("&gt;&lt;/iframe&gt;")
          .join("></iframe>")
      : null; // blog.body.replace('&lt;iframe', '<iframe').replace(';&lt;/iframe&gt;', '</iframe>') : null;

    // const imageHelper = new ImageHelper();
    // const coverImg = imageHelper.getImage(blog.coverPhoto, PREFERRED_FORMAT_LARGE);
    // console.log(blogBody)

    return (
      <HelmetProvider>
        {this.state.isLoading ? (
          <div style={{ height: "100vh" }}>{/* <LoaderComponent /> */}</div>
        ) : null}
        {blog ? (
          <>
            <Helmet>
              <meta charSet="utf-8" />
              <title>{blog.title}</title>
              <meta
                name="description"
                content={
                  blog.subtitle || "Neovia Advisory latest news and articles."
                }
              />
              <link rel="canonical" href="https://www.neovia.co.nz/" />
            </Helmet>
            <ScrollToTop />
            <div
              style={{
                backgroundImage: `url("${blog.coverPhoto.url}")`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                minHeight: "60vh",
              }}
              className="d-flex justify-content-center align-items-center page-header header-filter clear-filter bg-gradient-overlay"
            >
              <div className="header-text">
                <div className="text-left center mb-4 pl-lg-2">
                  <MDBRow>
                    <MDBCol md="12">
                      {blog.publishDate ? (
                        <div className="text-left white-text h5-responsive pt-lg-5 mb-lg-3 mb-1">
                          <b className="">
                            {dateHelper.getDateStr(blog.publishDate)}
                          </b>
                          <br />
                        </div>
                      ) : null}
                    </MDBCol>
                  </MDBRow>
                  <div className="h1-responsive white-text font-nanum-eb mt-lg-2 mt-0">
                    {blog.title ? <>{blog.title}</> : null}
                  </div>
                  {/* <h5 className="title-md mt-2 flock-grey font-acas-tight">
                                                    {
                                                        blog.subtitle ? (
                                                            <>
                                                                {blog.subtitle}
                                                            </>
                                                        ) : null
                                                    }
                                                </h5> */}
                  <h6 className="h6-responsive mt-4 white-text">
                    {blog.author ? (
                      <>
                        By <b className="font-weight-md">{blog.author}</b>
                      </>
                    ) : null}
                  </h6>
                  <MDBCol md="12" className="text-left mb-2 mt-4 p-0">
                    {blog.blog_category ? (
                      <span className="highlight-bg white-text h5-responsive text-uppercase">
                        {blog.blog_category.Name}
                      </span>
                    ) : null}
                  </MDBCol>
                </div>
              </div>
            </div>
            <div className="center bottom-20" style={{ zIndex: "2" }}></div>
            <div className="padding-lg text-left">
              <MDBContainer className="mt-2 mb-2">
                {/* <MDBRow className="read-more ml-1 ml-lg-0">
                                        <MDBIcon icon="chevron-left pointer sky-text mr-3 mt-1" onClick={() => this.props.history.push('/news')}></MDBIcon> <p className="font-acas-tight font-weight-md flock-grey text-uppercase pointer" onClick={() => this.props.history.push('/news')}></p>
                                    </MDBRow> */}
                <div className="h5-responsive mb-5 mt-5 blog-body">
                  {blog.title ? (
                    <>
                      <div className="h4-responsive tblue-text text-center font-nanum-eb mb-4">
                        {blog.title}
                      </div>
                    </>
                  ) : null}

                  {/* {
                                            blog.subtitle ? (
                                                <>
                                                    <div className="h6-responsive mb-5">
                                                        {blog.subtitle}
                                                    </div>
                                                </>
                                            ) : null
                                        } */}
                  {blogBody ? (
                    <div>
                      {ReactHtmlParser(blogBody, { transform: updateHtml })}
                    </div>
                  ) : null}
                </div>
                <br />
                <hr />
                <MDBBtn
                  outline
                  size="lg"
                  className="mt-5"
                  color="primary"
                  onClick={() => this.props.history.push("/news")}
                >
                  View all news
                </MDBBtn>
              </MDBContainer>
            </div>
          </>
        ) : null}
      </HelmetProvider>
    );
  }
}
export default withRouter(SelectedBlogPage);

function updateHtml(node) {
  if (node.type === "tag" && node.name === "oembed") {
    return (
      <div className="iframe-container">
        <Embed
          width={"100%"}
          height={"100%"}
          className="iframe"
          url={node.attribs.url}
        />
      </div>
    );
  }

  if (node.type === "tag" && node.name === "iframe") {
    let src = "";
    // let width = '';
    // let height = '';

    if (node && node.attribs) {
      src = node.attribs.src;
      return (
        <div className="iframe-container">
          <iframe className="iframe" width="100%" height="100%" src={src} />
        </div>
      );
    }

    return null;
  }
}

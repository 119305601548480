import React from "react";

export const SpinnerCustom = (props) => {
  const react = React;
  const { size } = props;

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        className="spinner-border"
        role="status"
        style={{
          height: size || "15px",
          width: size || "15px",
          verticalAlign: "middle",
          marginLeft: "5px",
          marginBottom: "2px",
          color: "white !important",
        }}
      >
        <span className="sr-only"></span>
      </div>
    </div>
  );
};

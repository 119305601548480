/* eslint-disable react-hooks/exhaustive-deps */
import { MDBBtn } from "mdbreact";
import React, { useEffect, useState } from "react";
import { useChecklistStore } from "../../../stores/checklist";
import { useFormDataStore } from "../../../stores/form-data";

export const TableInput = (props) => {
  const react = React;
  const currentForm = useChecklistStore((state) => state.currentForm);
  const data = useFormDataStore((state) => state.data);
  const updateValue = useFormDataStore((state) => state.updateValue);
  const {
    questionKey,
    question,
    placeholder,
    required,
    questionHint,
    answer,
    headings,
    changeHandler,
    disabled,
  } = props;

  const formData = data.find((d) => d.id === currentForm.id);
  const defaultValue =
    (formData && formData.form && formData.form[questionKey]) || null;

  const [tableData, setTableData] = useState(null);
  const [initialTableLength, setTableLength] = useState(0);

  const createAndFill2DArray = (rows, columns, defaultValue) => {
    return Array.from({ length: rows }, (d, j) => {
      return Array.from({ length: columns }, (v, i) => {
        return defaultValue[i][j];
      });
    });
  };

  useEffect(() => {
    let active = true;

    if (active) {
      let state;
      if (headings.some((h) => h.value)) {
        // Change any empty heading values to empty string
        let a = headings.map((h) => h.value).map((v) => (v ? v : ""));
        // Map values for headings to an array
        let b = a.map((h) => h.split(",").map((e) => e.trim()));
        // Find the longest length of heading values
        let longestIndex = b.reduce(
          (p, c, i, a) => (a[p].length > c.length ? p : i),
          0
        );
        const maxLength = b[longestIndex].length;
        // Make all heading value arrays the same length
        let u = b
          .map((arr) => {
            if (arr.length < maxLength) {
              var addedArray = new Array(maxLength - arr.length).fill("");
              return [...arr, ...addedArray];
            }
            return arr;
          })
          .filter(Boolean);
        // Create the table values
        state = createAndFill2DArray(
          maxLength,
          headings.filter(Boolean).length,
          u
        );
        setTableLength(state.length - 1);
      } else {
        state = [new Array(headings.filter(Boolean).length).fill("")];
      }

      const d = data.find((d) => d.id === currentForm.id);

      if (d && d.form && d.form[questionKey]) {
        const values = d.form[questionKey].split(";");
        if (values.length > 0) {
          for (let i = 0; i < values.length; i++) {
            const innerValues = values[i].split(",");
            state[i] = innerValues;
          }
        }
      }
      setTableData(state);
      // updateValue(currentForm.id, questionKey, state.join(";"));
    }

    return () => {
      active = false;
    };
  }, [headings, data]);

  const onAddHandler = () => {
    const state = new Array(headings.filter(Boolean).length).fill("");

    setTableData([...tableData, state]);
  };

  const onRemoveHandler = (rowIndex) => {
    let data = [...tableData];
    data.splice(rowIndex, 1);
    setTableData([...data]);
    updateValue(currentForm.id, questionKey, data.join(";"));
  };

  const updateTableData = (rIndex, dIndex, value) => {
    let data = [...tableData];
    data[rIndex][dIndex] = value;

    updateValue(currentForm.id, questionKey, data.join(";"));
    setTableData(data);
  };

  return (
    <div className="text-left">
      <p style={{ whiteSpace: "pre-wrap" }}>{question}</p>
      <table style={{ width: "100%" }}>
        <tr>
          {headings.map((h) => (
            <th>{h.key}</th>
          ))}
        </tr>
        {tableData &&
          tableData.map((t, rowIndex) => (
            <tr>
              {t.map((d, dataIndex) => (
                <td>
                  <input
                    style={{ color: "#666666" }}
                    className="form-control"
                    type="text"
                    autoComplete="off"
                    value={d}
                    onChange={(e) =>
                      updateTableData(rowIndex, dataIndex, e.target.value)
                    }
                  />
                </td>
              ))}
              {rowIndex > initialTableLength && (
                <button
                  style={{
                    marginTop: "0.5rem",
                    border: 0,
                    background: "unset",
                  }}
                  onClick={() => onRemoveHandler(rowIndex)}
                  type="button"
                >
                  X
                </button>
              )}
            </tr>
          ))}
      </table>
      <div className="mt-2 mb-4">
        <MDBBtn onClick={() => onAddHandler()} type="button">
          Add
        </MDBBtn>
      </div>
    </div>
  );
};

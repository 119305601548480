import { MDBBtn, MDBIcon, MDBTooltip } from "mdbreact";
import React from "react";
import { useChecklistStore } from "../../../stores/checklist";
import { useFormDataStore } from "../../../stores/form-data";

export const DateInput = (props) => {
  const react = React;
  const currentForm = useChecklistStore((state) => state.currentForm);
  const data = useFormDataStore((state) => state.data);
  const {
    questionKey,
    question,
    placeholder,
    required,
    questionHint,
    answer,
    changeHandler,
    disabled,
  } = props;

  const formData = data.find((d) => d.id === currentForm.id);
  const defaultValue =
    (formData && formData.form && formData.form[questionKey]) || null;

  return (
    <div className="form-group text-left">
      <label style={{ whiteSpace: "pre-line" }} htmlFor={questionKey}>
        {question}
        {required ? <span style={{ color: "red" }}>*</span> : ""}
        {questionHint ? (
          <MDBTooltip placement="bottom">
            <MDBBtn className="rm-button-style">
              <MDBIcon far icon="question-circle" />
            </MDBBtn>
            <div>{questionHint}</div>
          </MDBTooltip>
        ) : (
          ""
        )}
      </label>
      <input
        style={{ color: "#666666" }}
        autoComplete="off"
        type="date"
        className="form-control"
        id={questionKey}
        placeholder={placeholder}
        value={defaultValue || ""}
        required={required}
        onChange={changeHandler}
        disabled={disabled}
      />
    </div>
  );
};

import {
  MDBAnimation,
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBMask,
  MDBRow,
} from "mdbreact";
import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { withRouter } from "react-router-dom";
import DataRequestor, {
  ENTITY_OURTEAM,
  GET,
  STRAPI_URL,
} from "../../data/DataRequestor";
import { Logger } from "../../data/Logger";
import ScrollToTop from "../Nav/ScrollToTop";
import TeamMemberModal from "./TeamMemberModal";
// import TitleSlider from '../Home/TitleSlider'
import { scrollTo } from "../../App";
// import ReactGA from 'react-ga';
import _ from "lodash";

import "./style.css";

class OurPeople extends React.Component {
  logger = new Logger();

  constructor(props) {
    super(props);
    this.state = {
      requestor: new DataRequestor(),
      pageCount: 0,
      data: [],
      teamMembers: [],
      modal: false,
      selectedTeamMember: null,
    };
  }

  // componentDidMount() {
  //     ReactGA.initialize('UA-103173484-2');
  //     ReactGA.pageview('/data-company');
  // }

  componentDidMount() {
    let groupedUrl = STRAPI_URL + ENTITY_OURTEAM;
    // let allUrl = STRAPI_URL + ENTITY_TEAM_MEMBERS
    // let countUrl = STRAPI_URL + ENTITY_BLOG_POSTS_COUNT
    this.state.requestor.fetch(this, groupedUrl, GET, null, "grouped");
    // this.state.requestor.fetch(this, allUrl, GET, null, 'all')
    // ReactGA.initialize('UA-103173484-2');
    // ReactGA.pageview('/data-blog');
  }

  fetchSuccess(data, id) {
    if (data) {
      if (id === "grouped") {
        this.setState({
          teamMembers: data.our_people ? data.our_people : [],
        });
        this.logger.log(`grouped people fetch success`);
        this.logger.log(data);
      }
      // if (id === 'all') {
      //   this.setState({
      //     data: data ? data : []
      //   })
      //   this.logger.log(`all people fetch success`)
      //   this.logger.log(data)
      // }
    }
    this.logger.log(data);
  }

  fetchFailed(error) {
    console.log("id", error);
    this.logger.log(error);
  }

  fetchCountSuccess(data) {
    if (data) {
      this.setState({
        pageCount: data,
      });
      this.logger.log(`people fetch count success | ${data}`);
    }
  }

  fetchCountFailed(error) {
    this.logger.log(error);
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  setTeamMember = (id = null, member = null) => {
    let tMember = member;
    let top = null;

    this.setState({
      selectedTeamMember: tMember,
      top: top,
    });
  };

  render() {
    const mergeGroupedTeamMembers = _.flatMap(
      this.state.teamMembers,
      "team_members"
    );
    mergeGroupedTeamMembers.map((m, i) => {
      let tmEleId = `team-member-${m.id}`;
      let tmEmail = `mailto:${m.email}?subject=Enquiry`;
      let tmLinkdin = m.linkedin;
      let tmImage = m.image;
      let tmApptUrl = m.appturl;

      return (
        <>
          <MDBCol
            id={tmEleId}
            sm="12"
            md="3"
            lg="2"
            className="mb-4 text-center mx-auto no-padding-margin"
          >
            <TeamMemberModal
              image={m.image.url}
              qual={m.qualification}
              mobile={m.mobile_number}
              phone={m.phone_number}
              description={m.blurb}
              name={m.Name}
              title={m.title}
              email={m.email}
              linkedIn={m.linkedin}
              apptUrl={m.appturl}
            />
            <div className="mb-4">
              <h4 className="h3-responsive mt-4 font-nanum-eb secondary-text">
                {m.Name}
              </h4>
              <h6 className="mb-2 divider-margin-small text-title  font-nanum font-weight-md h5-responsive">
                {m.title}
              </h6>
              <ul className="list-unstyled mb-0">
                <a href={tmEmail} className="p-2 fa-lg pointer">
                  <MDBIcon fa-lg icon="envelope" className="" />
                </a>
                {tmLinkdin ? (
                  <a
                    href={tmLinkdin}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="p-2 fa-lg pointer"
                  >
                    <MDBIcon fab icon="linkedin" className="" />
                  </a>
                ) : null}
              </ul>
            </div>
          </MDBCol>
        </>
      );
    });
    const teamMembers = this.state.teamMembers.map((t, i) => {
      let title = t.title;
      const getTitle = (providedTitle) => {
        return providedTitle.replace("|", "\n");
      };

      return (
        <>
          <h2 className="pb-2 font-nanum-eb display-h3 mb-4 mt-4 tblue-text ml-lg-2">
            {title}
          </h2>
          <MDBRow>
            {t.team_members.map((m, i) => {
              let tmEleId = `team-member-${m.id}`;
              let tmEmail = `mailto:${m.email}?subject=Enquiry`;
              let tmLinkdin = m.linkedin;
              let tmImage = m.image;
              let tmApptUrl = m.appturl;

              return (
                <MDBCol
                  id={tmEleId}
                  sm="12"
                  md="3"
                  lg={t.team_members.length > 4 ? "4" : "3"}
                  className="mb-4 text-center mx-auto no-padding-margin"
                >
                  <TeamMemberModal
                    image={m.image.url}
                    qual={m.qualification}
                    mobile={m.mobile_number}
                    phone={m.phone_number}
                    description={m.blurb}
                    name={m.Name}
                    title={m.title}
                    email={m.email}
                    linkedIn={m.linkedin}
                    apptUrl={m.appturl}
                  />
                  <div className="mb-4">
                    <h4 className="h3-responsive mt-4 font-nanum-eb secondary-text">
                      {m.Name}
                    </h4>
                    <h6
                      style={{ whiteSpace: "pre-wrap" }}
                      className="mb-2 divider-margin-small text-title  font-nanum font-weight-md h5-responsive"
                    >
                      {getTitle(m.title)}
                    </h6>
                    <ul className="list-unstyled mb-0">
                      <a href={tmEmail} className="p-2 fa-lg pointer">
                        <MDBIcon fa-lg icon="envelope" className="" />
                      </a>
                      {tmLinkdin ? (
                        <a
                          href={tmLinkdin}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="p-2 fa-lg pointer"
                        >
                          <MDBIcon fab icon="linkedin" className="" />
                        </a>
                      ) : null}
                    </ul>
                  </div>
                </MDBCol>
              );
            })}
          </MDBRow>
        </>
      );
    });

    return (
      <HelmetProvider>
        <div>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Our People | Neovia Advisory</title>
            <link
              rel="canonical"
              href="https://www.neovia.co.nz/financial-experts"
            />
          </Helmet>
          <ScrollToTop />
          <div className="people-header d-flex justify-content-center align-items-center page-header header-filter clear-filter bg-gradient-overlay">
            <div className="header-text">
              <h2 className="h4-responsive text-left center font-weight-md mb-4 pl-lg-2 secondary-text font-nanum-b sf-compact">
                Our People
              </h2>
              <h1 className="h1-responsive text-left center font-weight-md mb-4 pl-lg-2 font-nanum-b">
                Experts in our fields, passionate about our work.
              </h1>
            </div>
          </div>
          <div id="about">
            <div className="padding-lg" id="team">
              <MDBContainer>
                <MDBAnimation reveal type="flipInX" className="fast text-left">
                  <h2 className="font-nanum-eb display-h2 text-center mb-4 mt-4 tblue-text">
                    Meet our team.
                  </h2>
                </MDBAnimation>
                <h6 className="font-nanum-eb h5-responsive text-center ">
                  We wouldn't exist without them.
                </h6>
                <div
                  className="down-arrow-people"
                  onClick={() => scrollTo("people")}
                >
                  <MDBAnimation type="bounce" infinite className="slow">
                    <MDBIcon className="" size="1x" icon="chevron-down" />
                  </MDBAnimation>
                </div>
              </MDBContainer>
            </div>
            <div className="pt-5 pb-5" id="people">
              <MDBContainer className="pb-5 mx-auto">
                {teamMembers}
              </MDBContainer>
            </div>
          </div>

          <div className="home-banner page-header header-filter clear-filter light-filter white-text">
            <MDBMask overlay="black-strong" className="w-100 pt-5 pb-5">
              <MDBCol md="10" lg="9" xl="8" className="mx-auto">
                <MDBContainer className="pt-5 pb-5 text-left">
                  <div className="h3-responsive mb-4 mt-2 font-nanum-eb">
                    We work closely with our clients to build enduring
                    relationships that facilitate their success and ongoing
                    prosperity.
                  </div>
                  <MDBBtn
                    outline
                    color="white"
                    onClick={() => this.props.history.push("/contact-us")}
                  >
                    Book an appointment
                  </MDBBtn>
                </MDBContainer>
              </MDBCol>
            </MDBMask>
          </div>
        </div>
      </HelmetProvider>
    );
  }
}

export default withRouter(OurPeople);

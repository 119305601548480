import axios from 'axios'
import { API_BASE_URL } from './constants'

export const METHOD_GET = 'get'
export const METHOD_POST = 'post'

export const axRequest = async (tokenFunc, path, method, data = null, url) => {
  let opts = {
    method: method,
    url: url ? `${url}${path}` : `${API_BASE_URL}${path}`
  }

  if (tokenFunc) {
    let token = await tokenFunc()
    // console.log(token);
    if (token) {
      opts['headers'] = { authorization: `Bearer ${token}` }
    }
  }

  if (data) {
    if (method === METHOD_GET) {
      opts['params'] = data
    } else if (method === METHOD_POST) {
      opts['data'] = data
    }
  }

  let response = {}
  try {
    response = await axios(opts)
  } catch {
    // error
  }
  return response.data
}

export const axCustomRequest = async (token, url, method, data = null) => {
  let opts = {
    method: method,
    url: url
  }

  if (token) {
    opts['headers'] = { authorization: `Bearer ${token}` }
  }

  if (data !== null) opts['data'] = data

  let response = await axios(opts)
  return response
}

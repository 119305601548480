import {
  MDBAnimation,
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBMask,
  MDBRow,
} from "mdbreact";
import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { withRouter } from "react-router-dom";
import DataRequestor, { GET, STRAPI_URL } from "../../data/DataRequestor";
import { Logger } from "../../data/Logger";
import ScrollToTop from "../Nav/ScrollToTop";
// import ReactGA from 'react-ga';

import "./style.css";

class Accounting extends React.Component {
  logger = new Logger();

  // componentDidMount() {
  //     ReactGA.initialize('UA-103173484-2');
  //     ReactGA.pageview('/data-company');
  // }
  constructor(props) {
    super(props);
    this.state = {
      requestor: new DataRequestor(),
      data: [],
    };
  }

  componentDidMount() {
    const adamUrl = STRAPI_URL + "/team-members/2";
    this.state.requestor.fetch(this, adamUrl, GET);
  }

  fetchSuccess(data) {
    if (data) {
      this.setState({
        data: data,
      });
    }
  }

  render() {
    return (
      <HelmetProvider>
        <div>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Chartered Accountancy | Neovia Advisory</title>
            <link
              rel="canonical"
              href="https://www.neovia.co.nz/chartered-accountancy"
            />
          </Helmet>
          <ScrollToTop />
          <div className="accounting-header d-flex justify-content-center align-items-center page-header header-filter clear-filter bg-gradient-overlay">
            <div className="header-text">
              <h2 className="sf-compact h4-responsive text-left center font-weight-md mb-4 pl-lg-2 secondary-text font-nanum-b">
                Chartered Accountancy
              </h2>
              <h1 className="h1-responsive text-left center font-weight-md mb-4 pl-lg-2 font-nanum-b">
                We focus on innovative solutions to achieve your goals.
              </h1>
            </div>
          </div>
          <div id="about">
            <div className="padding-lg">
              <MDBAnimation reveal type="fadeIn" className="slow">
                <MDBContainer className="pt-5 pb-5">
                  <MDBRow>
                    <MDBCol md="5" className="mx-auto text-center pb-4">
                      <h2 className="display-h2 font-weight-md text-lg-right text-left mb-3 tblue-text font-nanum-eb">
                        The way to a brighter financial future.
                      </h2>
                      <h6 className="sf-pro-tight h5-responsive text-lg-right text-left font-space-xs mt-lg-5 font-nanum-eb">
                        We are passionate about helping our clients to a growth
                        based financial future.
                      </h6>
                    </MDBCol>
                    <MDBCol
                      md="7"
                      className="mx-auto mt-lg-5 pl-lg-5  text-right"
                    >
                      <div className="mt-lg-5">
                        <div className="h5-responsive font-weight-md text-left mb-2 font-space-xs sf-pro">
                          Our accounting division focuses on ensuring the best
                          ideas and innovative solutions can be implemented,
                          while making sure set goals are achievable and in line
                          with current legislation.
                        </div>
                        <div className="h5-responsive text-left font-space-xs">
                          We look at the ‘big picture’ and approach our roles
                          with a holistic view, making sure we understand your
                          ambitions and the outcomes you want to achieve.
                        </div>
                        {/* <MDBBtn outline color="primary" className="mt-4" onClick={() => this.props.history.push('/financial-experts')} >Meet our team</MDBBtn> */}
                      </div>
                    </MDBCol>
                  </MDBRow>
                </MDBContainer>
              </MDBAnimation>
            </div>
          </div>

          <MDBAnimation reveal type="fadeIn" className="slow">
            <div className="accounting-banner-1 page-header header-filter clear-filter blue-filter secondary-text">
              <MDBMask overlay="black-strong" className="w-100 pt-5 pb-5">
                <MDBCol md="10" lg="9" xl="8" className="mx-auto">
                  <MDBContainer className="pt-5 pb-5 text-left">
                    <div className="h3-responsive mb-4 mt-2 font-nanum-eb white-text">
                      Our core services include business taxation and
                      compliance, management accounting, business advice,
                      trusts, change management and accounting software support.
                    </div>
                  </MDBContainer>
                </MDBCol>
              </MDBMask>
            </div>
          </MDBAnimation>

          <MDBAnimation reveal type="fadeIn" className="slow">
            <div className="padding-lg">
              <div className="pt-5">
                <MDBContainer className="pt-2">
                  <MDBRow className="mx-auto">
                    <MDBCol md="9" className="mx-auto">
                      <h2 className="display-h2 tblue-text font-nanum-eb text-left mb-5">
                        How we create a brighter financial future.
                      </h2>
                      <h5 className="h5-responsive font-weight-md text-left mb-1 primary-text">
                        Taxation and Business Accounting
                      </h5>
                      <h6 className="h5-responsive text-left mb-5 font-nanum-eb ">
                        Our taxation team deliver year end accounts, both
                        personal and business, income allocation and
                        structuring, GST, FBT and administration, analysis and
                        representing clients regarding IRD audits, issue
                        resolution and arrears, in addition to routine tax
                        matters.
                      </h6>
                      <h5 className="h5-responsive font-weight-md text-left mb-1 primary-text">
                        Business Changes: Acquisition, Mergers and Evaluations
                      </h5>
                      <h6 className="h5-responsive text-left mb-5 font-nanum-eb ">
                        We navigate you through company mergers, acquisitions,
                        solvent, liquidations and debt recovery.
                        <br />
                        <br />
                        Our team is skilled in business evaluations for sale or
                        purchase, corporate restructuring, and share valuations.
                      </h6>
                      <h5 className="h5-responsive font-weight-md text-left mb-1 primary-text">
                        Debtor Management
                      </h5>
                      <h6 className="h5-responsive text-left mb-5 font-nanum-eb ">
                        Our team take care of debtor and creditor management and
                        negotiation, as well as IRD dispute resolution.
                      </h6>
                      <h5 className="h5-responsive font-weight-md text-left mb-1 primary-text">
                        Accounting Software
                      </h5>
                      <h6 className="h5-responsive text-left mb-5 font-nanum-eb ">
                        We offer training in accounting software, including
                        cloud based systems. We are Xero Platinum Champion
                        partners and support MYOB.
                      </h6>
                      <h5 className="h5-responsive font-weight-md text-left mb-1 primary-text">
                        Business Advisory
                      </h5>
                      <h6 className="h5-responsive text-left mb-5 font-nanum-eb ">
                        We offer business advisory and consultation, financial
                        reporting, full company analysis and action plans,
                        budget and cash flow forecasting, as well as management
                        accounting and reporting.
                      </h6>
                      <h5 className="h5-responsive font-weight-md text-left mb-1 primary-text">
                        Strategic Partnership
                      </h5>
                      <h6 className="h5-responsive text-left mb-5 font-nanum-eb ">
                        We utilise{" "}
                        <a
                          href="https://www.tmnz.co.nz"
                          target="_blank"
                          className=""
                          style={{ textDecoration: "underline" }}
                        >
                          Tax Management New Zealand
                        </a>{" "}
                        who have helped our clients save on IRD interest and
                        penalties.
                      </h6>
                    </MDBCol>
                  </MDBRow>
                </MDBContainer>
              </div>
            </div>
          </MDBAnimation>

          <MDBAnimation
            reveal
            type="fadeIn"
            className="slow"
            style={{ backgroundColor: "whitesmoke" }}
          >
            <div className="pt-5 pb-5 padding-lg">
              <MDBContainer className="pt-5">
                <MDBCol md="9" className="mx-auto">
                  <h2 className="display-h3 tblue-text font-nanum-eb text-left mb-5">
                    More than business taxation and compliance.
                  </h2>
                  <h6 className="h4-responsive text-left primary-text mb-5 font-nanum-eb">
                    Our advisory services can guide you through:
                  </h6>
                  <h6 className="h5-responsive text-left primary-text mb-5 font-nanum-b">
                    <MDBIcon icon="chevron-right" className="mr-3 sky-text" />
                    Exit strategies and succession planning
                  </h6>
                  <h6 className="h5-responsive text-left primary-text mb-5 font-nanum-b">
                    <MDBIcon icon="chevron-right" className="mr-3 sky-text" />
                    Shareholder agreements and risk management
                  </h6>
                  <h6 className="h5-responsive text-left primary-text mb-5 font-nanum-b">
                    <MDBIcon icon="chevron-right" className="mr-3 sky-text" />
                    Wealth creation
                  </h6>
                  <h6 className="h5-responsive text-left primary-text mb-5 font-nanum-b">
                    <MDBIcon icon="chevron-right" className="mr-3 sky-text" />
                    Financing
                  </h6>
                  <h6 className="h5-responsive text-left primary-text mb-5 font-nanum-b">
                    <MDBIcon icon="chevron-right" className="mr-3 sky-text" />
                    Franchising
                  </h6>
                </MDBCol>
              </MDBContainer>
            </div>
          </MDBAnimation>

          <MDBAnimation reveal type="fadeIn" className="slow">
            <div className="accounting-banner-2 page-header header-filter clear-filter blue-filter white-text">
              <MDBMask overlay="black-strong" className="w-100 pt-5 pb-5">
                <MDBCol md="10" lg="9" xl="8" className="mx-auto">
                  <MDBContainer className="pt-5 pb-5 text-left">
                    <div className="h3-responsive mb-4 mt-2">
                      We believe knowledge is empowering and so is our
                      commitment to support you in understanding your current
                      situation, and helping you achieve your business and
                      personal aspirations.
                    </div>
                    {/* <MDBBtn outline color="white" onClick={() => this.props.history.push('/contact-us')}>Book an appointment</MDBBtn> */}
                  </MDBContainer>
                </MDBCol>
              </MDBMask>
            </div>
          </MDBAnimation>

          <div className="contact-div text-left">
            <MDBContainer className="mt-5 mb-5">
              <h3 className="tblue-text mb-4 font-nanum-eb font-weight-md">
                Get in touch
              </h3>
              <h6 className="mb-4 font-nanum-eb font-weight-md mb-5">
                Contact our Director Adam Coleman for a no obligation
                consultation to find out more about how we can help you.
              </h6>

              <MDBRow className="mx-auto">
                <MDBCol md="7">
                  <MDBRow className="font-nanum-b mb-3">
                    <MDBCol md="12">
                      <h4 className="tblue-text mb-4 font-nanum-eb font-weight-md">
                        Adam Coleman
                      </h4>
                    </MDBCol>
                    <MDBCol md="2" lg="2" className="mb-0 m-lr-auto">
                      <p>
                        <b className="font-quatBold">Phone</b>
                      </p>
                    </MDBCol>
                    <MDBCol md="10" lg="10" className="mb-0 m-lr-auto">
                      <p>
                        <a className="secondary-text" href="tel:+64276504699">
                          +64 027 650 4699{" "}
                        </a>
                      </p>
                    </MDBCol>
                    <MDBCol md="2" lg="2" className="mb-0 m-lr-auto">
                      <p>
                        <b className="font-quatBold">Email</b>
                      </p>
                    </MDBCol>
                    <MDBCol md="10" lg="10" className="mb-0 m-lr-auto">
                      <p>
                        <a
                          className="secondary-text"
                          href="mailto:adam@neovia.co.nz"
                        >
                          adam@neovia.co.nz
                        </a>
                      </p>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <div className="text-lg-left text-center">
                      <MDBBtn color="primary" className="min-220px">
                        <a
                          className="white-text"
                          href="mailto:adam@neovia.co.nz"
                        >
                          Send Adam an email
                        </a>
                      </MDBBtn>
                    </div>
                    <div className="text-lg-left text-center">
                      <MDBBtn
                        outline
                        color="primary"
                        onClick={() => this.props.history.push("/contact-us")}
                      >
                        Book an appointment
                      </MDBBtn>
                    </div>
                  </MDBRow>
                </MDBCol>
                <MDBCol md="5" className="text-center mt-lg-0 mt-4">
                  <MDBRow className="mx-auto">
                    <MDBCol md="6">
                      {this.state.data &&
                      this.state.data.image &&
                      this.state.data.image.url ? (
                        <img
                          src={this.state.data.image.url}
                          className="img-fluid"
                        />
                      ) : (
                        <img
                          src={require("../../../src/assets/img/Adam.jpg")}
                          className="img-fluid"
                        />
                      )}
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </div>
        </div>
      </HelmetProvider>
    );
  }
}

export default withRouter(Accounting);

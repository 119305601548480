import { MDBCol, MDBContainer, MDBFooter, MDBRow } from "mdbreact";
import React from "react";
import PrivacyModal from "./PrivacyPolicy";
import "./style.css";

const homeUrl = "/";
const contactUsUrl = "/contact-us";

const DETAILS = [
  {
    name: "Auckland",
    phone: "+64 9 921 0232",
    email: "auckland.office@neovia.co.nz",
  },
  {
    name: "Christchurch",
    phone: "+64 3 366 6087",
    email: "christchurch.office@neovia.co.nz",
  },
  {
    name: "Lincoln",
    phone: "+64 3 325 2409",
    email: "lincoln.office@neovia.co.nz",
  },
  {
    name: "Tauranga",
    phone: "+64 7 928 4818",
    email: "tauranga.office@neovia.co.nz",
  },
];

class FooterPage extends React.Component {
  authCtx = null;

  navigate = (url) => {
    // navigate to top of page
    window.scroll({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  };

  toggleAuthModal = (show, page) => {
    this.authCtx.toggleShow(show, page);
  };

  logout = () => {
    this.authCtx.logout();
  };

  render() {
    this.authCtx = this.context.auth;

    return (
      <MDBFooter color="primary-color" className="font-small font-nanum pt-5">
        <MDBContainer
          className="pt-2 pb-4 text-center text-md-left container-fluid"
          fluid
        >
          <div className="mb-4 pb-3">
            <h3 className="heading-text font-weight-bold mb-4 font-nanum-eb">
              Contact us
            </h3>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              {DETAILS.map((d, i) => (
                <React.Fragment key={i}>
                  <div
                    className="mb-4"
                    style={{ padding: "0 10px", flexGrow: 1 }}
                  >
                    <h4
                      style={{ fontSize: "1.25em" }}
                      className="font-weight-bold"
                    >
                      {d.name}
                    </h4>
                    <p>
                      <i className="fa fa-envelope mr-1" />{" "}
                      <a className="white-text" href={`mailto:${d.email}`}>
                        {d.email}
                      </a>
                    </p>
                    <p>
                      <i className="fa fa-phone mr-1" />{" "}
                      <a className="white-text" href={`tel:${d.phone}`}>
                        {d.phone}
                      </a>
                    </p>
                  </div>
                  {/* {i !== 3 && <div className='vl' />} */}
                </React.Fragment>
              ))}
            </div>
          </div>
          <MDBRow style={{ alignItems: "center" }}>
            <MDBCol md="12" lg="6" className="mb-4 m-lr-auto h6-responsive">
              <div className="pt-3">
                <h3 className="heading-text font-weight-bold mb-4 font-nanum-eb">
                  Neovia Advisory
                </h3>
                <div className="h6-responsive pr-lg-5">
                  Neovia is made up of accountants, bankers, trust specialists,
                  economists, property specialists and administrative experts,
                  with varying years of experience. Our head office is in
                  central Christchurch, with branches in Lincoln and Auckland.
                  <br />
                  <br />
                  Our first step is to always get to know a client personally,
                  consciously listen, build our knowledge about their goals and
                  offer expertise to deliver the required results. <br />
                  <br />
                </div>
              </div>
            </MDBCol>
            <MDBCol md="6" lg="3" className="mx-auto text-center">
              <img
                src={"/loader/new/neovia_logo_vert.svg"}
                alt="logo"
                className="mt-lg-3 footer-logo"
              />
            </MDBCol>
            <MDBCol md="6" lg="3" className="mx-auto text-center">
              <img
                src={require("../../assets/img/logos/rbi-logo-white.png")}
                alt="logo"
                className="mt-lg-3 rbi-footer-logo"
              />
            </MDBCol>
          </MDBRow>
        </MDBContainer>
        <div className="footer-copyright footer-padding text-center">
          <MDBContainer fluid>
            <br />
            <div className="white-text">
              <span className="text-uppercase font-text sf-pro">
                &copy; {new Date().getFullYear()} Copyright Neovia Advisory |{" "}
              </span>
              <a href="#!" style={{ display: "inline-block" }}>
                <PrivacyModal />
              </a>
            </div>
            <p className="text-uppercase mb-2 grey-text font-text sf-pro">
              <a
                href="http://xodesoftware.com/"
                rel="noopener noreferrer"
                alt="Designed by xodesoftware.com"
                target="_blank"
              >
                Designed by Xode
              </a>
            </p>
          </MDBContainer>
        </div>
      </MDBFooter>
    );
  }
}
export default FooterPage;

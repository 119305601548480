


const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export class DateHelper {

    getDateStr(dateStr) {
        if(!dateStr || dateStr.length === 0)
            return '';

        let date = new Date(dateStr);
        let day = this.padDate(date.getDate().toString(), 2);
        let month = date.getMonth();
        // let paddedMonth = this.padDate((date.getMonth() + 1).toString(), 2);
        let monthName = MONTHS[month];
        let year = date.getFullYear();

        return (day + ' ' + monthName + ' ' + year);
    }

    padDate(str, length) {
        while (str.length < length) {
            str = '0' + str;
        }
        return str;
    }
}
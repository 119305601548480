import {
  MDBAnimation,
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBMask,
  MDBRow,
} from "mdbreact";
import React from "react";
import { withRouter } from "react-router-dom";
import ScrollToTop from "../Nav/ScrollToTop";

import { Helmet, HelmetProvider } from "react-helmet-async";
import DataRequestor, { GET, STRAPI_URL } from "../../data/DataRequestor";
// import ReactGA from 'react-ga';

import "./style.css";

class Trusts extends React.Component {
  // componentDidMount() {
  //     ReactGA.initialize('UA-103173484-2');
  //     ReactGA.pageview('/data-company');
  // }
  constructor(props) {
    super(props);
    this.state = {
      requestor: new DataRequestor(),
      data: [],
    };
  }

  componentDidMount() {
    const adamUrl = STRAPI_URL + "/team-members/3";
    this.state.requestor.fetch(this, adamUrl, GET);
  }

  fetchSuccess(data) {
    if (data) {
      this.setState({
        data: data,
      });
    }
  }

  render() {
    return (
      <HelmetProvider>
        <div>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Virtual CFO | Neovia Advisory</title>
            <link rel="canonical" href="https://www.neovia.co.nz/virtual-CFO" />
          </Helmet>
          <ScrollToTop />
          <div className="strategic-header d-flex justify-content-center align-items-center page-header header-filter clear-filter bg-gradient-overlay">
            <div className="header-text">
              <h2 className="h4-responsive text-left center font-weight-md mb-4 pl-lg-2 secondary-text font-nanum-b sf-compact">
                Virtual CFO
              </h2>
              <h1 className="h1-responsive text-left center font-weight-md mb-4 pl-lg-2 font-nanum-b">
                Supporting you through your business growing pains.
              </h1>
            </div>
          </div>
          <div id="about">
            <div className="padding-lg">
              <MDBAnimation reveal type="fadeIn" className="slow">
                <MDBContainer className="pt-5 pb-5">
                  <MDBRow>
                    <MDBCol md="5" className="mx-auto text-center pb-4">
                      <h2 className="display-h2 font-weight-md text-lg-right text-left mb-3 tblue-text font-nanum-eb">
                        Does your business need a financial executive?
                      </h2>
                      <h6 className="h5-responsive text-lg-right text-left font-space-xs mt-lg-5 font-nanum-eb sf-pro-tight">
                        As a company's revenue grows and financial goals are
                        met, your business may need a financial executive.
                      </h6>
                    </MDBCol>
                    <MDBCol
                      md="7"
                      className="mx-auto mt-lg-5 pl-lg-5 text-right"
                    >
                      <div className="mt-lg-5">
                        <h6 className="h5-responsive font-weight-md text-left mb-2 font-space-xs">
                          A virtual Chief Financial Officer (CFO) enables
                          smaller organisations to gain access to an experienced
                          financial professional without the overheads of a
                          full-time commitment.
                        </h6>
                        <h6 className="h5-responsive text-left font-space-xs">
                          Our virtual CFO is an operational and financial expert
                          who performs the services of a chief financial officer
                          within your organisation. However, in lieu of
                          delivering those services in-person as a full-time
                          employee, our virtual CFO works remotely and on a
                          contractual, part-time basis.
                        </h6>
                        {/* <MDBBtn outline color="primary" className="mt-4" onClick={() => this.props.history.push('/financial-experts')} >Meet our team</MDBBtn> */}
                      </div>
                    </MDBCol>
                  </MDBRow>
                </MDBContainer>
              </MDBAnimation>
            </div>
          </div>

          <MDBAnimation reveal type="fadeIn" className="slow">
            <div className="trust-banner-1 page-header header-filter clear-filter blue-filter secondary-text">
              <MDBMask overlay="black-strong" className="w-100 pt-5 pb-5">
                <MDBCol md="10" lg="9" xl="8" className="mx-auto">
                  <MDBContainer className="pt-5 pb-5 text-left">
                    <div className="h3-responsive mb-4 mt-2 font-nanum-eb white-text">
                      Interested to learn more about how a virtual CFO could
                      benefit your business?
                    </div>
                    <h6
                      className="white-text mb-4 font-weight-md"
                      style={{ letterSpacing: "0.5px" }}
                    >
                      Get in touch with us to find out more, we would love to
                      hear from you.
                    </h6>
                    {/* <MDBBtn outline color="white">Book an appointment</MDBBtn> */}
                  </MDBContainer>
                </MDBCol>
              </MDBMask>
            </div>
          </MDBAnimation>
          <div className="contact-div text-left">
            <MDBContainer className="mt-5 mb-5">
              <MDBRow className="mx-auto">
                <MDBCol md="7">
                  <h3 className="tblue-text mb-4 font-nanum-eb font-weight-md">
                    Get in touch with Merryn O'Malley
                  </h3>
                  <h6 className="mb-4 font-nanum-eb font-weight-md">
                    Contact our Group Financial Controller for a no obligation
                    consultation to find out more about how we can help you.
                  </h6>

                  <MDBRow className="font-nanum-b mb-3">
                    <MDBCol md="2" lg="2" className="mb-0 m-lr-auto">
                      <p>
                        <b className="font-quatBold">Phone</b>
                      </p>
                    </MDBCol>
                    <MDBCol md="10" lg="10" className="mb-0 m-lr-auto">
                      <p>
                        <a className="secondary-text" href="tel:+64276555456">
                          +64 27 655 5456
                        </a>
                      </p>
                    </MDBCol>
                    <MDBCol md="2" lg="2" className="mb-0 m-lr-auto">
                      <p>
                        <b className="font-quatBold">Email</b>
                      </p>
                    </MDBCol>
                    <MDBCol md="10" lg="10" className="mb-0 m-lr-auto">
                      <p>
                        <a
                          className="secondary-text"
                          href="mailto:merryn@neovia.co.nz"
                        >
                          merryn@neovia.co.nz
                        </a>
                      </p>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <div className="text-lg-left text-center">
                      <MDBBtn color="primary" className="min-220px">
                        <a
                          className="white-text"
                          href="mailto:merryn@neovia.co.nz"
                        >
                          Send Merryn an email
                        </a>
                      </MDBBtn>
                    </div>
                    <div className="text-lg-left text-center">
                      <MDBBtn
                        outline
                        color="primary"
                        onClick={() => this.props.history.push("/contact-us")}
                      >
                        Book an appointment
                      </MDBBtn>
                    </div>
                  </MDBRow>
                </MDBCol>
                <MDBCol md="5" className="text-center mt-lg-0 mt-4">
                  {this.state.data &&
                  this.state.data.image &&
                  this.state.data.image.url ? (
                    <img
                      src={this.state.data.image.url}
                      className="img-fluid contact-card"
                    />
                  ) : (
                    <img
                      src={require("../../../src/assets/img/Merryn.jpg")}
                      className="img-fluid contact-card"
                    />
                  )}
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </div>
        </div>
      </HelmetProvider>
    );
  }
}

export default withRouter(Trusts);

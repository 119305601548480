import {
  MDBAnimation,
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBMask,
  MDBRow,
} from "mdbreact";
import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { withRouter } from "react-router-dom";
import DataRequestor, {
  ENTITY_OURTEAM,
  GET,
  STRAPI_URL,
} from "../../data/DataRequestor";
import ScrollToTop from "../Nav/ScrollToTop";

class OurStory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      requestor: new DataRequestor(),
      memberAdam: [],
      memberJo: [],
      memberMerryn: [],
      directors: [],
    };
  }

  componentDidMount() {
    const adamUrl = STRAPI_URL + "/team-members/2";
    const joUrl = STRAPI_URL + "/team-members/15";
    const merrynUrl = STRAPI_URL + "/team-members/3";
    const groupedUrl = STRAPI_URL + ENTITY_OURTEAM;
    // this.state.requestor.fetch(this, adamUrl, GET, null, 'adam')
    // this.state.requestor.fetch(this, joUrl, GET, null, 'jo')
    // this.state.requestor.fetch(this, merrynUrl, GET, null, 'merryn')
    this.state.requestor.fetch(this, groupedUrl, GET, null, "grouped");
  }

  fetchSuccess(data, id) {
    if (data) {
      if (id === "adam") {
        this.setState({
          memberAdam: data,
        });
      }
      if (id === "jo") {
        this.setState({
          memberJo: data,
        });
      }
      if (id === "merryn") {
        this.setState({
          memberMerryn: data,
        });
      }
      if (id === "grouped") {
        const grouped = data.our_people;
        const directors = grouped
          .map((a) => {
            if (a.title.startsWith("dir") || a.title.startsWith("Dir")) {
              return a;
            }
            return undefined;
          })
          .filter(Boolean);
        this.setState({
          directors: directors[0],
        });
      }
    }
  }

  render() {
    return (
      <HelmetProvider>
        <div>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Our Story | Neovia Advisory | Financial Solutions</title>
            <link rel="canonical" href="https://www.neovia.co.nz/our-story" />
          </Helmet>
          <ScrollToTop />
          <div className="our-story-header d-flex justify-content-center align-items-center page-header header-filter clear-filter bg-gradient-overlay">
            <div className="header-text">
              <h2 className="h4-responsive text-left center font-weight-md mb-4 pl-lg-2 secondary-text font-nanum-b sf-compact">
                Our Story
              </h2>
              <h1 className="h1-responsive text-left center font-weight-md mb-4 pl-lg-2 font-nanum-b">
                Neovia.
              </h1>
            </div>
          </div>
          <div id="our-story">
            <MDBAnimation reveal type="fadeIn" className="slow">
              <div className="padding-lg">
                <MDBContainer className="pt-lg-5 pb-lg-5">
                  <MDBRow className="mt-5 mb-5">
                    <MDBCol md="5" className="mx-auto text-center pb-4">
                      <h2 className="display-h2 font-weight-md text-lg-right text-left mb-3 tblue-text font-nanum-eb">
                        Neovia means new way,
                      </h2>
                      <h6 className="h5-responsive text-lg-right text-left font-space-xs mt-lg-5 font-nanum-eb sf-pro-tight">
                        which is exactly the direction our innovative company is
                        taking to help businesses perform at their best.
                      </h6>
                    </MDBCol>
                    <MDBCol
                      md="7"
                      className="mx-auto mt-lg-5 pl-lg-5  text-lg-right text-left"
                    >
                      <div className="mt-lg-5">
                        <h6 className="h5-responsive font-weight-md text-left mb-2 font-space-xs">
                          While our team has years of experience in traditional
                          accounting and business advisory solutions, we are
                          committed to new ways of working with clients, to
                          deliver even better results.
                        </h6>
                        <h6 className="h5-responsive text-left font-space-xs">
                          Neovia has a strong client base across nearly all
                          business sectors, from farming and property to
                          dentistry and service-based organisations.
                        </h6>
                      </div>
                    </MDBCol>
                  </MDBRow>
                </MDBContainer>
              </div>
            </MDBAnimation>

            <MDBAnimation reveal type="fadeIn" className="slow">
              <div className=" page-header header-filter clear-filter blue-filter secondary-text">
                <MDBMask overlay="black-strong" className="w-100 pt-5 pb-5">
                  <MDBCol md="10" lg="9" xl="8" className="mx-auto">
                    <MDBContainer className="pt-5 pb-5 text-left">
                      <div className="h3-responsive mb-4 mt-2 font-nanum-eb white-text">
                        "We see ourselves as business partners, which starts by
                        developing trusted relationships with our clients. We
                        want to know their needs, wants and goals for the future
                        and to gain an understanding of any problems they are
                        facing. We then develop a plan to help them achieve
                        great outcomes." <br />{" "}
                        <span className="sf-compact secondary-text">
                          - Managing Director Adam Coleman says.
                        </span>
                      </div>
                    </MDBContainer>
                  </MDBCol>
                </MDBMask>
              </div>
            </MDBAnimation>
            <MDBAnimation reveal type="fadeIn" className="slow">
              <div className="text-left" style={{ padding: "3%" }}>
                <MDBContainer className="mt-5 mb-5">
                  <MDBRow className="mx-auto">
                    <MDBCol md="12" className="mx-auto">
                      <h2 className="display-h3 tblue-text font-nanum-eb text-left">
                        Established in May 2021
                      </h2>
                      <h3 className="h4-responsive text-left primary-text mb-5 font-nanum-eb">
                        Neovia was formed by three Christchurch chartered
                        accountants
                      </h3>
                    </MDBCol>
                    {this.state.directors &&
                      this.state.directors.team_members &&
                      this.state.directors.team_members.map(
                        ({ Name, image, title }, i) => {
                          const getTitle = () => {
                            return title.split("|")[0];
                          };
                          return (
                            <MDBCol key={i} md="4" className="text-center mb-4">
                              <img
                                src={image.url}
                                className="img-fluid img-cropped"
                              />
                              <h4 className="h3-responsive mt-4 font-nanum-eb secondary-text">
                                {Name}
                              </h4>
                              <h6 className="mb-2 divider-margin-small text-title  font-nanum font-weight-md h5-responsive">
                                {getTitle()}
                              </h6>
                            </MDBCol>
                          );
                        }
                      )}
                  </MDBRow>
                  <MDBRow className="mx-auto">
                    <MDBCol md="12" className="text-center">
                      <h6 className="h4-responsive text-left primary-text mt-3 font-nanum-eb">
                        They have also brought Lowthers in Auckland and Gilbert
                        &amp; Associates in Lincoln under their wing.
                      </h6>
                    </MDBCol>
                  </MDBRow>
                </MDBContainer>
              </div>
            </MDBAnimation>
            <MDBAnimation reveal type="fadeIn" className="slow">
              <div className=" page-header header-filter clear-filter blue-filter secondary-text">
                <MDBMask overlay="black-strong" className="w-100 pt-5 pb-5">
                  <MDBCol md="10" lg="9" xl="8" className="mx-auto">
                    <MDBContainer className="pt-5 pb-5 text-left">
                      <div className="h3-responsive mb-4 mt-2 font-nanum-eb white-text">
                        "What we think sets Neovia apart from other practices is
                        the quality of our people and the depth of service we
                        provide our clients. We are combining innovation,
                        technology and knowledge to introduce new ways of doing
                        business and redefining what people expect from
                        financial and business advisors." <br />{" "}
                        <span className="sf-compact secondary-text">
                          - Jo McMaster-Finch says.
                        </span>{" "}
                        <br /> "We aim to not only understand our clients' goals
                        but to help define them. We then provide them with the
                        advice and support they need to thrive."
                      </div>
                    </MDBContainer>
                  </MDBCol>
                </MDBMask>
              </div>
            </MDBAnimation>
            <MDBAnimation
              reveal
              type="fadeIn"
              className="slow"
              style={{ backgroundColor: "whitesmoke" }}
            >
              <div
                style={{ backgroundColor: "whitesmoke" }}
                className="pt-5 pb-5 padding-lg"
              >
                <MDBContainer className="pt-5">
                  <MDBRow className="mx-auto">
                    <MDBCol md="7" className="mx-auto">
                      <h2 className="display-h3 tblue-text font-nanum-eb text-left mb-5">
                        Included in Neovia's range of services is:
                      </h2>
                      <h6 className="h5-responsive text-left primary-text mb-5 font-nanum-b">
                        <img
                          src={"/loader/new/swirl.svg"}
                          alt="neovia swirl"
                          className="swirl-bullet img-fluid mr-3"
                        />
                        Business taxation and compliance
                      </h6>
                      <h6 className="h5-responsive text-left primary-text mb-5 font-nanum-b">
                        <img
                          src={"/loader/new/swirl.svg"}
                          alt="neovia swirl"
                          className="swirl-bullet img-fluid mr-3"
                        />
                        Management accounting
                      </h6>
                      <h6 className="h5-responsive text-left primary-text mb-5 font-nanum-b">
                        <img
                          src={"/loader/new/swirl.svg"}
                          alt="neovia swirl"
                          className="swirl-bullet img-fluid mr-3"
                        />
                        Strategic advice
                      </h6>
                      <h6 className="h5-responsive text-left primary-text mb-5 font-nanum-b">
                        <img
                          src={"/loader/new/swirl.svg"}
                          alt="neovia swirl"
                          className="swirl-bullet img-fluid mr-3"
                        />
                        Problem solving
                      </h6>
                      <h6 className="h5-responsive text-left primary-text mb-5 font-nanum-b">
                        <img
                          src={"/loader/new/swirl.svg"}
                          alt="neovia swirl"
                          className="swirl-bullet img-fluid mr-3"
                        />
                        Virtual CFO
                      </h6>
                      <h6 className="h5-responsive text-left primary-text mb-5 font-nanum-b">
                        <img
                          src={"/loader/new/swirl.svg"}
                          alt="neovia swirl"
                          className="swirl-bullet img-fluid mr-3"
                        />
                        Bookkeeping
                      </h6>
                      <h6 className="h5-responsive text-left primary-text mb-5 font-nanum-b">
                        <img
                          src={"/loader/new/swirl.svg"}
                          alt="neovia swirl"
                          className="swirl-bullet img-fluid mr-3"
                        />
                        Accounting software support
                      </h6>
                      <h6 className="h5-responsive text-left primary-text mb-5 font-nanum-b">
                        <img
                          src={"/loader/new/swirl.svg"}
                          alt="neovia swirl"
                          className="swirl-bullet img-fluid mr-3"
                        />
                        Specialist Trustee Advisory Services
                      </h6>
                    </MDBCol>
                    <MDBCol md="5" className="mx-auto">
                      <div className="center text-center">
                        <img
                          src={"/loader/new/swirl.svg"}
                          alt="neovia swirl"
                          className="swirl-small img-fluid mb-5"
                        />
                      </div>
                    </MDBCol>
                  </MDBRow>
                </MDBContainer>
              </div>
            </MDBAnimation>
            <MDBAnimation reveal type="fadeIn" className="slow">
              <div className=" page-header header-filter clear-filter blue-filter secondary-text">
                <MDBMask overlay="black-strong" className="w-100 pt-5 pb-5">
                  <MDBCol md="10" lg="9" xl="8" className="mx-auto">
                    <MDBContainer className="pt-5 pb-5 text-left">
                      <div className="h3-responsive mb-4 mt-2 font-nanum-eb white-text">
                        "Our highly motivated team have a long history of
                        delivering an exceptional client experience and we are
                        constantly introducing new ways to offer them even more.{" "}
                        <br />{" "}
                        <span className="sf-compact secondary-text">
                          - Merryn O'Malley says.
                        </span>{" "}
                        <br /> "Through our relationships with our clients we
                        know that growing businesses aren't just looking for
                        general accounting services. Our clients have told us
                        that and we have responded with our mission to become a
                        leader in innovative service delivery."
                      </div>
                    </MDBContainer>
                  </MDBCol>
                </MDBMask>
              </div>
            </MDBAnimation>
            <MDBAnimation reveal type="fadeIn" className="slow">
              <div className="text-left" style={{ padding: "3%" }}>
                <MDBContainer className="mt-5 mb-5">
                  <MDBRow className="mx-auto">
                    <MDBCol md="12">
                      <h6 className="mb-4 font-nanum-eb font-weight-md mb-2">
                        If you're ready to take your business to the next level
                        and want to partner with trusted professionals, who give
                        sound financial advice, care about you and your
                        business, and provide innovative accounting solutions,
                        then contact the team at Neovia today.
                      </h6>
                      <div className="text-lg-left text-center">
                        <MDBBtn
                          outline
                          color="primary"
                          onClick={() => this.props.history.push("/contact-us")}
                        >
                          Contact us
                        </MDBBtn>
                      </div>
                    </MDBCol>
                  </MDBRow>
                </MDBContainer>
              </div>
            </MDBAnimation>
          </div>
        </div>
      </HelmetProvider>
    );
  }
}

export default withRouter(OurStory);

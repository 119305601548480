import React, { Component } from "react";
import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBRow,
  MDBCol,
  MDBIcon,
} from "mdbreact";
import ReactHtmlParser from "react-html-parser";

class ModalPage extends Component {
  state = {
    modal: false,
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  splitName() {
    const nameSplit = this.props.name.split(" ");
    if (nameSplit && nameSplit.length > 1) return nameSplit[0];

    return this.props.name;
  }

  render() {
    return (
      <MDBContainer
        style={{ padding: "0px", margin: "0px" }}
        className="mx-auto"
      >
        <div
          onClick={this.toggle}
          style={{ cursor: "pointer" }}
          className="mx-auto"
        >
          <img
            src={this.props.image}
            alt="our team"
            className="mx-auto img-fluid pointer no-padding-margin img-cropped-people"
          />
        </div>
        <MDBModal
          isOpen={this.state.modal}
          toggle={this.toggle}
          size="xl"
          backdropClassName="nobackdrop-modal"
          centered
          className=""
        >
          <MDBModalHeader
            toggle={this.toggle}
            className="text-left text-title font-weight-md h6-responsive"
          >
            <h2 className="font-weight-bold secondary-text h4-responsive pb-0">
              About {this.props.name}
            </h2>
            {this.props.title}
          </MDBModalHeader>
          <MDBModalBody>
            <MDBRow>
              <MDBCol md="8" className="pr-lg-4">
                <div className="mb-4 h6-responsive text-left">
                  {ReactHtmlParser(`${this.props.description}`)}
                </div>
                {this.props.qual ? (
                  <>
                    <p className="mb-4 text-left h6-responsive">
                      {this.splitName()} holds a {this.props.qual}
                    </p>
                  </>
                ) : null}
                <MDBRow>
                  <MDBCol md="12" className="text-left pr-lg-4 mb-4">
                    <h2 className="font-weight-bold secondary-text h5-responsive pb-0">
                      Get in touch
                    </h2>
                  </MDBCol>
                  <MDBCol md="2" lg="2" className="text-left">
                    <p>
                      <b className="font-quatRegular">Email</b>
                    </p>
                  </MDBCol>
                  <MDBCol md="10" lg="10" className="text-left">
                    <p>
                      <a
                        className=" pointer"
                        href={`mailto:${this.props.email}`}
                      >
                        {this.props.email}
                      </a>
                    </p>
                  </MDBCol>

                  <MDBCol md="2" lg="2" className="text-left">
                    <p>
                      <b className="font-quatRegular">Phone</b>
                    </p>
                  </MDBCol>
                  <MDBCol md="10" lg="10" className="text-left">
                    <a
                      href={`tel:${this.props.phone}`}
                      className=" mr-3 pointer"
                    >
                      {this.props.phone}
                    </a>
                  </MDBCol>
                  {this.props.mobile ? (
                    <>
                      <MDBCol md="2" lg="2" className="text-left">
                        <p>
                          <b className="font-quatRegular">Mobile</b>
                        </p>
                      </MDBCol>
                      <MDBCol md="10" lg="10" className="text-left">
                        <a
                          href={`tel:${this.props.mobile}`}
                          className=" mr-3 pointer"
                        >
                          {this.props.mobile}
                        </a>
                      </MDBCol>
                    </>
                  ) : null}
                  {this.props.linkedIn ? (
                    <>
                      <MDBCol md="2" lg="2" className="text-left">
                        <p>
                          <b className="font-quatRegular">LinkedIn</b>
                        </p>
                      </MDBCol>
                      <MDBCol md="10" lg="10" className="text-left">
                        <a
                          href={this.props.linkedIn}
                          target="_blank"
                          rel="noopener noreferrer"
                          className=" mr-3 pointer"
                        >
                          <MDBIcon
                            fab
                            icon="linkedin"
                            className="secondary-text"
                            size="1x"
                          />
                        </a>
                      </MDBCol>
                    </>
                  ) : null}
                </MDBRow>
              </MDBCol>
              <MDBCol md="4" className="mx-auto pr-lg-4 mt-lg-0 mt-5">
                <img
                  src={this.props.image}
                  alt="our team"
                  className="rounded mx-auto img-fluid team-img mb-5"
                />
              </MDBCol>
            </MDBRow>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="grey" outline onClick={this.toggle}>
              Close
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
    );
  }
}

export default ModalPage;

import { STRAPI_URL } from "../data/DataRequestor";
export const API_BASE_URL = STRAPI_URL;
export const SK = "5wLp?ZH@UT?HRh5*%6$qVDLv+t&!9KKw";

export const FORM_ID_DIRECT_DEBIT = 36;
export const EXCLUDED_FORM_IDS = [FORM_ID_DIRECT_DEBIT];
export const EXCLUDED_FORM_SUCCESS_DOWNLOAD_IDS = [FORM_ID_DIRECT_DEBIT];

export const FORMS_URL =
  STRAPI_URL + `/forms?live=true&id_nin=${EXCLUDED_FORM_IDS.join(",")}`;
export const FORM_DIRECT_DEBIT_URL =
  STRAPI_URL + `/forms?live=true&id=${EXCLUDED_FORM_IDS.join(",")}`;

export const SUCCESS_COLOR = "#01427e";
export const FAILED_COLOR = "#d32f2f";

export const PAYMENT_FORM_STORAGE_KEY = "payment_form";

export const getQParamByName = (name, url = window.location.href) => {
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};

/* eslint-disable react-hooks/exhaustive-deps */
import { MDBBtn, MDBCol, MDBContainer, MDBRow } from "mdbreact";
/* eslint-disable */
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { STRAPI_URL } from "../../data/DataRequestor";
import { logError } from "../../data/ErrorLogger";
import { useChecklistStore } from "../../stores/checklist";
import { useFormDataStore } from "../../stores/form-data";
import PrivacyModal from "../Footer/PrivacyPolicy";
import { Reload } from "../Loader/Reload";
import { SpinnerCustom } from "../Loader/SpinnerCustom";
import { ChecklistBuilder } from "./ChecklistBuilder";
import {
  ERROR_CODE_BUILDING_FORM_SECTIONS,
  ERROR_CODE_FORM_SUBMISSION_FAILED,
  ERROR_CODE_HANDLE_NEW_SUBMISSION,
  ERROR_CODE_LOADING_FORM,
  ERROR_CODE_UPDATE_FORM_FIELD,
} from "./ChecklistErrorCode";
import {
  buildFormAnswers,
  buildFormState,
  groupSections,
  updateForm,
} from "./ChecklistHelpers";
import { ChecklistLayout } from "./ChecklistLayout";
import { ChecklistResult } from "./ChecklistResult";

// const formUrl = STRAPI_URL + '/form-requests?uid_eq='
const formsUrl = STRAPI_URL + "/forms?name_eq=";
const locationsUrl = STRAPI_URL + "/locations";

const Checklist = (props) => {
  const react = React;
  const data = useFormDataStore((state) => {
    if (state && state.data) {
      return state.data;
    }

    return [];
  });
  const [reload, setReload] = useState(false);
  const setData = useFormDataStore((state) => state.setData);
  const updateValue = useFormDataStore((state) => state.updateValue);
  const clearForm = useFormDataStore((state) => state.clearForm);
  const setCurrentForm = useChecklistStore((state) => state.setCurrentForm);
  const currentForm = useChecklistStore((state) => state.currentForm);
  const [loading, setLoading] = useState(false);
  const [submission, setSubmission] = useState(null);
  const [error, setError] = useState(null);
  const [locations, setLocations] = useState(null);
  const [hasSubmitted, setSubmitted] = useState(false);
  const [validation, setValidation] = useState(null);

  const pathArray = window?.location?.pathname?.split("/");
  const query = pathArray ? pathArray[pathArray.length - 1] : "form";

  const clearFormState = useChecklistStore((state) => state.clearForm);
  const clearDataState = useFormDataStore((state) => state.clearData);

  const handleError = (message, error) => {
    setError(message);
    logError(message, error);
  };

  const resetError = () => {
    setError(null);
  };

  const urlUnEncodeTitle = (formName) => {
    return formName?.replace(/-/g, " ")?.toLowerCase();
  };

  const onHandleNewSubmission = () => {
    try {
      window.sessionStorage.removeItem(query);
      setSubmitted(false);
      clearDataState();
      clearFormState();
      setSubmission(null);
      window.location.reload();
    } catch (e) {
      handleError(ERROR_CODE_HANDLE_NEW_SUBMISSION, e);
    }
  };

  // const load = async () => {
  //   if (query) {
  //     if (reload) {
  //       window.location.reload();
  //     } else {
  //       await getForm();
  //     }
  //   } else {
  //     setCurrentForm([]);
  //     setLocations([]);
  //   }
  // };

  useEffect(() => {
    try {
      if (window.sessionStorage.getItem(query)) {
        setSubmitted(true);
      }
    } catch (e) {
      handleError(null, e);
    }
  }, [query]);

  useEffect(() => {
    let active = true;

    const getLocations = async () => {
      const res = await fetch(locationsUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (res.status === 200) {
        const resJson = await res.json();
        if (resJson && resJson.length > 0) {
          if (active) {
            setLocations(resJson);
          }
          return resJson;
        } else {
          setLocations([]);
          return [];
        }
      }

      return [];
    };

    const getForm = async () => {
      try {
        resetError(null);
        setLoading(true);
        const loc = await getLocations();
        const res = await fetch(formsUrl + urlUnEncodeTitle(query), {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (res.status === 200) {
          const resJson = await res.json();
          // console.log("res", resJson);
          if (resJson && resJson.length > 0) {
            const questionForm = await updateForm(resJson[0], loc);
            const formState = buildFormState(questionForm);

            if (active) {
              setCurrentForm(questionForm);

              let formData = data.find((d) => d.id == questionForm.id);
              if (!formData) {
                // console.log("FormUpdated");
                setData(questionForm.id, formState);
              }
            }
          } else {
            setCurrentForm([]);
          }
        } else {
          handleError(ERROR_CODE_LOADING_FORM, e);
        }
      } catch (e) {
        handleError(ERROR_CODE_LOADING_FORM, e);
      }

      if (reload) {
        setReload(false);
      }
      setLoading(false);
    };

    if (query) {
      getForm();
    } else {
      setCurrentForm([]);
      setLocations([]);
    }

    return () => {
      active = false;
    };
  }, [query, setCurrentForm, setData, reload]);

  const changeHandler = (event) => {
    try {
      updateValue(currentForm.id, event.target.id, event.target.value);
    } catch (e) {
      handleError(ERROR_CODE_UPDATE_FORM_FIELD, e);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setValidation(null);
      const formData = data.find((d) => d.id == currentForm.id);

      const keys = Object.keys(formData);
      let q = null;
      keys.forEach((key) => {
        if (!formData) {
          currentForm.sections.some((v) => {
            if (v.questionKey === key && v.required) {
              q = v.question;
              return true;
            }
            return false;
          });
        }
      });
      setValidation(q);
      if (!q) {
        const formData = data.find((d) => d.id == currentForm.id);
        const answers = await buildFormAnswers(currentForm, formData.form);
        setSubmission(answers);
        // clearForm(currentForm.id);
      }
    } catch (e) {
      handleError(ERROR_CODE_FORM_SUBMISSION_FAILED, e);
    }
  };

  // const clearSubmission = () => {
  //   clearForm(currentForm.id);
  //   setSubmission(null);
  // };

  const getGroupSections = () => {
    try {
      return groupSections(currentForm);
    } catch (e) {
      handleError(ERROR_CODE_BUILDING_FORM_SECTIONS, e);
    }
  };

  const getYear = () => {
    try {
      return new Date().getFullYear();
    } catch (e) {
      return "";
    }
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <SpinnerCustom size="30px" />
      </div>
    );
  }

  if (currentForm && currentForm.length === 0) {
    return (
      <ChecklistLayout history={props?.history}>
        <div style={{ flexDirection: "column" }} className="message-layout">
          <p
            style={{ fontSize: "1.6rem", padding: "0 1.6rem" }}
            className="mt-4"
          >
            Form not found. Please contact Neovia for assistance.
          </p>
          <MDBBtn onClick={() => props?.history?.push("/contact-us")}>
            Contact us
          </MDBBtn>
          <div
            style={{ position: "absolute", bottom: 0 }}
            className="footer-copyright footer-padding text-center"
          >
            <MDBContainer fluid>
              <br />
              <div className="primary-text">
                <span className="text-uppercase font-text sf-pro">
                  &copy; {getYear()} Copyright Neovia Advisory |{" "}
                </span>
                <a href="#!" style={{ display: "inline-block" }}>
                  <PrivacyModal />
                </a>
              </div>
              <p className="text-uppercase mb-2 primary-text font-text sf-pro">
                <a
                  href="http://xodesoftware.com/"
                  rel="noopener noreferrer"
                  alt="Designed by xodesoftware.com"
                  target="_blank"
                >
                  Designed by Xode
                </a>
              </p>
            </MDBContainer>
          </div>
        </div>
      </ChecklistLayout>
    );
  }

  if (hasSubmitted) {
    return (
      <ChecklistLayout history={props?.history}>
        <div style={{ flexDirection: "column" }} className="message-layout">
          <p
            style={{ fontSize: "1.6rem", padding: "0 1.6rem" }}
            className="mt-4"
          >
            Form already completed. We will be in touch.
          </p>
          <MDBBtn onClick={() => onHandleNewSubmission()}>
            Create New Submission
          </MDBBtn>
          <div
            style={{ position: "absolute", bottom: 0 }}
            className="footer-copyright footer-padding text-center"
          >
            <MDBContainer fluid>
              <br />
              <div className="primary-text">
                <span className="text-uppercase font-text sf-pro">
                  &copy; {getYear()} Copyright Neovia Advisory |{" "}
                </span>
                <a href="#!" style={{ display: "inline-block" }}>
                  <PrivacyModal />
                </a>
              </div>
              <p className="text-uppercase mb-2 primary-text font-text sf-pro">
                <a
                  href="http://xodesoftware.com/"
                  rel="noopener noreferrer"
                  alt="Designed by xodesoftware.com"
                  target="_blank"
                >
                  Designed by Xode
                </a>
              </p>
            </MDBContainer>
          </div>
        </div>
      </ChecklistLayout>
    );
  }

  if ((!loading && !currentForm) || error) {
    return (
      <ChecklistLayout history={props?.history}>
        <div className="message-layout">
          <p
            style={{ fontSize: "1.6rem", padding: "0 1.6rem" }}
            className="mt-4"
          >
            Oops something went wrong.
            {error && (
              <div style={{ fontSize: "1.05rem", padding: "0 1.6rem" }}>
                <br />
                {error}
                <br />
              </div>
            )}
            <br />
            <div style={{ fontSize: "1.05rem", padding: "0 1.6rem" }}>
              <Reload callback={() => setReload(true)} />
            </div>
          </p>
          <div
            style={{ position: "absolute", bottom: 0 }}
            className="footer-copyright footer-padding text-center"
          >
            <MDBContainer fluid>
              <br />
              <div className="primary-text">
                <span className="text-uppercase font-text sf-pro">
                  &copy; {getYear()} Copyright Neovia Advisory |{" "}
                </span>
                <a href="#!" style={{ display: "inline-block" }}>
                  <PrivacyModal />
                </a>
              </div>
              <p className="text-uppercase mb-2 primary-text font-text sf-pro">
                <a
                  href="http://xodesoftware.com/"
                  rel="noopener noreferrer"
                  alt="Designed by xodesoftware.com"
                  target="_blank"
                >
                  Designed by Xode
                </a>
              </p>
            </MDBContainer>
          </div>
        </div>
      </ChecklistLayout>
    );
  }

  return (
    <MDBRow style={{ height: "100vh", overflowY: "scroll" }}>
      <MDBCol md="3">
        <div
          className="form-sidebar"
          style={{
            background: `linear-gradient(0deg, rgba(36, 67, 107, 0.6), rgba(36, 67, 107, 0.6)), url(${
              currentForm?.image
                ? currentForm?.image?.url
                : require("../../assets/img/form-bg.jpg")
            })`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "local",
          }}
        >
          <button
            style={{ border: 0, background: "none" }}
            onClick={() => props?.history?.push("/accounting-resources")}
          >
            <img
              className="mb-4"
              src="/loader/new/neovia_full_logo_white.png"
              alt="Neovia"
              style={{ width: "clamp(100px, 70%, 500px)" }}
            />
          </button>
        </div>
      </MDBCol>
      <MDBCol>
        <div className="pt-5 pb-5">
          {!submission && (
            <>
              <div
                className="mb-6 form-title-wrapper justify-between"
                style={{
                  display: "block",
                }}
              >
                <h3 className="text-left mb-0 pl-3 amiko-bold">
                  {currentForm?.name}
                </h3>
                <button
                  style={{
                    display: "block",
                    paddingLeft: "1rem",
                    border: 0,
                    background: "none",
                    fontSize: "12px",
                    outline: "none !important",
                    color: "#EB9258",
                  }}
                  onClick={() => {
                    clearForm(currentForm?.id);
                    setReload(true);
                  }}
                >
                  Clear & restart {currentForm?.name}
                </button>
              </div>
              <div>
                <form onSubmit={handleSubmit}>
                  {currentForm &&
                    getGroupSections().map((section, i) => (
                      <ChecklistBuilder
                        key={i}
                        section={section}
                        changeHandler={changeHandler}
                        loading={loading}
                        errorCallback={handleError}
                      />
                    ))}
                  <MDBRow>
                    <MDBCol style={{ marginLeft: "auto" }} md="9">
                      <MDBBtn type="submit">
                        {loading ? <Spinner /> : "Submit"}
                      </MDBBtn>
                    </MDBCol>
                  </MDBRow>
                  {validation && (
                    <p
                      className="text-center"
                      style={{
                        color: "darkred",
                        fontSize: "14px",
                        lineHeight: "14px",
                        fontWeight: 500,
                        minHeight: "20px",
                      }}
                    >
                      Please ensure required questions are answered: <br />
                      {validation?.slice(0, 200) + "..."}
                    </p>
                  )}
                </form>
              </div>
            </>
          )}
          {submission && (
            <>
              <ChecklistResult
                // clearForm={() => {
                //   clearForm(currentForm.id);
                // }}
                setReload={setReload}
                handleSuccess={() => {
                  // clearForm(currentForm.id);
                }}
                handleError={(message, error) => {
                  setSubmission(null);
                  clearFormState();
                  handleError(message, error);
                }}
                submission={submission}
                query={query}
                locations={locations}
              />
            </>
          )}
        </div>
        <div className="footer-copyright footer-padding text-center">
          <MDBContainer fluid>
            <br />
            <div className="primary-text">
              <span className="text-uppercase font-text sf-pro">
                &copy; {new Date().getFullYear()} Copyright Neovia Advisory |{" "}
              </span>
              <a href="#!" style={{ display: "inline-block" }}>
                <PrivacyModal />
              </a>
            </div>
            <p className="text-uppercase mb-2 primary-text font-text sf-pro">
              <a
                href="http://xodesoftware.com/"
                rel="noopener noreferrer"
                alt="Designed by xodesoftware.com"
                target="_blank"
              >
                Designed by Xode
              </a>
            </p>
          </MDBContainer>
        </div>
      </MDBCol>
    </MDBRow>
  );
};

export default withRouter(Checklist);

/* eslint-disable react-hooks/exhaustive-deps */
import { MDBBtn } from "mdbreact";
import moment from "moment";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import React, { useCallback, useEffect, useState } from "react";
import { STRAPI_URL } from "../../data/DataRequestor";
import { logError } from "../../data/ErrorLogger";
import { useFileUploadStore } from "../../stores/file-upload";
import { EXCLUDED_FORM_SUCCESS_DOWNLOAD_IDS } from "../../utils/constants";
import { Spinner } from "../Loader/Spinner";
import { ERROR_CODE_FORM_CHECKLIST_SUBMIT_PART2_FAILED } from "./ChecklistErrorCode";
import { COMPONENT_TYPE_ENUM } from "./ChecklistHelpers";
import { generatePDF } from "./PDF/Form";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.fonts = {
  AmikoBold: {
    normal:
      "https://taurus-group-uploads.s3.ap-southeast-2.amazonaws.com/Amiko_Regular_fc2170e3cf.ttf",
    bold: "https://taurus-group-uploads.s3.ap-southeast-2.amazonaws.com/Amiko_Bold_17ec7c7243.ttf",
  },
  SFPro: {
    normal:
      "https://taurus-group-uploads.s3.ap-southeast-2.amazonaws.com/SF_Pro_Text_Regular_d55401ff57.otf",
  },
  SFCompact: {
    bold: "https://taurus-group-uploads.s3.ap-southeast-2.amazonaws.com/SF_Compact_Text_Bold_83665afb2f.ttf",
    normal:
      "https://taurus-group-uploads.s3.ap-southeast-2.amazonaws.com/SF_Compact_Text_Regular_0db153ef0d.otf",
  },
};

const formResponseCreateUrl = STRAPI_URL + "/form-responses";

export const ChecklistResult = (props) => {
  const test = React;
  const {
    submission,
    locations,
    query,
    // clearForm,
    handleSuccess,
    handleError,
  } = props;
  const filesToUpload = useFileUploadStore((state) => state.files);

  const [show, setShow] = useState(false);
  const [pdf, setPdf] = useState(null);

  const handlePdfClick = () => {
    try {
      const now = moment();
      if (pdf) {
        pdf.open();
        pdf.download(
          `neovia_${submission.name}_${now.format("ddd-DD-MMM-yyyy")}.pdf`
        );
      }
    } catch (e) {
      logError("-> ChecklistResult -> handlePdfClick", e);
      window.alert("Something went wrong, Try again!");
    }
  };

  const getValuesFromSubmission = () => {
    let name = "";
    let email = "";
    let location = "";
    for (const sections of submission.sections) {
      if (sections.__component !== COMPONENT_TYPE_ENUM.TITLE) {
        if (sections.questionKey === "user-details-name") {
          name = sections.answer;
        }
        if (sections.questionKey === "user-details-email") {
          email = sections.answer;
        }
        if (sections.questionKey === "user-details-location") {
          const locationsPos = locations
            .map((e) => e.location)
            .indexOf(sections.answer);
          if (locationsPos !== -1) {
            location = locations[locationsPos].emails;
          }
        }
      }
    }

    return { name, email, location };
  };

  const fileToBlob = async (file) =>
    new Blob([new Uint8Array(await file.arrayBuffer())], { type: file.type });

  const updateFormResponse = useCallback(async () => {
    try {
      const values = getValuesFromSubmission();
      if (submission.sections) {
        submission.sections = submission.sections.filter((s) => {
          if (s.__component !== "form-inputs.table-input") {
            return true;
          }

          return s.answer && s.answer.length > 0;
        });
      }

      const docDefinition = generatePDF(submission);
      const pdfDocGenerator = pdfMake.createPdf(docDefinition);
      setPdf(pdfDocGenerator);
      pdfDocGenerator.getBlob(async (blob) => {
        try {
          const now = moment();
          const formData = new FormData();
          formData.append(
            `files.response_pdf`,
            blob,
            `${values.name}_${submission.name}_${now.format(
              "ddd-DD-MMM-yyyy"
            )}.pdf`
          );
          if (filesToUpload) {
            for (const file of filesToUpload) {
              formData.append(
                `files.file_uploads`,
                await fileToBlob(file),
                `${file.name}_${values.name}_${now.format(
                  "ddd-DD-MMM-yyyy"
                )}.pdf`
              );
            }
          }
          formData.append(
            "data",
            JSON.stringify({
              date_submitted: now.format("ddd-DD-MMM-yyyy"),
              response: submission,
              email_sent: false,
              name: values.name,
              email: values.email,
              form: submission.name,
              branch_email: values.location,
            })
          );

          const res = await fetch(formResponseCreateUrl, {
            method: "POST",
            body: formData,
          });

          if (res.status === 200) {
            const resJson = await res.json();
            window.sessionStorage.setItem(query, true);
            setShow(true);
          } else {
            handleError(ERROR_CODE_FORM_CHECKLIST_SUBMIT_PART2_FAILED, {});
          }
        } catch (e) {
          handleError(ERROR_CODE_FORM_CHECKLIST_SUBMIT_PART2_FAILED, e);
        }
      });
    } catch (e) {
      handleError(ERROR_CODE_FORM_CHECKLIST_SUBMIT_PART2_FAILED, e);
    }
  }, [query, submission]);

  useEffect(() => {
    updateFormResponse();
  }, [updateFormResponse]);

  useEffect(() => {
    if (show) {
      handleSuccess();
    }
  }, [show]);

  const downloadSuccessDisplay = (
    <>
      <div style={{ fontSize: "1.6rem", padding: "0 1.6rem" }} className="pb-2">
        Thank you for your submission. Download a PDF of your answers below.
      </div>
      <MDBBtn type="button" onClick={() => handlePdfClick()}>
        Download PDF
      </MDBBtn>
    </>
  );

  const basicSuccessDisplay = (
    <>
      <div style={{ fontSize: "1.6rem", padding: "0 1.6rem" }} className="pb-2">
        Thank you for your submission.
      </div>
    </>
  );

  const isExcludedForDownload = EXCLUDED_FORM_SUCCESS_DOWNLOAD_IDS.includes(
    submission.id
  );

  // console.log(submission.id);
  // console.log(isExcludedForDownload);

  return (
    <div style={{ pointerEvents: "auto" }}>
      {!show ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <div>Submitting</div>
          <Spinner />
        </div>
      ) : (
        <div className="download-layout">
          {isExcludedForDownload && basicSuccessDisplay}
          {!isExcludedForDownload && downloadSuccessDisplay}
        </div>
      )}
    </div>
  );
};

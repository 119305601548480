import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import React from "react";
// import BlogHeader from '../HeaderComponents/BlogHeader'
import { Helmet, HelmetProvider } from "react-helmet-async"; // import ReactGA from 'react-ga';
import DataRequestor, {
  ENTITY_BLOG_POSTS,
  ENTITY_BLOG_POSTS_COUNT,
  GET,
  STRAPI_URL,
} from "../../data/DataRequestor";
import { Logger } from "../../data/Logger";
import ScrollToTop from "../Nav/ScrollToTop";
import BlogCard from "./BlogCard";

class BlogPage extends React.Component {
  logger = new Logger();

  constructor(props) {
    super(props);
    this.state = {
      requestor: new DataRequestor(),
      pageCount: 0,
      data: [],
    };
  }

  componentDidMount() {
    let url =
      STRAPI_URL +
      ENTITY_BLOG_POSTS +
      `?_start=0&publishDate_lte=${this.state.requestor.getDateStr()}&_sort=publishDate:DESC`;
    let countUrl = STRAPI_URL + ENTITY_BLOG_POSTS_COUNT;
    this.state.requestor.fetchWithCount(this, url, countUrl, GET);
    // ReactGA.initialize('UA-103173484-2');
    // ReactGA.pageview('/data-blog');
  }

  fetchSuccess(data) {
    if (data) {
      this.setState({
        data: data,
      });
      this.logger.log(`blogs fetch success`);
      this.logger.log(data);
    }
  }

  fetchFailed(error) {
    this.logger.log(error);
  }

  fetchCountSuccess(data) {
    if (data) {
      this.setState({
        pageCount: data,
      });
      this.logger.log(`blogs fetch count success | ${data}`);
    }
  }

  fetchCountFailed(error) {
    this.logger.log(error);
  }

  render() {
    const cards = this.state.data.map((l, i) => {
      return (
        <MDBCol key={l.id} sm="12" md="12" lg="12" className="mb-5">
          <BlogCard key={l.id} {...l} />
        </MDBCol>
      );
    });

    return (
      <HelmetProvider>
        <div>
          <ScrollToTop />
          <Helmet>
            <meta charSet="utf-8" />
            <title>News | Neovia Advisory</title>
            <meta
              name="description"
              content="Read our latest articles and news at Neovia."
            />
            <link rel="canonical" href="https://www.neovia.co.nz/news" />
          </Helmet>
          <div className="news-header d-flex justify-content-center align-items-center page-header header-filter clear-filter bg-gradient-overlay">
            <div className="header-text">
              <h2 className="h4-responsive text-left center font-weight-md mb-4 pl-lg-2 secondary-text font-nanum-b sf-compact">
                Neovia News
              </h2>
              <h1 className="h1-responsive text-left center font-weight-md mb-4 pl-lg-2 font-nanum-b">
                Stay updated with our latest news and articles.
              </h1>
            </div>
          </div>
          <div style={{ backgroundColor: "whitesmoke" }}>
            <MDBContainer id="blog" className="padding-lg">
              <MDBRow>{cards}</MDBRow>
            </MDBContainer>
          </div>
        </div>
      </HelmetProvider>
    );
  }
}

export default BlogPage;

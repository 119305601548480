import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "../src/assets/scss/style.scss";
import "./App.css";
import AboutUs from "./Components/About/About";
import Accounting from "./Components/About/Accounting";
import AustralianProperty from "./Components/About/AustralianProperty";
import CFO from "./Components/About/CFO";
import People from "./Components/About/People";
import Strategic from "./Components/About/Strategic";
import Trusts from "./Components/About/Trusts";
import News from "./Components/Blog/BlogPage";
import NewsPost from "./Components/Blog/SelectedBlogPage";
import Contact from "./Components/Contact/ContactPage";
import Footer from "./Components/Footer/Footer";
import Home from "./Components/Home/HomePage";
import JobVacancies from "./Components/JobVacancies/JobVacancies";
import Nav from "./Components/Nav/Nav";
import OurStory from "./Components/OurStory/OurStory";
import PaymentFailed from "./Components/Payment/PaymentFailed";
import PaymentOption from "./Components/Payment/PaymentOption";
import PaymentPage from "./Components/Payment/PaymentPage";
import PaymentSuccess from "./Components/Payment/PaymentSuccess";
import Resources from "./Components/Resources/Resources";
// import './assets/fonts/Tisa/stylesheet.css'
import "./assets/fonts/Amiko/stylesheet.css";
import "./assets/fonts/SF/stylesheet.css";
import ChecklistContainer from "./Components/Checklist/ChecklistContainer";
import JobVacancy from "./Components/JobVacancies/JobVacancy";
import JobVacancyApplication from "./Components/JobVacancies/JobVacancyApplication";

export function scrollTo(id, offset = 50) {
  var testDiv = document.getElementById(id);
  window.scrollTo({
    top: testDiv.offsetTop - offset,
    behavior: "smooth",
  });
}

function App() {
  useEffect(() => {
    let ele = document.getElementById("init-loader");
    if (ele) {
      window.setTimeout(() => {
        ele.classList.add("loading-container-fade");
        window.setTimeout(() => {
          ele.classList.add("hide-loader");
        }, 1550);
      }, 1050);
    }
  }, []);

  return (
    <div className="App" style={{ overflow: "hidden", color: "#24436B" }}>
      <Router>
        <Switch>
          <Route path="/questionnaires/:title">
            <ChecklistContainer />
          </Route>
          <Route path="/payment-options">
            <Nav />
            <PaymentOption />
            <Footer />
          </Route>
          <Route path="/payment">
            <Nav />
            <PaymentPage />
            <Footer />
          </Route>
          <Route path="/payment-success">
            <Nav />
            <PaymentSuccess />
            <Footer />
          </Route>
          <Route path="/payment-failed">
            <Nav />
            <PaymentFailed />
            <Footer />
          </Route>
          <Route path="/contact-us">
            <Nav />
            <Contact />
            <Footer />
          </Route>
          <Route path="/news">
            <Nav />
            <News />
            <Footer />
          </Route>
          <Route path="/news-latest">
            <Nav />
            <NewsPost />
            <Footer />
          </Route>
          <Route path="/accounting-resources">
            <Nav />
            <Resources />
            <Footer />
          </Route>
          {/* <Route path='/crowdfunding-advisory'>
            <Crowdfunding />
          </Route> */}
          <Route path="/strategic-advisory">
            <Nav />
            <Strategic />
            <Footer />
          </Route>
          <Route path="/australian-rental-property">
            <Nav />
            <AustralianProperty />
            <Footer />
          </Route>
          <Route path="/chartered-accountancy">
            <Nav />
            <Accounting />
            <Footer />
          </Route>
          <Route path="/trust-advisory">
            <Nav />
            <Trusts />
            <Footer />
          </Route>
          <Route path="/virtual-CFO">
            <Nav />
            <CFO />
            <Footer />
          </Route>
          <Route path="/financial-experts">
            <Nav />
            <People />
            <Footer />
          </Route>
          <Route path="/financial-solutions-company">
            <Nav />
            <AboutUs />
            <Footer />
          </Route>
          <Route path="/our-story">
            <Nav />
            <OurStory />
            <Footer />
          </Route>
          <Route path="/job-vacancies">
            <Nav />
            <JobVacancies />
            <Footer />
          </Route>
          <Route path="/job-vacancy">
            <Nav />
            <JobVacancy />
            <Footer />
          </Route>
          <Route path="/job-vacancy-application">
            <Nav />
            <JobVacancyApplication />
            <Footer />
          </Route>
          <Route path="/">
            <Nav />
            <Home />
            <Footer />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;

export const ERROR_CODE_INITIALISE_FORM_COMPONENT_FAILED =
  "E09 - Failed to initialise questionnaire.";
export const ERROR_CODE_LOADING_FORM = "E10 - Failed to load questionnaire.";
export const ERROR_CODE_BUILDING_FORM_SECTIONS =
  "E11 - One or more sections contains errors.";
export const ERROR_CODE_HANDLE_NEW_SUBMISSION =
  "E12 - One or more sections contains errors.";
export const ERROR_CODE_UPDATE_FORM_FIELD =
  "E13 - One or more form fields failed to update.";
export const ERROR_CODE_FORM_SUBMISSION_FAILED =
  "E14 - One or more errors occurred while submitting the questionnaire.";
export const ERROR_CODE_FORM_CHECKLIST_BUILD_FAILED =
  "E14 - One or more errors occurred while building the questionnaire.";
export const ERROR_CODE_FORM_CHECKLIST_SUBMIT_PART2_FAILED =
  "E15 - One or more errors occurred while submitting part of the questionnaire.";

import {
  MDBAnimation,
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBMask,
  MDBRow,
} from "mdbreact";
import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { withRouter } from "react-router-dom";
import ScrollToTop from "../Nav/ScrollToTop";
// import ReactGA from 'react-ga';

import "./style.css";

class AustralianProperty extends React.Component {
  // componentDidMount() {
  //     ReactGA.initialize('UA-103173484-2');
  //     ReactGA.pageview('/data-company');
  // }

  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     requestor: new DataRequestor(),
  //     data: [],
  //   };
  // }

  // componentDidMount() {
  //   const memberUrl = STRAPI_URL + "/team-members/28";
  //   this.state.requestor.fetch(this, memberUrl, GET);
  // }

  // fetchSuccess(data) {
  //   if (data) {
  //     this.setState({
  //       data: data,
  //     });
  //   }
  // }

  render() {
    return (
      <HelmetProvider>
        <div>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Australian Rental Property | Neovia Advisory</title>
            <link
              rel="canonical"
              href="https://www.neovia.co.nz/australian-rental-property"
            />
          </Helmet>
          <ScrollToTop />
          <div className="property-header d-flex justify-content-center align-items-center page-header header-filter clear-filter bg-gradient-overlay">
            <div className="header-text">
              <h2 className="h4-responsive text-left center font-weight-md mb-4 pl-lg-2 secondary-text font-nanum-b sf-compact">
                Australian Rental Property
              </h2>
              <h1 className="h1-responsive text-left center font-weight-md mb-4 pl-lg-2 font-nanum-b">
                Taking care of your Australian and NZ tax obligations.
              </h1>
            </div>
          </div>
          <div id="about">
            <div className="padding-lg">
              <MDBAnimation reveal type="fadeIn" className="slow">
                <MDBContainer className="pt-5 pb-5">
                  <MDBRow>
                    <MDBCol md="5" className="mx-auto text-center pb-4">
                      <h2 className="display-h2 font-weight-md text-lg-right text-left mb-3 tblue-text font-nanum-eb">
                        We specialise in Australian tax returns.
                      </h2>
                      <h6 className="h5-responsive text-lg-right text-left font-space-xs mt-lg-5 font-nanum-eb sf-pro-tight">
                        Have you been filing your Australian tax returns
                        correctly?
                      </h6>
                    </MDBCol>
                    <MDBCol
                      md="7"
                      className="mx-auto mt-lg-5 pl-lg-5 text-right"
                    >
                      <div className="mt-lg-5">
                        <h6 className="h5-responsive font-weight-md text-left mb-2 font-space-xs">
                          If you have an Australian Residential Rental
                          Investment Property, are you aware of your obligations
                          for NZ and Australian tax?
                        </h6>
                        <h6 className="h5-responsive text-left font-space-xs">
                          Are you aware of the NZ tax rules for overseas
                          investments such as these? There is more you need to
                          know than just owning an equivalent rental property in
                          New Zealand.
                        </h6>
                        {/* <MDBBtn outline color="primary" className="mt-4" onClick={() => this.props.history.push('/financial-experts')} >Meet our team</MDBBtn> */}
                      </div>
                    </MDBCol>
                  </MDBRow>
                </MDBContainer>
              </MDBAnimation>
            </div>
          </div>

          <MDBAnimation reveal type="fadeIn" className="slow">
            <div className="property-banner-1 page-header header-filter clear-filter blue-filter secondary-text">
              <MDBMask overlay="black-strong" className="w-100 pt-5 pb-5">
                <MDBCol md="10" lg="9" xl="8" className="mx-auto">
                  <MDBContainer className="pt-5 pb-5 text-left">
                    <div className="h3-responsive mb-4 mt-2 font-nanum-eb white-text">
                      Australia and New Zealand have different rules, and owning
                      an Australian rental property is not equivalent to owning
                      a rental property in New Zealand.
                    </div>
                    <h6
                      className="white-text mb-4 font-weight-md"
                      style={{ letterSpacing: "0.5px" }}
                    >
                      Let us take care of your obligations so you don't expose
                      yourself to unnecessary risk.
                    </h6>
                  </MDBContainer>
                </MDBCol>
              </MDBMask>
            </div>
          </MDBAnimation>

          <MDBAnimation reveal type="fadeIn" className="slow">
            <div className="padding-lg">
              <div className="pt-5 pb-5">
                <MDBContainer>
                  <MDBRow className="mx-auto">
                    <MDBCol md="9" className="mx-auto">
                      <h2 className="display-h3 tblue-text font-nanum-eb text-left">
                        Know your obligations.
                      </h2>
                      <div className="center">
                        <h6 className="bullet-point h5-responsive text-left primary-text mb-5 font-nanum-b">
                          <MDBIcon
                            icon="chevron-right"
                            className="mr-3 sky-text"
                          />
                          Australian Depreciation Rules are quite different from
                          New Zealand, we ensure you are meeting statutory
                          obligations.
                        </h6>
                        <h6 className="bullet-point h5-responsive text-left primary-text mb-5 font-nanum-b">
                          <MDBIcon
                            icon="chevron-right"
                            className="mr-3 sky-text"
                          />
                          Capital Gains Tax rules are different for non-resident
                          property owners.
                        </h6>
                        <h6 className="bullet-point h5-responsive text-left primary-text mb-5 font-nanum-b">
                          <MDBIcon
                            icon="chevron-right"
                            className="mr-3 sky-text"
                          />
                          Capital Gains Tax applies on every property sold for a
                          profit.
                        </h6>
                        <h6 className="bullet-point h5-responsive text-left primary-text mb-5 font-nanum-b">
                          <MDBIcon
                            icon="chevron-right"
                            className="mr-3 sky-text"
                          />
                          Some rental properties are subject to special Federal
                          and State Government grants and special tax refunds.
                          Their tax treatment varies on both sides of the
                          Tasman.
                        </h6>
                      </div>
                    </MDBCol>
                  </MDBRow>
                </MDBContainer>
              </div>
            </div>
          </MDBAnimation>

          <div className="padding-lg" style={{ backgroundColor: "whitesmoke" }}>
            <MDBAnimation reveal type="fadeIn" className="slow pt-2">
              <MDBContainer className="pt-5">
                <MDBCol md="9" className="mx-auto">
                  <h2 className="display-h3 tblue-text font-nanum-eb text-left mb-5">
                    Who is this service for?
                  </h2>
                  <h6 className="h5-responsive text-left primary-text mb-5 font-nanum-b">
                    <MDBIcon icon="check" className="mr-3 sky-text" />
                    Anyone who lives in New Zealand and owns an Australian
                    Investment Property.
                  </h6>
                </MDBCol>
              </MDBContainer>
            </MDBAnimation>

            <MDBAnimation reveal type="fadeIn" className="slow">
              <div className="pb-2 pt-3">
                <MDBContainer className="pt-lg-2 pb-2">
                  <MDBCol md="9" className="mx-auto">
                    <h2 className="display-h3 tblue-text font-nanum-eb text-left mb-5">
                      Utilise our expert knowledge.
                    </h2>
                    <h6 className="h4-responsive text-left primary-text mb-5 font-nanum-eb">
                      Our in-house specialist will ensure your Australian Rental
                      Property is meeting the necessary requirements.
                    </h6>
                    <h6 className="h5-responsive text-left primary-text mb-5 font-nanum-b">
                      <MDBIcon icon="check" className="mr-3 sky-text" /> The
                      Financial Arrangements Rules.
                    </h6>
                    <h6 className="h5-responsive text-left primary-text mb-5 font-nanum-b">
                      <MDBIcon icon="check" className="mr-3 sky-text" />
                      New Zealanders that apply to overseas loans and mortgages.
                    </h6>
                    <h6 className="h5-responsive text-left primary-text mb-5 font-nanum-b">
                      <MDBIcon icon="check" className="mr-3 sky-text" />
                      New Zealand Non-Resident Withholding Tax payable on
                      overseas interest paid.
                    </h6>
                    <h6 className="h5-responsive text-left primary-text mb-5 font-nanum-b">
                      <MDBIcon icon="check" className="mr-3 sky-text" />
                      New Zealand Approved Issuer Levy payable on overseas
                      interest paid.
                    </h6>
                  </MDBCol>
                </MDBContainer>
              </div>
            </MDBAnimation>
          </div>

          <div className="property-banner-2 page-header header-filter clear-filter blue-filter secondary-text">
            <MDBMask overlay="black-strong" className="w-100 pt-5 pb-5">
              <MDBCol md="10" lg="9" xl="8" className="mx-auto">
                <MDBContainer className="pt-5 pb-5 text-left">
                  <div className="h3-responsive mb-4 mt-2 font-nanum-eb white-text">
                    Australian rental property is a niche area of expertise -
                    get in touch to ensure your obligations are met.
                  </div>
                  {/* <div className="text-left">
                                    <MDBBtn outline color="white" onClick={() => this.props.history.push('/contact-us')}>Book an appointment</MDBBtn>
                                </div> */}
                </MDBContainer>
              </MDBCol>
            </MDBMask>
          </div>

          <div className="contact-div text-left">
            <MDBContainer className="mt-5 mb-5">
              <MDBRow className="mx-auto">
                <MDBCol md="7">
                  <h3 className="tblue-text mb-4 font-nanum-eb font-weight-md">
                    Get in touch with Grace Lu
                  </h3>
                  <h6 className="mb-4 font-nanum-eb font-weight-md">
                    Contact our Director Grace Lu for a no obligation
                    consultation to find out more about how we can help you.
                  </h6>

                  <MDBRow className="font-nanum-b mb-3">
                    <MDBCol md="2" lg="2" className="mb-0 m-lr-auto">
                      <p>
                        <b className="font-quatBold">Phone</b>
                      </p>
                    </MDBCol>
                    <MDBCol md="10" lg="10" className="mb-0 m-lr-auto">
                      <p>
                        <a className="secondary-text" href="tel:+64272261228">
                          +64 27 226 1228
                        </a>
                      </p>
                    </MDBCol>
                    <MDBCol md="2" lg="2" className="mb-0 m-lr-auto">
                      <p>
                        <b className="font-quatBold">Email</b>
                      </p>
                    </MDBCol>
                    <MDBCol md="10" lg="10" className="mb-0 m-lr-auto">
                      <p>
                        <a
                          className="secondary-text"
                          href="mailto:grace@neovia.co.nz"
                        >
                          grace@neovia.co.nz
                        </a>
                      </p>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <div className="text-lg-left text-center">
                      <MDBBtn color="primary" className="min-220px">
                        <a
                          className="white-text"
                          href="mailto:grace@neovia.co.nz"
                        >
                          Send Grace an email
                        </a>
                      </MDBBtn>
                    </div>
                    <div className="text-lg-left text-center">
                      <MDBBtn
                        outline
                        color="primary"
                        onClick={() => this.props.history.push("/contact-us")}
                      >
                        Book an appointment
                      </MDBBtn>
                    </div>
                  </MDBRow>
                </MDBCol>
                <MDBCol md="5" className="text-center mt-lg-0 mt-4">
                  <MDBRow className="mx-auto">
                    <MDBCol md="6">
                      <img
                        src={require("../../../src/assets/img/GraceLu.jpg")}
                        alt="Grace Lu"
                        className="img-fluid"
                      />
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </div>
        </div>
      </HelmetProvider>
    );
  }
}

export default withRouter(AustralianProperty);

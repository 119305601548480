export const PREFERRED_FORMAT_THUMB = 'thumbnail';
export const PREFERRED_FORMAT_SMALL = 'small';
export const PREFERRED_FORMAT_MEDIUM = 'medium';
export const PREFERRED_FORMAT_LARGE = 'large';


export default class ImageHelper {
    getImage(image, preferredFormat = PREFERRED_FORMAT_MEDIUM) {
        let url = null;
        if (image) {
            let formats = image.formats;
            let preferred = null;
            try {
                preferred = formats[preferredFormat];
            } catch {

            }
            if (!preferred) {
                while (preferredFormat !== null) {
                    preferredFormat = this.getNextPreferred(preferredFormat);
                }
                if (preferredFormat !== null) {
                    preferred = formats[preferredFormat];
                }
            }

            if (preferredFormat && preferred) {
                url = preferred.url;
            } else {
                preferred = this.getOnlyAvailable(formats);
                if (preferred)
                    url = preferred.url;
            }
        }
        return url;
    }

    getNextPreferred(preferred) {
        switch (preferred) {
            case PREFERRED_FORMAT_THUMB:
                preferred = PREFERRED_FORMAT_SMALL;
            case PREFERRED_FORMAT_SMALL:
                preferred = PREFERRED_FORMAT_MEDIUM;
            case PREFERRED_FORMAT_MEDIUM:
                preferred = PREFERRED_FORMAT_LARGE;
            default:
                preferred = null;
        }
        return preferred;
    }

    getOnlyAvailable(formats) {
        if (!formats)
            return;

        let imgArr = [formats[PREFERRED_FORMAT_THUMB], formats[PREFERRED_FORMAT_SMALL], formats[PREFERRED_FORMAT_MEDIUM], formats[PREFERRED_FORMAT_LARGE]];
        let availableArr = imgArr.filter(i => i !== undefined);
        if (availableArr && availableArr.length > 0) {
            availableArr = availableArr.reverse();
            return availableArr[0];
        }
    }
}
import {
  MDBAnimation,
  MDBBtn,
  MDBCardBody,
  MDBCardTitle,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBRow,
} from "mdbreact";
import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Parallax } from "react-parallax";
import { withRouter } from "react-router-dom";
import { scrollTo } from "../../App";
import DataRequestor, {
  ENTITY_BLOG_POSTS,
  GET,
  STRAPI_URL,
} from "../../data/DataRequestor";
import { Logger } from "../../data/Logger";
import BlogCard from "../Blog/BlogCard";
import ScrollToTop from "../Nav/ScrollToTop";
import LogoSlider from "./LogoSlider";
import "./style.css";

class Home extends React.Component {
  logger = new Logger();

  constructor(props) {
    super(props);
    this.state = {
      requestor: new DataRequestor(),
      limit: 1,
      isMore: true,
      data: [],
      collapsed: false,
    };
  }

  handleTogglerClick = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  componentDidMount() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    this.getData();
  }

  getData() {
    let url =
      STRAPI_URL +
      ENTITY_BLOG_POSTS +
      `?_start=${this.state.data.length}&_limit=${
        this.state.limit
      }&publishDate_lte=${this.state.requestor.getDateStr()}&_sort=publishDate:DESC`;
    this.state.requestor.fetch(this, url, GET);
  }

  fetchSuccess(data) {
    if (data) {
      this.setState({
        data: data,
        isMore: data.length < this.state.limit,
      });
      this.logger.log(`blogs fetch success`);
      this.logger.log(data);
    }
  }

  fetchFailed(error) {
    this.logger.log(error);
  }

  scrollToGetStarted() {
    var testDiv = document.getElementById("get-started");
    window.scroll({
      top: testDiv.offsetTop - 25,
      behavior: "smooth",
    });
  }

  render() {
    const overlay = (
      <div
        id="sidenav-overlay"
        style={{ backgroundColor: "transparent" }}
        onClick={this.handleTogglerClick}
      />
    );

    const cards = this.state.data.map((l, i) => {
      return (
        <MDBCol key={l.Number} sm="12" md="12" lg="12" className="mb-5">
          <BlogCard {...l} />
        </MDBCol>
      );
    });

    return (
      <HelmetProvider>
        <div id="apppage" style={{ overflow: "hidden" }}>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Home | Neovia Advisory</title>
            <link rel="canonical" href="https://www.neovia.co.nz" />
          </Helmet>
          <ScrollToTop />
          <Parallax
            bgImage={require("../../assets/img/home_rocks.jpg")}
            bgImageAlt="Neovia Advisory"
            strength={100}
            className="primary-text bg-gradient-overlay-home"
            // style={{ borderBottom: '6px solid grey' }}
          >
            <MDBContainer className="text-left">
              <div style={{ zIndex: "2", position: "relative" }}>
                <MDBRow className="vertical-align">
                  <MDBCol md="7" className="pt-lg-5 z-9 home-header">
                    <MDBAnimation reveal type="flipInX" className="slow">
                      <h1 className="display-h2 home-header-title pt-5">
                        Providing financial and business advice for
                        extraordinary results.
                      </h1>
                    </MDBAnimation>
                    {/* <hr className="pl-4 mb-2 mt-4 d-inline-block mx-auto hr-line" /> */}
                    <h6
                      className="h3-responsive mt-4 mb-4 home-header-subtitle sf-compact"
                      style={{ marginLeft: 1 }}
                    >
                      Leading a new era in financial services.
                    </h6>
                    {/* <MDBBtn outline color="white" style={{ minWidth: '220px' }} onClick={() => scrollTo('financial-solutions-company')}>About us</MDBBtn> */}
                    {/* <MDBRow center> */}

                    <MDBBtn
                      className="sf-pro"
                      color="info"
                      size="lg"
                      style={{ minWidth: "220px", marginLeft: 0 }}
                      onClick={() => scrollTo("services", -10)}
                    >
                      Our Services
                    </MDBBtn>

                    {/* </MDBRow> */}
                  </MDBCol>
                  <MDBCol md="5" className="1">
                    <img
                      src={require("../../assets/img/logos/NeoviaWhiteSwirlFull-01.svg")}
                      className="swirl-img-temp"
                    />
                  </MDBCol>
                </MDBRow>
              </div>
            </MDBContainer>
            <div className="down-arrow" onClick={() => scrollTo("get-started")}>
              <MDBAnimation type="tada" infinite className="slow">
                <MDBIcon size="1x" icon="chevron-down" />
              </MDBAnimation>
            </div>
            <div style={{ minHeight: "100vh" }} />
          </Parallax>

          <div>
            <LogoSlider />
          </div>

          <div
            style={{ backgroundColor: "whitesmoke" }}
            className="padding-lg"
            id="get-started"
          >
            <MDBContainer className="pt-5 pb-5">
              <MDBRow className="mt-5 mb-5">
                <MDBCol md="5" className="mx-auto text-center pb-4">
                  <MDBAnimation reveal type="fadeIn" className="slow">
                    <div className="amiko-bold display-h2 font-weight-md text-lg-right text-left mb-3 primary-text pt-serif">
                      Smart accounting and business advice
                    </div>
                  </MDBAnimation>
                  <h6 className="sf-pro-tight h5-responsive text-lg-right text-left font-space-xs mt-lg-5 pt-serif">
                    Neovia works to not only understand your goals but to help
                    define them.
                  </h6>
                </MDBCol>
                <MDBCol md="7" className="mx-auto mt-lg-5 pl-lg-5 text-right">
                  <div className="mt-lg-5">
                    <h6 className="h5-responsive text-left mb-2 font-space-xs sf-pro">
                      Elevate your business with our innovative financial
                      management services. We combine vision, technology and
                      years of experience to offer new and better ways of doing
                      business.
                    </h6>
                    <h6 className="h5-responsive text-left font-space-xs sf-pro">
                      Talk to our experts today about reaching your goals.
                    </h6>
                  </div>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </div>

          {/* <MDBAnimation reveal type="fadeIn" className="slow">
                    <div className="home-banner page-header header-filter clear-filter secondary-text">
                        <MDBMask overlay="white-strong" className="w-100 pt-5 pb-5">
                            <MDBCol md="10" lg="9" xl="8" className="mx-auto mt-5 mb-5">
                                <MDBContainer className="pt-5 pb-5 text-left">
                                    <h2 className="text-center primary-text pt-serif">Our purpose is to provide <span className="font-weight-md secondary-text">innovative</span> solutions and identify new opportunities for our clients.</h2>
                                </MDBContainer>
                            </MDBCol>
                        </MDBMask>
                    </div>
                </MDBAnimation> */}
          <MDBContainer id="services" className="pt-5 pb-4">
            <MDBAnimation reveal type="fadeIn" className="slow">
              <MDBContainer className="pt-5 mx-auto">
                <h2 className="text-center mt-5 mb-5 display-h2 font-weight-md primary-text pt-serif">
                  Our Services.
                </h2>
                {/* <MDBRow className="mx-auto"> */}
                {/* <MDBCol md="11" className="mx-auto ml-0 pl-0"> */}
                <h3 className="h3-responsive secondary-text pt-serif text-left mb-lg-5 mb-4 pt-2">
                  Neovia provides a comprehensive suite of services to help you
                  and your business thrive.
                </h3>
                <h5
                  className="h5-responsive text-left mb-1 pt-serif"
                  style={{ lineHeight: "30px" }}
                >
                  <div className="mb-2">Our team specialises in:</div>
                  <li>protecting your finances and assets</li>
                  <li>working strategically to improve business performance</li>
                  <li>
                    implementing new technology that builds business resilience
                  </li>
                  <li>providing robust advice and financial solutions</li>
                </h5>
                {/* </MDBCol> */}
                {/* </MDBRow> */}
              </MDBContainer>
            </MDBAnimation>
            {/* </div> */}

            <div className="">
              <div className="padding-lg">
                <MDBAnimation reveal type="fadeIn" className="slow">
                  <MDBContainer className="mx-auto">
                    {/* <hr className="pl-4 mb-4 mt-2 d-inline-block mx-auto hr-line" /> */}
                    <MDBRow className="pb-5">
                      <MDBCol
                        sm="12"
                        md="6"
                        lg="4"
                        className="mx-auto no-padding-margin-sm"
                        onClick={() =>
                          this.props.history.push("/chartered-accountancy")
                        }
                      >
                        <div className="text-left mb-2 card-border">
                          <MDBCardTitle className="text-left sub-title">
                            <MDBRow>
                              {/* <MDBCol md="12">
                                                        <img src={require('../../assets/img/icons/laws.png')} alt="accounting icon" className="small-icon" />
                                                    </MDBCol> */}
                              <MDBCol md="12" className="mx-auto">
                                <div className="font-weight-md primary-text pt-serif center h6-responsive pt-4">
                                  Chartered Accountancy
                                </div>
                              </MDBCol>
                            </MDBRow>
                          </MDBCardTitle>
                          <MDBCardBody className="pt-0  ml-2 mr-2 small-text pt-serif">
                            We are your experienced accountants and tax experts,
                            providing business advice, tax solutions, business
                            metric and forecast reporting, and accounting
                            software support.
                            <div className="mt-4 h5-responsive pointer text-small font-weight-md read-more">
                              Learn more{" "}
                              <MDBIcon fa icon="arrow-right" className="ml-2" />
                            </div>
                          </MDBCardBody>
                          {/* <div><MDBBtn outline color="grey" className="ml-lg-4 mb-4">Learn more</MDBBtn></div> */}
                        </div>
                      </MDBCol>
                      <br />
                      <MDBCol
                        sm="12"
                        md="6"
                        lg="4"
                        className="mx-auto no-padding-margin-sm"
                        onClick={() =>
                          this.props.history.push("/trust-advisory")
                        }
                      >
                        <div className="text-left mb-2 card-border">
                          <MDBCardTitle className="text-left sub-title">
                            <MDBRow>
                              {/* <MDBCol md="12">
                                                        <img src={require('../../assets/img/icons/growth.png')} alt="growth icon" className="small-icon" />
                                                    </MDBCol> */}
                              <MDBCol md="12" className="mx-auto">
                                <div className="font-weight-md primary-text pt-serif h6-responsive center pt-4">
                                  Trust Advisory
                                </div>
                              </MDBCol>
                            </MDBRow>
                          </MDBCardTitle>
                          <MDBCardBody className="pt-0  ml-2 mr-2 small-text pt-serif">
                            Our Trust Division works with clients, alongside the
                            company's expert tax advisors, to advise on how to
                            protect assets from future potential events and
                            situations.
                            <div className="mt-4 h5-responsive pointer text-small font-weight-md read-more">
                              Learn more{" "}
                              <MDBIcon fa icon="arrow-right" className="ml-2" />
                            </div>
                          </MDBCardBody>
                        </div>
                      </MDBCol>
                      <MDBCol
                        sm="12"
                        md="6"
                        lg="4"
                        className="mx-auto no-padding-margin-sm"
                        onClick={() =>
                          this.props.history.push("/strategic-advisory")
                        }
                      >
                        <div className="text-left mb-2 card-border">
                          <MDBCardTitle className="text-left sub-title">
                            <MDBRow>
                              {/* <MDBCol md="12">
                                                        <img src={require('../../assets/img/icons/investment.png')} alt="investment icon" className="small-icon" />
                                                    </MDBCol> */}
                              <MDBCol md="12" className="mx-auto">
                                <div className="font-weight-md primary-text pt-serif h6-responsive center pt-4">
                                  Strategic Advisory
                                </div>
                              </MDBCol>
                            </MDBRow>
                          </MDBCardTitle>
                          <MDBCardBody className="pt-0  ml-2 mr-2 small-text pt-serif">
                            Businesses may face challenges that require guidance
                            and support. Watch your business performance improve
                            with advice from our Strategic Advisory team.
                            <div className="mt-4 h5-responsive pointer text-small font-weight-md read-more">
                              Learn more{" "}
                              <MDBIcon fa icon="arrow-right" className="ml-2" />
                            </div>
                          </MDBCardBody>
                        </div>
                      </MDBCol>
                      {/* <MDBCol
                      sm='12'
                      md='6'
                      lg='4'
                      className='mx-auto no-padding-margin-sm'
                      onClick={() => this.props.history.push('/crowdfunding-advisory')}
                    >
                      <div className='text-left mb-2 card-border'>
                        <MDBCardTitle className='text-left sub-title'>
                          <MDBRow>
                            <MDBCol md='12' className='mx-auto'>
                              <div className='font-weight-md primary-text pt-serif h6-responsive center pt-4'>Crowdfunding Advisory</div>
                            </MDBCol>
                          </MDBRow>
                        </MDBCardTitle>
                        <MDBCardBody className='pt-0  ml-2 mr-2 small-text pt-serif'>
                          We are specialists in providing solutions for business to grow and achieve their business' dreams. We are
                          experienced in managing and growing funds through the crowdfunding process.
                          <div className='mt-4 h5-responsive pointer text-small font-weight-md read-more'>
                            Learn more <MDBIcon fa icon='arrow-right' className='ml-2' />
                          </div>
                        </MDBCardBody>
                      </div>
                    </MDBCol> */}
                      <MDBCol
                        sm="12"
                        md="6"
                        lg="4"
                        className="no-padding-margin-sm"
                        onClick={() =>
                          this.props.history.push("/australian-rental-property")
                        }
                      >
                        <div className="text-left mb-2 card-border">
                          <MDBCardTitle className="text-left sub-title">
                            <MDBRow>
                              {/* <MDBCol md="12">
                                                        <img src={require('../../assets/img/icons/asset.png')} alt="asset icon" className="small-icon" />
                                                    </MDBCol> */}
                              <MDBCol md="12" className="mx-auto">
                                <div className="font-weight-md primary-text pt-serif h6-responsive center pt-4">
                                  Australian Property
                                </div>
                              </MDBCol>
                            </MDBRow>
                          </MDBCardTitle>
                          <MDBCardBody className="pt-0  ml-2 mr-2 small-text pt-serif">
                            Are you aware of the NZ tax rules for overseas
                            residential property investments? There is more you
                            need to know than just owning an equivalent rental
                            property in New Zealand.
                            <div className="mt-4 h5-responsive pointer text-small font-weight-md read-more">
                              Learn more{" "}
                              <MDBIcon fa icon="arrow-right" className="ml-2" />
                            </div>
                          </MDBCardBody>
                        </div>
                      </MDBCol>
                      <MDBCol
                        sm="12"
                        md="6"
                        lg="4"
                        className="no-padding-margin-sm"
                        onClick={() =>
                          this.props.history.push(
                            "/virtual-chief-financial-officer"
                          )
                        }
                      >
                        <div className="text-left mb-2 card-border">
                          <MDBCardTitle className="text-left sub-title">
                            <MDBRow>
                              {/* <MDBCol md="12">
                                                        <img src={require('../../assets/img/icons/coaching.png')} alt="coaching icon" className="small-icon" />
                                                    </MDBCol> */}
                              <MDBCol md="12" className="mx-auto">
                                <div className="font-weight-md primary-text pt-serif h6-responsive center pt-4">
                                  Virtual CFO
                                </div>
                              </MDBCol>
                            </MDBRow>
                          </MDBCardTitle>
                          <MDBCardBody className="pt-0  ml-2 mr-2 small-text pt-serif">
                            As a company's revenue grows and financial goals are
                            met, your business may need a financial executive.
                            Reduce your growing pains with our virtual CFO
                            services, utilising our expertise.
                            <div className="mt-4 h5-responsive pointer text-small font-weight-md read-more">
                              Learn more{" "}
                              <MDBIcon fa icon="arrow-right" className="ml-2" />
                            </div>
                          </MDBCardBody>
                        </div>
                      </MDBCol>
                    </MDBRow>
                  </MDBContainer>
                </MDBAnimation>
              </div>
            </div>
          </MDBContainer>

          <div className="primary-bg pt-5 pb-5">
            <MDBContainer>
              <h2 className="h1-responsive white-text text-center font-nanum mb-4 mt-3">
                We are New Zealand wide.
              </h2>
              <MDBAnimation reveal type="fadeIn" className="slow pt-4 pb-4">
                <MDBRow className="text-left">
                  <MDBCol md="4">
                    <div className="display-h3 font-weight-md text-center mb-3 secondary-text pt-serif">
                      Christchurch
                    </div>
                  </MDBCol>
                  <MDBCol md="4">
                    <div className="display-h3 font-weight-md text-center mb-3 secondary-text pt-serif">
                      Lincoln
                    </div>
                  </MDBCol>
                  <MDBCol md="4">
                    <div className="display-h3 font-weight-md text-center mb-3 secondary-text pt-serif">
                      Auckland
                    </div>
                  </MDBCol>
                </MDBRow>
                <MDBBtn
                  color="white"
                  outline
                  className="mt-5"
                  onClick={() => this.props.history.push("contact-us")}
                >
                  View all Locations
                </MDBBtn>
              </MDBAnimation>
            </MDBContainer>
          </div>

          <MDBAnimation reveal type="fadeIn" className="slow">
            <div
              style={{ backgroundColor: "whitesmoke" }}
              className="pt-4 pb-4"
            >
              <div className="padding-lg">
                {/* <MDBContainer className="text-left pt-5 pb-4"> */}
                <div className="mt-lg-5 text-center">
                  <MDBRow className="mt-lg-5 mb-lg-5">
                    <MDBCol md="7" className="mx-auto text-left pr-lg-5">
                      <div className="">
                        <img
                          src={require("../../assets/img/team.jpeg")}
                          alt="photo"
                          className="img-fluid mb-5 mx-auto"
                          style={{ maxHeight: "550px" }}
                        />
                      </div>
                    </MDBCol>
                    <MDBCol md="5" className="mx-auto text-center pb-4 pr-lg-5">
                      <div className="center">
                        <MDBContainer>
                          <h2 className="display-h2 font-weight-md text-left mb-3 primary-text pt-serif">
                            A financial solutions company.
                          </h2>
                          <h6 className="h5-responsive text-left font-space-xs mt-lg-5 mt-4 mb-1 pt-serif">
                            Our passionate team of experts is dedicated to
                            looking after our clients, priding ourselves on
                            getting it right the first time. We build trusting
                            relationships with a tailored approach for each
                            individual client.
                          </h6>
                        </MDBContainer>
                      </div>
                    </MDBCol>
                  </MDBRow>
                </div>
                {/* </MDBContainer> */}
              </div>
            </div>
          </MDBAnimation>

          <div className="primary-bg pt-5 pb-5">
            <MDBContainer>
              <h2 className="h1-responsive white-text text-lg-left text-center font-nanum mb-4 mt-3">
                Creating a movement of{" "}
                <span className="font-weight-md">brighter</span> financial
                futures.
              </h2>
              <MDBAnimation reveal type="flipInX" className="slow pt-4 pb-4">
                <MDBRow className="text-lg-left text-center">
                  <MDBCol md="4">
                    <h6>We have clients across</h6>
                    <div className="display-h3 font-weight-md mb-3 secondary-text pt-serif text-lg-left text-center">
                      16 cities
                    </div>
                  </MDBCol>
                  <MDBCol md="4">
                    <h6>Our growing number</h6>
                    <div className="display-h3 font-weight-md mb-3 secondary-text pt-serif text-lg-left text-center">
                      2191 clients
                    </div>
                  </MDBCol>
                  <MDBCol md="4">
                    <h6>We have more than</h6>
                    <div className="display-h3 font-weight-md mb-3 secondary-text pt-serif text-lg-left text-center">
                      15 experts
                    </div>
                  </MDBCol>
                </MDBRow>
              </MDBAnimation>
            </MDBContainer>
          </div>

          <MDBAnimation reveal type="fadeIn" className="slow pt-4 pb-2">
            <div className="padding-lg">
              <MDBContainer className="h5-responsive pt-5 pb-5">
                <h2 className="display-h2 font-weight-md text-left mb-3 primary-text pt-serif">
                  Who we are.
                </h2>
                <MDBRow className="mx-auto mb-lg-5">
                  <MDBCol md="5" className="mx-auto">
                    <div className="center text-center">
                      <img
                        src={"/loader/new/swirl.svg"}
                        alt="neovia swirl"
                        className="swirl-small img-fluid mt-lg-0 mt-3 mb-4"
                      />
                    </div>
                  </MDBCol>
                  <MDBCol md="7" className="mx-auto text-left">
                    <p className="mt-4 mb-4 pt-serif">
                      We are a team of experts with a range of divisions that
                      work together to create a full service, end to end
                      financial solution that will help generate and protect
                      your wealth for a growth based financial future.
                    </p>
                    <div style={{ lineHeight: "45px" }} className="pt-serif">
                      <MDBIcon icon="check" className="mr-3 primary-text" />{" "}
                      Chartered Accountants
                      <br />
                      <MDBIcon
                        icon="check"
                        className="mr-3 primary-text"
                      />{" "}
                      Trust Advisors
                      <br />
                      <MDBIcon
                        icon="check"
                        className="mr-3 primary-text"
                      />{" "}
                      Strategic Advisors
                      <br />
                      <MDBIcon
                        icon="check"
                        className="mr-3 primary-text"
                      />{" "}
                      Property Specialists
                      <br />
                      <MDBIcon
                        icon="check"
                        className="mr-3 primary-text"
                      />{" "}
                      Financial Controllers
                      <br />
                    </div>
                    <div
                      onClick={() =>
                        this.props.history.push("/financial-solutions-company")
                      }
                      className="mt-4 h5-responsive pointer text-small font-weight-md read-more pt-serif"
                    >
                      Learn more{" "}
                      <MDBIcon fa icon="arrow-right" className="ml-2" />
                    </div>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </div>
          </MDBAnimation>

          {/* <MDBAnimation reveal type="fadeIn" className="slow">
                    <div className="home-banner page-header header-filter clear-filter secondary-text">
                        <MDBMask overlay="white-strong" className="w-100 pt-5 pb-5">
                            <MDBCol md="10" lg="9" xl="8" className="mx-auto mt-5 mb-5">
                                <MDBContainer className="pt-5 pb-5 text-left">
                                    <h3 className="text-center primary-text pt-serif">We have a focus on supporting New Zealand families, and we think outside the box to make their dreams happen.</h3>
                                </MDBContainer>
                            </MDBCol>
                        </MDBMask>
                    </div>
                </MDBAnimation> */}

          {/* <MDBAnimation reveal type="fadeIn" className="slow">
                    <div className="padding-lg">
                        <MDBContainer className="pt-5 pb-5 secondary-text">
                            <h2 className="text-center primary-text h1-responsive pt-serif mt-5 mb-4">What our bright futured clients have to say.</h2>
                            <div className="text-center mb-5">
                                <MDBContainer className="pb-lg-5">

                                    <MDBCarousel
                                        activeItem={1}
                                        length={1}
                                        interval={false}
                                        showIndicators={true}
                                        showControls={true}
                                        autoPlay={true}
                                        interval={15000}
                                        className='no-flex'
                                        style={{ minHeight: '400px' }}
                                    >
                                        <MDBCarouselInner>
                                            <MDBCarouselItem itemId='1' className="text-center mt-5">
                                                <MDBCol md="7" className="mx-auto">
                                                    {/* <div className='mx-auto mb-4 mt-5'>
                                                        <img src={require('../../assets/img/Steve-n-Vic.jpg')} alt="Steve and Vic" className="img-fluid square-img customer-img mb-2" style={{ filter: 'grayscale(100%)' }} />
                                                    </div> */}
          {/* <MDBRow>
                                                        <span style={{ lineHeight: '30px' }}><MDBIcon size="2x" icon='quote-left mr-2 mb-5 text-center' />
                                                            <h4 className="testimonal font-nanum primary-text">
                                                                Our goal has always been to bring life and new ideas to Christchurch, and Neovia has played a central role in making this happen.
                                                            </h4></span>
                                                    </MDBRow>
                                                    <h4 className='font-weight-bold mt-2 mb-5 h5-responsive font-nanum-b'>Steve and Victoria Pomeroy</h4>
                                                    {/* <h6 className='font-weight-bold my-3 pb-2 h5-responsive primary-text'>Pomeroy's Old Brewery Inn</h6> */}
          {/* <img
                                                        src={require('../../assets/img/logos/pomeroys.svg')}
                                                        className='img-fluid mt-4'
                                                        alt="Pomeroy's Old Brewery Inn"
                                                        style={{ maxWidth: '350px' }}
                                                    />
                                                </MDBCol>  */}
          {/* </MDBCarouselItem>
                                        </MDBCarouselInner>
                                    </MDBCarousel> */}

          {/* //     </MDBContainer> */}
          {/* // </div> */}
          {/* <MDBRow className="pt-5 mx-auto text-center">
                                <MDBCol md="4">
                                    <img
                                        src={require('../../assets/img/logos/placeholder.png')}
                                        className='img-fluid mt-5 mb-lg-5 mb-4 logo-grey'
                                        alt="Pomeroy's Old Brewery Inn"
                                        style={{ width: '300px' }}
                                    />
                                </MDBCol>
                                <MDBCol md="4">
                                    <img
                                        src={require('../../assets/img/logos/placeholder.png')}
                                        className='img-fluid mt-5 mb-lg-5 mb-4 logo-grey'
                                        alt="Pomeroy's Old Brewery Inn"
                                        style={{ maxWidth: '300px' }}
                                    />
                                </MDBCol>
                                <MDBCol md="4">
                                    <img
                                        src={require('../../assets/img/logos/placeholder.png')}
                                        className='img-fluid mt-5 mb-lg-5 mb-4 logo-grey'
                                        alt="Pomeroy's Old Brewery Inn"
                                        style={{ maxWidth: '300px' }}
                                    />
                                </MDBCol>
                                <MDBCol md="6">
                                    <img
                                        src={require('../../assets/img/logos/placeholder.png')}
                                        className='img-fluid mt-5 mb-lg-5 mb-4 logo-grey'
                                        alt="Pomeroy's Old Brewery Inn"
                                        style={{ maxWidth: '300px' }}
                                    />
                                </MDBCol>
                                <MDBCol md="6">
                                    <img
                                        src={require('../../assets/img/logos/placeholder.png')}
                                        className='img-fluid mt-5 mb-lg-5 mb-4 logo-grey'
                                        alt="Pomeroy's Old Brewery Inn"
                                        style={{ maxWidth: '300px' }}
                                    />
                                </MDBCol>
                            </MDBRow> */}
          {/* </MDBContainer>
                    </div>
                </MDBAnimation> */}

          <MDBAnimation reveal type="fadeIn" className="slow">
            <div
              className="padding-lg"
              style={{ backgroundColor: "whitesmoke" }}
            >
              <MDBContainer className="pb-5 pt-4">
                <h2 className="primary-text display-h3 mt-2 text-left pt-serif">
                  Our latest news.
                </h2>
              </MDBContainer>
              <MDBContainer>
                <MDBRow>{cards}</MDBRow>
                <MDBBtn
                  color="primary"
                  size="lg"
                  className="mb-4 mt-4"
                  onClick={() => this.props.history.push("/news")}
                >
                  View all news
                </MDBBtn>
              </MDBContainer>
            </div>
          </MDBAnimation>
        </div>
      </HelmetProvider>
    );
  }
}

export default withRouter(Home);

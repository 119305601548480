import {
  MDBAnimation,
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBMask,
  MDBRow,
  MDBView,
} from "mdbreact";
import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { withRouter } from "react-router-dom";
import TitleSlider from "../Home/TitleSlider";
import ScrollToTop from "../Nav/ScrollToTop";
import { TeamMembersData } from "./TeamMemberData";
import TeamMemberModal from "./TeamMemberModal";
// import ReactGA from 'react-ga';

import "./style.css";

class AboutUs extends React.Component {
  state = {
    modal: false,
    selectedTeamMember: null,
  };

  // componentDidMount() {
  //     ReactGA.initialize('UA-103173484-2');
  //     ReactGA.pageview('/data-company');
  // }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  setTeamMember = (id = null, member = null) => {
    let tMember = member;
    let top = null;

    this.setState({
      selectedTeamMember: tMember,
      top: top,
    });
  };

  render() {
    const teamMembers = TeamMembersData.map((m, i) => {
      let tmEleId = `team-member-${m.id}`;
      let tmEmail = `mailto:${m.email}?subject=Enquiry`;
      let tmLinkdin = m.linkedin;
      let tmImage = m.image;
      let tmApptUrl = m.appturl;

      return (
        <MDBCol
          id={tmEleId}
          sm="12"
          md="4"
          lg="3"
          className="mb-4 text-center mx-auto"
        >
          <TeamMemberModal
            image={m.image}
            qual={m.qual}
            mobile={m.mobile_number}
            phone={m.phone_number}
            description={m.blurb}
            name={m.name}
            title={m.title}
            email={m.email}
            linkedIn={m.linkedin}
            apptUrl={m.appturl}
          />
          <h4 className="h4-responsive mt-4 font-weight-bold flock-blue">
            {m.name}
          </h4>
          <h6 className="mb-2 divider-margin-small text-title flock-grey font-weight-md h5-responsive">
            {m.title}
          </h6>
          <ul className="list-unstyled mb-0">
            <a href={tmEmail} className="p-2 fa-lg pointer">
              <MDBIcon fa-lg icon="envelope" className="flock-grey" />
            </a>
            {tmLinkdin ? (
              <a
                href={tmLinkdin}
                target="_blank"
                rel="noopener noreferrer"
                className="p-2 fa-lg pointer"
              >
                <MDBIcon fab icon="linkedin" className="flock-grey" />
              </a>
            ) : null}
          </ul>
        </MDBCol>
      );
    });

    return (
      <HelmetProvider>
        <div>
          <Helmet>
            <meta charSet="utf-8" />
            <title>About Us | Neovia Advisory | Financial Solutions</title>
            <link
              rel="canonical"
              href="https://www.neovia.co.nz/financial-solutions-company"
            />
          </Helmet>
          <ScrollToTop />
          <div className="about-header d-flex justify-content-center align-items-center page-header header-filter clear-filter bg-gradient-overlay">
            <div className="header-text">
              <h2 className="h4-responsive text-left center font-weight-md mb-4 pl-lg-2 secondary-text font-nanum-b sf-compact">
                About Us
              </h2>
              <h1 className="h1-responsive text-left center font-weight-md mb-4 pl-lg-2 font-nanum-b">
                A financial solutions company.
              </h1>
            </div>
          </div>
          <div id="about">
            <MDBAnimation reveal type="fadeIn" className="slow">
              <div className="padding-lg">
                <MDBContainer className="pt-lg-5 pb-lg-5">
                  <MDBRow className="mt-5 mb-5">
                    <MDBCol md="5" className="mx-auto text-center pb-4">
                      <h2 className="display-h2 font-weight-md text-lg-right text-left mb-3 tblue-text font-nanum-eb">
                        For growth-based financial futures.
                      </h2>
                      <h6 className="h5-responsive text-lg-right text-left font-space-xs mt-lg-5 font-nanum-eb sf-pro-tight">
                        We have the courage to break the norms, and think
                        outside the box to provide the best solutions.
                      </h6>
                    </MDBCol>
                    <MDBCol
                      md="7"
                      className="mx-auto mt-lg-5 pl-lg-5  text-lg-right text-left"
                    >
                      <div className="mt-lg-5">
                        <h6 className="h5-responsive text-left mb-2 font-space-xs">
                          We provide end-to-end capability from accounting
                          solutions and advice, to creating and expanding
                          capital, providing robust business advice and
                          protecting your wealth through our trust division.
                        </h6>
                        <MDBBtn
                          outline
                          color="primary"
                          className="mt-5 mr-lg-4"
                          onClick={() =>
                            this.props.history.push("/financial-experts")
                          }
                        >
                          Meet our team
                        </MDBBtn>
                      </div>
                    </MDBCol>
                  </MDBRow>
                </MDBContainer>
              </div>
            </MDBAnimation>
            <MDBAnimation reveal type="fadeIn" className="slow">
              <div
                className="h5-responsive font-nanum-eb pt-5 pb-5 "
                style={{ backgroundColor: "whitesmoke" }}
              >
                <TitleSlider />
              </div>
            </MDBAnimation>

            <MDBAnimation reveal type="fadeIn" className="slow">
              <div
                style={{
                  padding: "0",
                  margin: "0",
                  borderTop: "solid whitesmoke 4px",
                  backgroundColor: "whitesmoke",
                }}
              >
                <MDBRow style={{ padding: "0", margin: "0" }}>
                  <MDBCol
                    sm="12"
                    md="12"
                    lg="4"
                    className="no-padding-margin container"
                  >
                    <MDBView>
                      <img
                        src={require("../../assets/img/close.png")}
                        className="img-fluid img-aboutus"
                        alt="tailored approach"
                      />
                      <div className="center-title">
                        <h3 className="h5-responsive tblue-text font-nanum-eb font-weight-md">
                          Tailored approach
                        </h3>
                        <h6 className="h5-responsive font-nanum-b">
                          Provide a personalised and individual approach.
                        </h6>
                      </div>
                    </MDBView>
                  </MDBCol>

                  <MDBCol
                    sm="12"
                    md="12"
                    lg="4"
                    className="no-padding-margin container"
                  >
                    <MDBView>
                      <img
                        src={require("../../assets/img/problem.png")}
                        className="img-fluid img-aboutus"
                        alt="problem solvers"
                      />
                      <div className="center-title">
                        <h3 className="h5-responsive tblue-text font-nanum-eb font-weight-md">
                          Problem solvers
                        </h3>
                        <h6 className="h5-responsive font-nanum-b">
                          Solve problems, provide quality solutions, and seek
                          new opportunities to improve our clients’ business
                          performance.
                        </h6>
                      </div>
                    </MDBView>
                  </MDBCol>

                  <MDBCol
                    sm="12"
                    md="12"
                    lg="4"
                    className="no-padding-margin container"
                  >
                    <MDBView>
                      <img
                        src={require("../../assets/img/people.png")}
                        className="img-fluid img-aboutus"
                        alt="proven in our field"
                      />
                      <div className="center-title">
                        <h3 className="h5-responsive tblue-text font-nanum-eb font-weight-md">
                          Proven in our field
                        </h3>
                        <h6 className="h5-responsive font-nanum-b">
                          Growing financial futures for our clients.
                        </h6>
                      </div>
                    </MDBView>
                  </MDBCol>
                </MDBRow>
              </div>
            </MDBAnimation>

            <div className="pt-3 pb-5 flock-grey padding-lg">
              <MDBContainer className="pt-5 pb-2">
                <MDBAnimation reveal type="fadeInUp" className="slow">
                  <h2 className="display-h2 tblue-text font-nanum-eb text-left mb-4">
                    Our values.
                  </h2>
                </MDBAnimation>
                <MDBRow className="mx-auto mt-5 mb-5">
                  <MDBCol md="7" className="mx-auto mt-lg-5 font-nanum-eb">
                    <h5 className="h3-responsive font-weight-md text-left mb-1 ">
                      Make it happen <span className="accent-text">|</span> DO
                      IT
                    </h5>
                    <h6 className="h4-responsive text-left mb-5 font-nanum-eb secondary-text">
                      Take massive action
                    </h6>
                    <h5 className="h3-responsive font-weight-md text-left mb-1 ">
                      Do our best <span className="accent-text">|</span>{" "}
                      EXCELLENCE
                    </h5>
                    <h6 className="h4-responsive text-left mb-5 font-nanum-eb secondary-text">
                      Continuous improvement
                    </h6>
                    <h5 className="h3-responsive font-weight-md text-left mb-1 ">
                      Family focus <span className="accent-text">|</span>{" "}
                      BALANCE
                    </h5>
                    <h6 className="h4-responsive text-left mb-5 font-nanum-eb secondary-text">
                      Better together
                    </h6>
                    <h5 className="h3-responsive font-weight-md text-left mb-1 ">
                      Simply the best people{" "}
                      <span className="accent-text">|</span> ATTITUDE
                    </h5>
                    <h6 className="h4-responsive text-left mb-5 font-nanum-eb secondary-text">
                      Achieving great things
                    </h6>
                    <h5 className="h3-responsive font-weight-md text-left mb-1 ">
                      Unlocking potential <span className="accent-text">|</span>{" "}
                      POSSIBILITIES
                    </h5>
                    <h6 className="h4-responsive text-left mb-5 font-nanum-eb secondary-text">
                      Beyond the numbers
                    </h6>
                  </MDBCol>
                  <MDBCol md="5" className="mx-auto">
                    <div className="center">
                      <img
                        src={"/loader/new/swirl.svg"}
                        alt="neovia swirl"
                        className="img-fluid mb-5 swirl-small"
                      />
                    </div>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
              <MDBContainer className="pt-5 pb-2">
                <MDBRow className="mx-auto mt-5 mb-5">
                  <MDBCol md="5" className="mx-auto hide-767px">
                    <div className="center">
                      <img
                        src={"/loader/new/swirl.svg"}
                        alt="neovia swirl"
                        className="img-fluid mb-5 swirl-small"
                      />
                    </div>
                  </MDBCol>
                  <MDBCol md="7" className="mx-auto mt-lg-5 font-nanum-eb">
                    <MDBAnimation reveal type="fadeInUp" className="slow">
                      <h2
                        className="display-h2 tblue-text font-nanum-eb text-left"
                        style={{ marginBottom: "5rem" }}
                      >
                        What we deliver.
                      </h2>
                    </MDBAnimation>
                    <h5 className="h5-responsive font-weight-md text-left mb-1 ">
                      End-to-end financial solutions
                    </h5>
                    <h6 className="h5-responsive text-left mb-5 font-nanum-eb">
                      Efficient, fully integrated financial solutions with
                      specialised divisions.
                    </h6>
                    <h5 className="h5-responsive font-weight-md text-left mb-1 ">
                      Problem solving capability
                    </h5>
                    <h6 className="h5-responsive text-left mb-5 font-nanum-eb">
                      We are problem solvers with proven capability.
                    </h6>
                    <h5 className="h5-responsive font-weight-md text-left mb-1 ">
                      Client-first approach
                    </h5>
                    <h6 className="h5-responsive text-left mb-5 font-nanum-eb">
                      Our commitment is helping our clients enhance business
                      performance, protect and maximise their wealth.
                    </h6>
                    <h5 className="h5-responsive font-weight-md text-left mb-1 ">
                      Consultancy
                    </h5>
                    <h6 className="h5-responsive text-left mb-5 font-nanum-eb">
                      Our dedicated team can be consultants in your business
                      when you need us.
                    </h6>
                    <h5 className="h5-responsive font-weight-md text-left mb-1 ">
                      Flexible
                    </h5>
                    <h6 className="h5-responsive text-left mb-5 font-nanum-eb">
                      We operate in a "virtual" capacity as and when you
                      require.
                    </h6>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </div>

            <MDBAnimation reveal type="fadeIn" className="slow">
              <div className="home-banner page-header header-filter clear-filter blue-filter white-text">
                <MDBMask overlay="black-strong" className="w-100 pt-5 pb-5">
                  <MDBCol md="10" lg="9" xl="8" className="mx-auto">
                    <MDBContainer className="pt-5 pb-5 text-left">
                      <div className="h3-responsive mb-4 mt-2 font-nanum-eb">
                        We work closely with our clients to build enduring
                        relationships that facilitate their success and ongoing
                        prosperity.
                      </div>
                      <MDBBtn
                        outline
                        color="white"
                        onClick={() => this.props.history.push("/contact-us")}
                      >
                        Book an appointment
                      </MDBBtn>
                    </MDBContainer>
                  </MDBCol>
                </MDBMask>
              </div>
            </MDBAnimation>

            <MDBAnimation reveal type="fadeIn" className="slow">
              <div
                className="pt-5 pb-5"
                style={{ backgroundColor: "whitesmoke" }}
              >
                <div className="padding-lg">
                  <MDBContainer id="learnmore">
                    <MDBRow className="mt-lg-5 mb-lg-5">
                      <MDBCol md="5" lg="5" xl="5">
                        <h2 className="h2-responsive font-weight-md text-left tblue-text font-nanum-eb">
                          Our purpose
                        </h2>
                      </MDBCol>
                      <MDBCol md="6" lg="6" xl="7" className="mx-auto pb-5">
                        <div className="center">
                          <h6 className="h5-responsive  font-nanum-b text-left tblue-text  font-weight-md mb-2">
                            Pathways to freedom | financial | time | mind.
                            {/*} Our purpose is to provide innovative solutions and
                            identify new opportunities for our clients, and
                            effectively communicating these innovative solutions
                            so that our clients get the most from their
              experience with us.*/}
                          </h6>
                        </div>
                      </MDBCol>
                      <MDBCol md="5" lg="5" xl="5">
                        <h2 className="h2-responsive font-weight-md text-left tblue-text font-nanum-eb">
                          Our mission
                        </h2>
                      </MDBCol>
                      <MDBCol md="6" lg="6" xl="7" className="mx-auto">
                        <div className="center">
                          <h6 className="h5-responsive  font-nanum-b text-left mb-2">
                            Being the most efficient problem solvers of
                            integrated financial services in New Zealand with a
                            core focus as Chartered Accountants. Providing start
                            to finish capabilities from accounting solutions and
                            advice, to creating and expanding capital, providing
                            robust business advice and protecting your wealth
                            through our trust division.{" "}
                          </h6>
                        </div>
                      </MDBCol>
                    </MDBRow>
                  </MDBContainer>
                </div>
              </div>
            </MDBAnimation>
          </div>

          <MDBAnimation reveal type="fadeIn" className="slow padding-lg">
            <div className="text-left font-nanum-eb pt-4 pb-4">
              <MDBContainer className="pt-5 pb-5">
                <h2 className="h1-responsive primary-text font-weight-md text-left mb-lg-4 font-space-md">
                  Specialties
                </h2>
                <MDBRow>
                  <MDBCol md="6" className="mx-auto pt-lg-5 pt-2">
                    <h5
                      className="text-small mb-5"
                      style={{ lineHeight: "45px" }}
                    >
                      <p
                        className="icon-hover mb-0 pb-0"
                        onClick={() =>
                          this.props.history.push("/chartered-accountancy")
                        }
                      >
                        Chartered Accountancy
                      </p>
                      <p
                        className="icon-hover mb-0 pb-0"
                        onClick={() =>
                          this.props.history.push("/trust-advisory")
                        }
                      >
                        Trust Advisory
                      </p>
                      <p
                        className="icon-hover mb-0 pb-0"
                        onClick={() =>
                          this.props.history.push("/strategic-advisory")
                        }
                      >
                        Strategic Advisory
                      </p>
                      {/* <p className='icon-hover mb-0 pb-0' onClick={() => this.props.history.push('/crowdfunding-advisory')}>
                      Crowdfunding Advisory
                    </p> */}
                      <p
                        className="icon-hover mb-0 pb-0"
                        onClick={() =>
                          this.props.history.push("/australian-rental-property")
                        }
                      >
                        Australian Rental Property
                      </p>
                      <p
                        className="icon-hover mb-0 pb-0"
                        onClick={() =>
                          this.props.history.push(
                            "/virtual-chief-financial-officer"
                          )
                        }
                      >
                        Virtual CFO
                      </p>
                    </h5>
                  </MDBCol>
                  <MDBCol md="6" className="mx-auto">
                    <MDBRow className="mb-5 mt-lg-5 text-center">
                      <MDBCol md="6" className="mx-auto mb-4 mt-4">
                        <img
                          src={require("../../assets/img/logos/CAANZ_logo.jpg")}
                          alt="applications icon"
                          style={{ height: "109px" }}
                          className="img-fluid"
                        />
                        <br />
                      </MDBCol>
                      <MDBCol md="6" className="mx-auto mb-4 mt-4">
                        <img
                          src={require("../../assets/img/logos/xero.jpg")}
                          alt="applications icon"
                          style={{ height: "95px" }}
                          className="img-fluid"
                        />
                        <br />
                      </MDBCol>
                      <MDBCol md="6" className="mx-auto mb-4 mt-4">
                        <img
                          src={require("../../assets/img/logos/spotlight.png")}
                          alt="applications icon"
                          style={{ height: "112px" }}
                          className="img-fluid"
                        />
                        <br />
                      </MDBCol>
                      <MDBCol md="6" className="mx-auto mb-4 mt-4">
                        <img
                          src={require("../../assets/img/logos/MYOB-logo.jpg")}
                          alt="applications icon"
                          style={{ height: "88px" }}
                          className="img-fluid"
                        />
                        <br />
                      </MDBCol>
                    </MDBRow>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </div>
          </MDBAnimation>
        </div>
      </HelmetProvider>
    );
  }
}

export default withRouter(AboutUs);

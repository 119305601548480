import React from 'react'
import { DateHelper } from '../../helpers/DateHelper'
import ImageHelper, {
  PREFERRED_FORMAT_THUMB,
  PREFERRED_FORMAT_SMALL,
  PREFERRED_FORMAT_MEDIUM,
  PREFERRED_FORMAT_LARGE
} from '../../helpers/ImageHelper'
import { MDBCol, MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBCardImage, MDBIcon, MDBRow, MDBBtn } from 'mdbreact'
import { withRouter } from 'react-router'
import './style.css'

function BlogCard(props) {
  const urlRef = '/news-latest' + '?id=' + props.id
  const dateHelper = new DateHelper()

  const imageHelper = new ImageHelper()
  const coverImg = imageHelper.getImage(props.coverPhoto, PREFERRED_FORMAT_MEDIUM)

  return (
    <div>
      <a href={urlRef}>
        <MDBCard>
          <MDBRow>
            <MDBCol md='4'>
              <div
                className='img-fluid'
                style={{
                  backgroundImage: `url("${coverImg}")`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  minHeight: '300px',
                  borderRadius: '5px'
                }}
              >
                {' '}
              </div>
            </MDBCol>
            <MDBCol md='8'>
              <div>
                <MDBRow>
                  <MDBCol md='6'>
                    {props.publishDate ? (
                      <div className='text-left  h6-responsive pt-lg-0 pt-4 mb-1'>
                        <b className='text-uppercase'>{dateHelper.getDateStr(props.publishDate)}</b>
                        <br />
                      </div>
                    ) : null}
                  </MDBCol>
                  <MDBCol md='6' className='text-lg-right text-left pt-lg-0 pt-4 mb-1'>
                    {props.blog_category ? (
                      <span className='highlight-bg white-text text-uppercase'>{props.blog_category.Name}</span>
                    ) : null}
                  </MDBCol>
                </MDBRow>
                <div className='tblue-text font-nanum-eb h2-responsive text-left mb-2 mt-3 pt-lg-0 pt-3'>
                  {props.title ? <>{props.title}</> : null}
                </div>
                <MDBCardText className='text-left'>
                  <hr />
                  <div className='p  h6-responsive' style={{ height: '67px', overflow: 'hidden' }}>
                    {props.subtitle ? <>{props.subtitle}</> : null}
                  </div>
                </MDBCardText>
                <div className='text-left'>
                  <MDBBtn outline color='primary' className='mt-4' onClick={() => props.history.push(urlRef)}>
                    Read More <MDBIcon fa icon='long-arrow-alt-right' className='ml-2' />
                  </MDBBtn>
                </div>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBCard>
      </a>
    </div>
  )
}

export default withRouter(BlogCard)

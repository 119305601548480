import React from "react";
import { AiOutlineReload } from "react-icons/ai";

export const Reload = (props) => {
  const react = React;
  const { message, callback } = props;

  const clickHandler = () => {
    if (callback) callback();
  };

  return (
    <div
      style={{
        width: "100%",
        minHeight: "2rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="on-hover" onClick={clickHandler}>
        {message && (
          <div
            style={{
              maxWidth: "400px",
              padding: "0px 20px",
            }}
          >
            {message}
            <br />
            <br />
          </div>
        )}
        <AiOutlineReload
          style={{
            fontSize: "2rem",
            verticalAlign: "middle",
          }}
        />
        <div style={{ paddingTop: "10px" }}>Retry</div>
      </div>
    </div>
  );
};

/* eslint-disable react-hooks/exhaustive-deps */
import { MDBBtn, MDBIcon, MDBTooltip } from "mdbreact";
import React, { useEffect, useMemo, useState } from "react";
import { STRAPI_URL } from "../../../data/DataRequestor";
import { useChecklistStore } from "../../../stores/checklist";
import { useFormDataStore } from "../../../stores/form-data";
import { ChecklistBuilder } from "../ChecklistBuilder";
import { COMPONENT_TYPE_ENUM } from "../ChecklistHelpers";

const range = (start, stop, step) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

const getFormSection = async (id) => {
  const res = await fetch(STRAPI_URL + "/form-sections/" + id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (res.status === 200) {
    return await res.json();
  }

  return [];
};

export const RepeatableSectionInput = (props) => {
  const {
    question,
    questionKey,
    questionHint,
    min,
    max,
    sectionPrefix,
    changeHandler,
    disabled,
    sectionGroup,
  } = props;
  const [repeatableSections, setRepeatableSections] = useState([]);
  const react = React;
  const currentForm = useChecklistStore((state) => state.currentForm);
  const data = useFormDataStore((state) => state.data);

  const options = range(min, max, 1);
  const formData = data.find((d) => d.id === currentForm.id);
  const defaultValue =
    (formData && formData.form && formData.form[questionKey]) || "";

  useEffect(() => {
    let active = true;

    const loadSection = async () => {
      const s = await getFormSection(sectionGroup.id);

      if (s) {
        if (active) {
          setRepeatableSections(s.sections);
        }
      }
    };

    if (sectionGroup) {
      loadSection();
    }

    return () => {
      active = false;
    };
  }, [sectionGroup]);

  const buildSections = useMemo(() => {
    if (defaultValue === "") return [];
    if (!repeatableSections || repeatableSections.length === 0) return [];

    const array = Array.from({ length: defaultValue }, (_, i) => i + 1);
    const s = [];

    for (let i = 0; i < array.length; i++) {
      const paragraph = {
        __component: COMPONENT_TYPE_ENUM.PARAGRAPH,
        text: `<p><span style="color: rgb(218, 115, 37);">${sectionPrefix} ${array[i]}</span></p>`,
      };
      const sections = JSON.parse(JSON.stringify(repeatableSections));

      for (let r = 0; r < sections.length; r++) {
        if (sections[r].questionKey) {
          sections[r].questionKey += `-${array[i]}`;
        }
      }

      sections.unshift(paragraph);
      s.push(sections);
    }

    return s;
  }, [repeatableSections, defaultValue]);

  return (
    <>
      <div className="form-group text-left">
        <label style={{ whiteSpace: "pre-line" }} htmlFor={questionKey}>
          {question}
          <span style={{ color: "red" }}>*</span>
          {questionHint ? (
            <MDBTooltip placement="bottom">
              <MDBBtn className="rm-button-style">
                <MDBIcon far icon="question-circle" />
              </MDBBtn>
              <div>{questionHint}</div>
            </MDBTooltip>
          ) : (
            ""
          )}
        </label>
        <select
          required
          value={defaultValue}
          style={{ color: "#666666" }}
          className="form-control"
          id={questionKey}
          onChange={changeHandler}
        >
          <option selected disabled hidden value="">
            -- Select --
          </option>
          {options.map((v) => (
            <option disabled={disabled} key={v} value={v}>
              {v}
            </option>
          ))}
        </select>
      </div>
      {buildSections.map((s, i) => (
        <ChecklistBuilder
          noTitle
          key={`${questionKey}-${sectionPrefix}-${i}`}
          section={s}
          changeHandler={changeHandler}
          loading={disabled}
        />
      ))}
    </>
  );
};

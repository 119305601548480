import { MDBBtn, MDBIcon, MDBTooltip } from "mdbreact";
import React from "react";
import { useChecklistStore } from "../../../stores/checklist";
import { useFormDataStore } from "../../../stores/form-data";

export const SelectInput = (props) => {
  const react = React;
  const currentForm = useChecklistStore((state) => state.currentForm);
  const data = useFormDataStore((state) => state.data);
  const {
    questionKey,
    question,
    placeholder,
    required,
    questionHint,
    answer,
    options,
    changeHandler,
    disabled,
  } = props;

  const formData = data.find((d) => d.id === currentForm.id);
  const defaultValue =
    (formData && formData.form && formData.form[questionKey]) || "";

  return (
    <div className="form-group text-left">
      <label style={{ whiteSpace: "pre-line" }} htmlFor={questionKey}>
        {question}
        {required ? <span style={{ color: "red" }}>*</span> : ""}
        {questionHint ? (
          <MDBTooltip placement="bottom">
            <MDBBtn className="rm-button-style">
              <MDBIcon far icon="question-circle" />
            </MDBBtn>
            <div>{questionHint}</div>
          </MDBTooltip>
        ) : (
          ""
        )}
      </label>
      <select
        value={defaultValue || ""}
        style={{ color: "#666666" }}
        className="form-control"
        id={questionKey}
        required={required}
        onChange={changeHandler}
      >
        <option disabled hidden value="">
          {placeholder ? placeholder : "-- Select --"}
        </option>
        {options.map(({ value }) => (
          <option disabled={disabled} key={value} value={value}>
            {value}
          </option>
        ))}
      </select>
    </div>
  );
};

import React from 'react'
import { MDBBtn, MDBModal, MDBModalBody, MDBContainer } from 'mdbreact'

class PrivacyModal extends React.Component {
  state = {
    modal10: false,
    modal11: false
  }

  toggle = (nr) => () => {
    let modalNumber = 'modal' + nr
    this.setState({
      [modalNumber]: !this.state[modalNumber]
    })
  }

  render() {
    return (
      <>
        <div className='text-uppercase font-text' onClick={this.toggle(10)}>
          Privacy Policy
        </div>
        <MDBModal size='fluid' fullHeight position='bottom' isOpen={this.state.modal10} toggle={this.toggle(10)} className='white-text'>
          <MDBModalBody className='text-left background-dark'>
            <MDBContainer className='divider-margin'>
              <h1 className='h1-responsive mt-4 mb-4'>Our Privacy Policy</h1>
              <p className='h5-responsive pt-serif mb-5'>
                We collect personal information from you, including your Name, Contact Details and Payment Information. We collect your
                personal information in order to communicate with you and to accept payments from you for your account.
                <br />
                <br />
                We keep your information safe by storing it securely in our servers.
                <br />
                <br />
                You have the right to ask for a copy of any personal information we hold about you, and to ask for it to be corrected if you
                think it is wrong. If you'd like to ask for a copy of your information, or to have it corrected, please contact Leanna
                Singleton at{' '}
                <a className='white-text' style={{ textDecoration: 'underline' }} href='mailto:leanna@neovia.co.nz'>
                  leanna@neovia.co.nz
                </a>
                , our Data Officer.
              </p>
              <MDBBtn color='secondary' onClick={this.toggle(10)} className='mb-5'>
                Close
              </MDBBtn>
            </MDBContainer>
          </MDBModalBody>
        </MDBModal>
      </>
    )
  }
}

export default PrivacyModal

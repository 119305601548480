import {
  MDBAnimation,
  MDBBtn,
  MDBCard,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBRow,
} from "mdbreact";
import React, { useCallback, useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useHistory, withRouter } from "react-router-dom";
import { STRAPI_URL } from "../../data/DataRequestor";
import {
  getQParamByName,
  PAYMENT_FORM_STORAGE_KEY,
} from "../../utils/constants";
import ScrollToTop from "../Nav/ScrollToTop";
import "./styles.css";

const PaymentFailed = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [resData, setResData] = useState({});
  const [message, setMessage] = useState("Payment failed, please try again.");

  const verify = useCallback(async (result) => {
    let verifyRes = await fetch(
      `${STRAPI_URL}/payments/verify?result=${result}`
    );
    if (verifyRes.status === 200) {
      let jsonRes = await verifyRes.json();
      if (jsonRes.data) {
        setResData(jsonRes.data);
      }
      if (jsonRes.message) {
        setMessage(jsonRes.message);
      }
      if (jsonRes.retryData) {
        handleDataResponse(jsonRes.retryData);
      }
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    let result = getQParamByName("result");
    let uid = getQParamByName("userid");
    if (result && uid) {
      verify(result);
    } else {
      setLoading(false);
    }
  }, [verify]);

  const handleDataResponse = (data) => {
    window.sessionStorage.setItem(
      PAYMENT_FORM_STORAGE_KEY,
      JSON.stringify(data)
    );
  };

  const getResData = () => {
    let dataArr = [];
    for (var key in resData) {
      if (resData.hasOwnProperty(key)) {
        if (key === "reference") {
          dataArr.push(
            <div key={key}>
              <div className="payment-result-key-col">Reference</div>
              <div className="payment-result-val-col">{resData[key]}</div>
            </div>
          );
        } else if (key === "amount") {
          dataArr.push(
            <div key={key}>
              <div className="payment-result-key-col">Amount</div>
              <div className="payment-result-val-col">${resData[key]}</div>
            </div>
          );
        } else if (key === "card") {
          dataArr.push(
            <div key={key}>
              <div className="payment-result-key-col">Card Number</div>
              <div className="payment-result-val-col">{resData[key]}</div>
            </div>
          );
        } else if (key === "response") {
          dataArr.push(
            <div key={key}>
              <div className="payment-result-key-col">Result</div>
              <div className="payment-result-val-col">{resData[key]}</div>
            </div>
          );
        } else if (key === "invoiceNo") {
          dataArr.push(
            <div key={key}>
              <div className="payment-result-key-col">Invoice Number</div>
              <div className="payment-result-val-col">{resData[key]}</div>
            </div>
          );
        }
      }
    }
    return dataArr;
  };

  return (
    <HelmetProvider>
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Payment Options | Neovia Advisory</title>
          <link
            rel="canonical"
            href="https://www.neovia.co.nz/chartered-accountancy"
          />
        </Helmet>
        <ScrollToTop />
        <div className="payment-header d-flex justify-content-center align-items-center page-header header-filter clear-filter bg-gradient-overlay">
          <div className="header-text">
            <h2 className="h4-responsive text-left center font-weight-md mb-4 pl-lg-2 secondary-text font-nanum-b sf-compact">
              Payment Failed
            </h2>
            <h1 className="h1-responsive text-left center font-weight-md mb-4 pl-lg-2 font-nanum-b">
              We focus on creative solutions to achieve your goals.
            </h1>
          </div>
        </div>
        <div id="about">
          <div className="padding-lg">
            <MDBAnimation reveal type="fadeIn" className="slow">
              <MDBContainer className="pt-5 pb-5">
                <MDBRow>
                  <MDBCol md="12" className="mx-auto text-center pb-4">
                    <MDBCard>
                      {loading ? (
                        <div
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <div
                            className="spinner-border text-primary"
                            role="status"
                            style={{
                              height: "45px",
                              width: "45px",
                              verticalAlign: "middle",
                              border: "2px solid",
                              borderRightColor: "transparent",
                            }}
                          >
                            <span className="sr-only"></span>
                          </div>
                        </div>
                      ) : (
                        <>
                          <h6 className="h5-responsive text-primary text-center font-space-xs mt-lg-5 font-nanum-eb">
                            {message}
                          </h6>
                          <div className="payment-response-container">
                            {getResData()}
                          </div>
                          <div>
                            <MDBBtn
                              color="primary"
                              onClick={() => history.push("/payment-options")}
                            >
                              <MDBIcon icon="chevron-left" className="mr-2" />
                              Back to payment options
                            </MDBBtn>
                          </div>
                        </>
                      )}
                    </MDBCard>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </MDBAnimation>
          </div>
        </div>
      </div>
    </HelmetProvider>
  );
};

export default withRouter(PaymentFailed);

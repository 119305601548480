import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { withRouter } from "react-router-dom";
import { logError } from "../../data/ErrorLogger";
import { useChecklistStore } from "../../stores/checklist";
import { useFormDataStore } from "../../stores/form-data";
import { Reload } from "../Loader/Reload";
import Checklist from "./Checklist";
import { ERROR_CODE_INITIALISE_FORM_COMPONENT_FAILED } from "./ChecklistErrorCode";

export const ChecklistContainer = () => {
  const react = React;

  const errorFallback = () => {
    return (
      <div className="message-layout">
        <p style={{ fontSize: "1.6rem", padding: "0 1.6rem" }} className="mt-4">
          Oops something went wrong.
          <div style={{ fontSize: "1.05rem", padding: "0 1.6rem" }}>
            <br />
            {ERROR_CODE_INITIALISE_FORM_COMPONENT_FAILED}
            <br />
          </div>
          <br />
          <div style={{ fontSize: "1.05rem", padding: "0 1.6rem" }}>
            <Reload
              callback={() => {
                try {
                  const checklistState = useChecklistStore.getState();
                  if (checklistState && checklistState.clearForm) {
                    checklistState.clearForm();
                  }

                  const formDataState = useFormDataStore.getState();
                  if (formDataState && formDataState.clearData) {
                    formDataState.clearData();
                  }

                  window.location.reload();
                } catch (e) {}
              }}
            />
          </div>
        </p>
      </div>
    );
  };

  return (
    <ErrorBoundary
      FallbackComponent={errorFallback}
      onError={(error, info) => {
        let stackInfo = "Not provided";
        try {
          stackInfo = info?.componentStack;
        } catch (e) {}
        // log error
        logError(stackInfo, error);
      }}
      onReset={() => {
        // reset the state of your app here
      }}
    >
      <Checklist />
    </ErrorBoundary>
  );
};

export default withRouter(ChecklistContainer);

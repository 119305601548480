export const GET = "GET";
export const POST = "POST";
export const PUT = "PUT";
export const DELETE = "DELETE";

export const STRAPI_URL = "https://admin.neovia.co.nz";
// export const STRAPI_URL = 'http://localhost:8086'
export const ENTITY_BLOG_POSTS = "/blog-posts";
export const ENTITY_BLOG_POSTS_COUNT = "/blog-posts/count";
export const ENTITY_OURTEAM = "/ourteam";
export const ENTITY_TEAM_MEMBERS = "/team-members";

export default class DataRequestor {
  getDateStr() {
    let date = new Date();
    let day = this.padDate(date.getDate().toString(), 2);
    let month = this.padDate((date.getMonth() + 1).toString(), 2);
    let year = date.getFullYear();

    return year + "-" + month + "-" + day;
  }

  padDate(str, length) {
    while (str.length < length) {
      str = "0" + str;
    }
    return str;
  }

  async getHeaders(meth = GET, data = null) {
    let opts = {
      method: meth,
      headers: {},
    };

    if (data !== null && (meth === PUT || meth === POST)) {
      opts.body = JSON.stringify(data);
    }

    return opts;
  }

  async fetch(callbackObj, url, method, data = null, id) {
    let headers = await this.getHeaders(method, data);

    fetch(url, headers)
      .then((response) => response.json())
      .then((resData) => {
        callbackObj.fetchSuccess(resData, id);
      })
      .catch((error) => {
        callbackObj.fetchFailed(error);
      });
  }

  async fetchWithCount(callbackObj, url, countUrl, method, data = null) {
    let headers = await this.getHeaders(method, data);

    fetch(url, headers)
      .then((response) => response.json())
      .then((resData) => {
        callbackObj.fetchSuccess(resData);
      })
      .catch((error) => {
        callbackObj.fetchFailed(error);
      });

    if (callbackObj.fetchCountSuccess && callbackObj.fetchCountFailed) {
      fetch(countUrl)
        .then((response) => response.json())
        .then((resData) => {
          callbackObj.fetchCountSuccess(resData);
        })
        .catch((error) => {
          callbackObj.fetchCountFailed(error);
        });
    }
  }
}

import axios from "axios";
import { STRAPI_URL } from "./DataRequestor";

export const logError = async (message = "None provided", e = {}) => {
  try {
    const st = JSON.stringify(e, Object.getOwnPropertyNames(e));
    const res = await axios.post(`${STRAPI_URL}/error-logs`, {
      error_message: message,
      stack_trace: st,
    });
  } catch (e) {
    console.log(e);
  }
};

/* eslint-disable react-hooks/exhaustive-deps */
import { MDBBtn, MDBIcon, MDBTooltip } from "mdbreact";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useChecklistStore } from "../../../stores/checklist";
import { useFormDataStore } from "../../../stores/form-data";

export const CheckboxInput = (props) => {
  const data = useFormDataStore((state) => state.data);
  const {
    questionKey,
    question,
    required,
    questionHint,
    answer,
    defaultValue,
    options,
    extraDetails,
    changeHandler,
    disabled,
  } = props;
  const test = React;
  const [checkedState, setCheckedState] = useState();
  const currentForm = useChecklistStore((state) => state.currentForm);
  const updateValue = useFormDataStore((state) => state.updateValue);

  useEffect(() => {
    const formData = data.find((d) => d.id === currentForm.id);
    if (
      !formData ||
      !formData.form ||
      !formData.form[questionKey] ||
      !formData.form[questionKey].length === 0
    ) {
      const state = new Array(options.filter(Boolean).length).fill(false);
      setCheckedState(state);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    let active = true;

    if (active) {
      const state = new Array(options.filter(Boolean).length).fill(false);
      const formData = data.find((d) => d.id === currentForm.id);
      if (formData && formData.form && formData.form[questionKey]) {
        const values = formData.form[questionKey].split(",");
        if (values.length > 0) {
          for (let i = 0; i < values.length; i++) {
            const index = options.map((o) => o.value).indexOf(values[i]);
            state[index] = true;
          }
        }
      }
      setCheckedState(state);
    }

    return () => {
      active = false;
    };
  }, [options, questionKey]);

  const onCheckedHandler = (position) => {
    const valuesArray = [];
    const updatedCheckedState = checkedState?.map((item, index) =>
      index === position ? !item : item
    );
    for (let i = 0; i < options.length; i++) {
      if (updatedCheckedState[i]) {
        valuesArray.push(options[i].value);
      }
    }

    updateValue(currentForm.id, questionKey, valuesArray.toString());
    setCheckedState(updatedCheckedState);
  };

  const onOtherCheckedInputChange = (event, position) => {
    var value = options[position];
    const valuesArray = [];
    for (let i = 0; i < options.length; i++) {
      if (i === position) {
        valuesArray.push(`${options[i].value} - ${event.target.value}`);
      } else if (checkedState[i]) {
        valuesArray.push(options[i].value);
      }
    }
    updateValue(currentForm.id, questionKey, valuesArray.toString());
  };

  return (
    <div className="form-group text-left">
      <fieldset aria-required={required} id={questionKey}>
        <legend style={{ fontSize: 16, whiteSpace: "pre-line" }}>
          {question}
          {required ? <span style={{ color: "red" }}>*</span> : ""}
          {questionHint ? (
            <MDBTooltip placement="bottom">
              <MDBBtn className="rm-button-style">
                <MDBIcon far icon="question-circle" />
              </MDBBtn>
              <div>{questionHint}</div>
            </MDBTooltip>
          ) : (
            ""
          )}
        </legend>
        <div style={{ color: "#666666" }}>
          {checkedState &&
            options.map(({ value }, i) => {
              const key = uuidv4();
              return (
                <div key={key}>
                  <label>
                    <input
                      type="checkbox"
                      onChange={() => onCheckedHandler(i)}
                      disabled={disabled}
                      id={questionKey}
                      name={questionKey}
                      checked={checkedState[i]}
                    />{" "}
                    {value}
                  </label>
                  {value && value.toLowerCase() === "other" && (
                    <input
                      className="form-control"
                      onChange={(e) => onOtherCheckedInputChange(e, i)}
                      disabled={!checkedState[i]}
                      id={`${questionKey}-other`}
                      name={`${questionKey}-other`}
                      required={checkedState[i]}
                    />
                  )}
                </div>
              );
            })}
        </div>
        {/* {extraDetails.length > 0 && <ChecklistBuilder section={extraDetails[0]} changeHandler={changeHandler} loading={disabled} />} */}
      </fieldset>
    </div>
  );
};

import moment from "moment";
import striptags from "striptags";

import { COMPONENT_TYPE_ENUM } from "../ChecklistHelpers";

export const generatePDF = (checklist, isOffline = false) => {
  for (let i = 0; i < checklist.sections.length; i++) {
    const item = checklist.sections[i];
    if (item.title) {
      item.title = item.title.replace("&nbsp;", " ");
    }
    if (item.text) {
      item.text = item.text.replace("&nbsp;", " ");
    }
    if (item.question) {
      item.question = item.question.replace("&nbsp;", " ");
    }
  }

  const now = moment();
  if (!checklist) return null;

  return {
    pageSize: "A4",
    pageMargins: [50, 115, 50, 50],
    info: {
      title: `neovia_${checklist.name}_${now.format("ddd-DD-MMM-yyyy")}`,
      author: "Neovia",
    },
    background: [
      {
        stack: [
          {
            image: "neovia",
            width: 130,
            absolutePosition: { x: 36, y: 39 },
          },
          {
            image: "neoviaSwirl",
            width: 150,
            absolutePosition: { x: 0, y: 688 },
          },
          {
            table: {
              body: [
                [
                  {
                    text: `${checklist.name}`,
                    style: "title",
                    marginLeft: 12,
                    borderColor: ["#EB9258", "#fff", "#fff", "#fff"],
                  },
                ],
                [
                  {
                    text: `${now.format("ddd DD MMM yyyy")}`,
                    style: "date",
                    marginLeft: 15,
                    borderColor: ["#EB9258", "#fff", "#fff", "#fff"],
                  },
                ],
              ],
            },
            absolutePosition: { x: 190, y: 40 },
          },
        ],
      },
    ],
    content: [
      {
        columns: [
          {
            width: 150,
            text: "",
          },
          {
            stack: isOffline
              ? [...generateOfflinePdfContent(checklist)]
              : [...generatePdfContent(checklist)],
          },
        ],
      },
    ],
    footer: function (currentPage, pageCount) {
      return [
        {
          text: `${currentPage.toString()} of ${pageCount}`,
          absolutePosition: { x: 550, y: 25 },
          fontSize: 8,
        },
      ];
    },
    styles: {
      title: {
        fontSize: 16,
        bold: true,
        characterSpacing: -0.4,
        font: "AmikoBold",
      },
      date: {
        fontSize: 9,
      },
      sectionTitle: {
        fontSize: 12,
        marginTop: 5,
        marginBottom: 15,
        font: "SFCompact",
        characterSpacing: -0.4,
      },
      question: {
        fontSize: 10,
        bold: true,
        font: "SFCompact",
      },
      questionPrint: {
        fontSize: 10,
        bold: true,
        font: "SFCompact",
      },
      questionPrintRadio: {
        fontSize: 10,
        bold: true,
        marginBottom: 15,
        font: "SFCompact",
      },
      answer: {
        fontSize: 11,
        marginBottom: 10,
      },
      answerExtra: {
        fontSize: 11,
      },
      answerPrintRadioCheckbox: {
        // marginBottom: 50,
      },
      answerPrintRadio: {
        // marginBottom: 5,
        marginTop: 2,
        marginLeft: 5,
        fontSize: 11,
      },
      answerPrint: {
        marginTop: 20,
        marginBottom: 20,
        width: "100%",
        borderColor: ["#EB9258", "#fff", "#fff", "#fff"],
      },
      answerPrintNote: {
        marginTop: 20,
        marginBottom: 5,
        width: "100%",
        borderColor: ["#EB9258", "#fff", "#fff", "#fff"],
      },
      notePrint: {
        fontSize: 9,
        bold: true,
        font: "SFCompact",
        marginTop: 0,
        marginBottom: 20,
        width: "100%",
        borderColor: ["#fff", "#fff", "#fff", "#fff"],
      },
      table: {
        marginBottom: 10,
        fontSize: 11,
      },
      tablePrint: {
        marginTop: 5,
        marginBottom: 10,
        fontSize: 11,
      },
      tablePrintHeader: {
        fontSize: 10,
      },
      paragraph: {
        marginBottom: 16,
        color: "#da7325",
        fontSize: 12,
      },
      spacer: {
        marginBottom: 10,
      },
    },
    defaultStyle: {
      color: "#24436B",
      font: "SFPro",
    },
    images: {
      neovia: {
        url: "https://taurus-group-uploads.s3.ap-southeast-2.amazonaws.com/neovia_logo_blue_658e9d9795.jpg",
      },
      neoviaSwirl: {
        url: "https://taurus-group-uploads.s3.ap-southeast-2.amazonaws.com/neovia_swirl_form_c658d5ac8a.png",
      },
    },
  };
};

const generatePdfContent = (checklist) => {
  return checklist.sections.map((section) => {
    if (section.__component === COMPONENT_TYPE_ENUM.TITLE) {
      return [
        {
          text: section.title,
          style: "sectionTitle",
        },
      ];
    } else if (section.__component === COMPONENT_TYPE_ENUM.PARAGRAPH) {
      return [{ text: striptags(section.text), style: "paragraph" }];
    } else if (section.__component === COMPONENT_TYPE_ENUM.UPLOAD) {
      return [
        { text: section.question, style: "question" },
        { text: section.answer || "n/a", style: "answer" },
      ];
    } else if (section.__component === COMPONENT_TYPE_ENUM.ATTACHMENT) {
      return [];
    } else if (section.__component === COMPONENT_TYPE_ENUM.TABLE) {
      return [
        { text: section.question, style: "question" },
        {
          style: "table",
          table: {
            body: [
              section.headings.map((h) => h.key),
              ...section.answer.split(";").map((a) => {
                return a.split(",").map((b) => ({ text: b }));
              }),
            ],
          },
        },
      ];
    } else {
      return [
        { text: section.question, style: "question" },
        { text: section.answer || "n/a", style: "answer" },
      ];
    }
  });
};

const generateOfflinePdfContent = (checklist) => {
  return checklist.sections.map((section) => {
    if (section.__component === COMPONENT_TYPE_ENUM.TITLE) {
      return [
        {
          text: section.title,
          style: "sectionTitle",
        },
      ];
    } else if (section.__component === COMPONENT_TYPE_ENUM.PARAGRAPH) {
      return [
        {
          text: striptags(section.text),
          style: "paragraph",
        },
      ];
    } else if (section.__component === COMPONENT_TYPE_ENUM.UPLOAD) {
      return [];
    } else if (section.__component === COMPONENT_TYPE_ENUM.ATTACHMENT) {
      return [];
    } else if (section.__component === COMPONENT_TYPE_ENUM.TABLE) {
      return [
        { text: section.question, style: "question" },
        {
          style: "tablePrint",
          table: {
            widths: section.headings.map((h) => "*"),
            body: [
              section.headings.map((h) => ({
                text: h.key,
                style: "tablePrintHeader",
                borderColor: ["#24436B", "#24436B", "#24436B", "#24436B"],
              })),
              section.headings.map((h) => ({
                text: " ",
                borderColor: ["#24436B", "#24436B", "#24436B", "#24436B"],
              })),
              section.headings.map((h) => ({
                text: " ",
                borderColor: ["#24436B", "#24436B", "#24436B", "#24436B"],
              })),
              section.headings.map((h) => ({
                text: " ",
                borderColor: ["#24436B", "#24436B", "#24436B", "#24436B"],
              })),
              // ...section.answer.split(";").map((a) => {
              //   return a.split(",").map((b) => ({ text: b }));
              // }),
            ],
          },
        },
      ];
    } else {
      if (
        section.__component === COMPONENT_TYPE_ENUM.RADIO ||
        section.__component === COMPONENT_TYPE_ENUM.SELECT
      ) {
        return [
          { text: section.question, style: "questionPrintRadio" },
          ...section.options.map((o) => ({
            table: {
              body: [
                [
                  {
                    table: {
                      border: [false, false, false, false],
                      widths: [6],
                      heights: [4],
                      body: [
                        [
                          {
                            text: " ",
                            borderColor: [
                              "#24436B",
                              "#24436B",
                              "#24436B",
                              "#24436B",
                            ],
                            style: "answerPrintRadioCheckbox",
                          },
                        ],
                      ],
                    },
                    borderColor: ["#FFF", "#FFF", "#FFF", "#FFF"],
                  },
                  {
                    text: o.value,
                    borderColor: ["#FFF", "#FFF", "#FFF", "#FFF"],
                    style: "answerPrintRadio",
                  },
                ],
              ],
            },
          })),
          { text: "", style: "spacer" },
        ];
      }

      if (section.relatedFormName) {
        return [
          { text: section.question, style: "questionPrint" },
          {
            style: "answerPrintNote",
            table: {
              border: [true, false, false, false],
              widths: ["*"],
              body: [
                [
                  {
                    text: "\n",
                    borderColor: ["#fff", "#fff", "#fff", "#24436B"],
                  },
                ],
              ],
            },
          },
          {
            text: `Note: Please complete accompanying form: ${section.relatedFormName}`,
            style: "notePrint",
          },
        ];
      }

      return [
        { text: section.question, style: "questionPrint" },
        {
          style: "answerPrint",
          table: {
            border: [true, false, false, false],
            widths: ["*"],
            body: [
              [
                {
                  text: "\n",
                  borderColor: ["#fff", "#fff", "#fff", "#24436B"],
                },
              ],
            ],
          },
        },
      ];
    }
  });
};

import {
  MDBCollapse,
  MDBContainer,
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
} from "mdbreact";
import React from "react";
import { Link, withRouter } from "react-router-dom";
import "./style.scss";
// import MobileNavOne from "./mn1";

export const NAV_LINKS = [
  { name: "Home", path: "/" },
  {
    type: "dropdown",
    name: "Services",
    children: [
      { name: "Chartered Accountancy", path: "/chartered-accountancy" },
      { name: "Trust Advisory", path: "/trust-advisory" },
      { name: "Strategic Advisory", path: "/strategic-advisory" },
      //   { name: 'Crowdfunding Advisory', path: 'crowdfunding-advisory' },
      {
        name: "Australian Rental Property",
        path: "/australian-rental-property",
      },
      { name: "Virtual CFO", path: "/virtual-CFO" },
    ],
  },
  {
    type: "dropdown",
    name: "Resources",
    children: [
      { name: "Resources", path: "/accounting-resources" },
      {
        name: "Questionnaires & Checklists",
        path: "/accounting-resources#questionnaires",
      },
    ],
  },
  { name: "Payments", path: "/payment-options" },
  { name: "News", path: "/news" },
  {
    type: "dropdown",
    name: "About",
    children: [
      { name: "About Us", path: "/financial-solutions-company" },
      { name: "Our People", path: "/financial-experts" },
      { name: "Our Story", path: "/our-story" },
      { name: "Job Vacancies", path: "/job-vacancies" },
    ],
  },
  { name: "Contact", path: "/contact-us" },
];

class Nav extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isTop: true,
      menuOpen: false,
      activeUrl: "/",
      hasScrolled: false,
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  toggleMenu = (e) => {
    if (e) e.preventDefault();
    // let ele = document.getElementById("mdb-hamburger-menu");
    // if (ele) {
    //   ele.classList.toggle("opened");
    //   ele.classList.toggle("closed");
    // }

    let isOpen = !this.state.menuOpen;
    this.setState({
      menuOpen: isOpen,
    });
  };

  updateActiveUrl = (url) => {
    if (window.innerWidth < 992) {
      this.toggleMenu();
    }

    this.setState({
      activeUrl: url,
    });

    if (url.includes("#")) {
      let urlSplit = url.split("#");
      if (urlSplit.length > 1) {
        let id = urlSplit[urlSplit.length - 1];
        this.scrollTo(id);
      }
    }
  };

  scrollTo(id) {
    let ele = document.getElementById(id);
    if (ele) {
      let top = ele.offsetTop;
      window.scrollTo({
        top: top,
        left: 0,
        behavior: "smooth",
      });
    }
  }

  handleScroll = (e) => {
    if (window.scrollY <= 40) {
      this.setState({ hasScrolled: false });
    } else if (window.scrollY > 41) {
      this.setState({ hasScrolled: true });
    }
  };

  render() {
    const links = NAV_LINKS.map((l, i) => {
      if (l.type === "dropdown") {
        return (
          <MDBDropdown key={i}>
            <MDBDropdownToggle className="pt-2 white-text" nav caret>
              <span className="mr-2">
                <b className="ml-1 nav-text sf-compact white-text">{l.name}</b>
              </span>
            </MDBDropdownToggle>
            <MDBDropdownMenu className="background-dark">
              {l.children.map((c, x) => {
                return (
                  <MDBDropdownItem
                    key={x}
                    style={{ fontWeight: "800", textAlign: "right" }}
                    active={c.path === window.location.pathname}
                    onClick={() => {
                      if (this.state.menuOpen) {
                        this.toggleMenu();
                      }
                      this.updateActiveUrl(c.path);
                    }}
                  >
                    <MDBNavLink
                      to={c.path}
                      className="nav-text sf-compact white-text"
                      style={{ fontWeight: "800", textAlign: "right" }}
                    >
                      {c.name}
                    </MDBNavLink>
                  </MDBDropdownItem>
                );
              })}
            </MDBDropdownMenu>
          </MDBDropdown>
        );
      } else {
        return (
          <MDBNavItem
            key={i}
            className="pb-2 pt-2"
            active={l.path === window.location.pathname}
            onClick={() => {
              if (this.state.menuOpen) {
                this.toggleMenu();
              }
              this.updateActiveUrl(l.path);
            }}
          >
            <Link
              to={l.path}
              style={{
                paddingLeft: "15px",
                paddingRight: "15px",
              }}
            >
              <b className="nav-text mr-lg-2 nav-text sf-compact white-text">
                {l.name}
              </b>
            </Link>
          </MDBNavItem>
        );
      }
    });

    return (
      <>
        {/* <MobileNavOne links={NAV_LINKS} updateActiveUrl={this.updateActiveUrl} /> */}
        <MDBNavbar
          id="nav"
          className="background-dark"
          dark
          expand="lg"
          fixed="top"
          scrolling
        >
          <MDBContainer className="container-fluid" fluid>
            <MDBNavbarBrand>
              <img
                style={{ cursor: "pointer" }}
                src="/loader/new/neovia_logo.svg"
                alt="logo"
                className="nav-logo pointer"
                onClick={() => this.props.history.push("/")}
                data-scrolling={this.state.hasScrolled}
              />
              <img
                src={require("../../assets/img/logos/rbi-logo-white.png")}
                alt="logo"
                className="rbi-navbar-logo"
              />
            </MDBNavbarBrand>

            <div className="mdb-hamburger-menu-container hide-mobile-nav">
              <a
                id="mdb-hamburger-menu"
                className={this.state.menuOpen ? "opened" : "closed"}
                href="#menu"
                onClick={(e) => this.toggleMenu(e)}
              >
                <div className="hamburger-line top bg-white"></div>
                <div className="hamburger-line middle bg-white"></div>
                <div className="hamburger-line bottom bg-white"></div>
              </a>
            </div>

            <MDBCollapse
              id="navbarCollapse"
              isOpen={this.state.menuOpen}
              className={this.state.menuOpen ? "fade-in" : null}
              navbar
            >
              <MDBNavbarNav right>
                {links}
                {/* <MDBRow style={{ alignSelf: 'center' }} center>
                                    <MDBNavItem>
                                        <a className={`fb-ic ${this.state.menuOpen ? '' : 'ml-4'}`} href="https://www.facebook.com/Neoviagroupnz" target="_blank" rel="noopener noreferrer"><MDBIcon fab icon="facebook-f" className="nav-text mt-2" /></a>
                                    </MDBNavItem>
                                    <MDBNavItem>
                                        <a className={`li-ic ${this.state.menuOpen ? 'ml-4' : 'ml-4'}`} href="https://www.linkedin.com/company/Neovia-group-limited/" target="_blank" rel="noopener noreferrer"><MDBIcon fab icon="linkedin" className="nav-text mt-2" /></a>
                                    </MDBNavItem>
                                </MDBRow> */}
              </MDBNavbarNav>
            </MDBCollapse>
          </MDBContainer>
        </MDBNavbar>
        <div
          className={
            this.state.menuOpen
              ? "mobile-nav fade-in"
              : "mobile-nav mobile-nav-closed"
          }
        >
          <div
            style={{
              maxWidth: "400px",
              marginLeft: "auto",
              marginRight: "auto",
              textAlign: "center",
            }}
          >
            <MDBNavbarNav
              className="flex"
              style={{
                paddingTop: "50px",
                flexDirection: "column",
              }}
            >
              {links}
            </MDBNavbarNav>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(Nav);

import {
  MDBBtn,
  MDBCard,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBRow,
} from "mdbreact";
import React, { useEffect, useState } from "react";
import { HelmetProvider } from "react-helmet-async";
import { useHistory } from "react-router-dom";
import { STRAPI_URL } from "../../data/DataRequestor";
import { PAYMENT_FORM_STORAGE_KEY } from "../../utils/constants";

const paymentUrl = STRAPI_URL + "/payments/checkout";

const PaymentForm = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState({
    name: "",
    phone: "",
    email: "",
    clientId: "",
    invoiceNo: "",
    amount: "",
  });

  useEffect(() => {
    let dataStore = window.sessionStorage.getItem(PAYMENT_FORM_STORAGE_KEY);
    window.sessionStorage.removeItem(PAYMENT_FORM_STORAGE_KEY);
    try {
      let formData = JSON.parse(dataStore);
      if (formData) setData(formData);
    } catch {
      // something went wrong
      // not much can be done
      // ignore
    }
  }, []);

  const checkout = async (e) => {
    e.preventDefault();
    e.target.className += " was-validated";
    setError(null);

    // test form for validity
    let inputs = e.nativeEvent.target;
    for (let i = 0; i < inputs.length; i++) {
      let input = inputs[i];
      if (input != null && !input.validity.valid) {
        setError("Please ensure all fields are completed.");
        return;
      }
    }

    setLoading(true);
    let result = await fetch(paymentUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (result.status === 200) {
      let resJson = await result.json();
      if (resJson.success && resJson.data && resJson.data.redirectUri) {
        window.location.href = resJson.data.redirectUri;
      } else {
        setError(resJson.message);
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  const changeHandler = (event) => {
    let d = { ...data };
    d[event.target.id] = event.target.value;
    setData(d);
  };

  return (
    <HelmetProvider>
      <div>
        <MDBContainer className="pt-5 pb-5">
          <MDBRow>
            <MDBCol md="8" className="mx-auto text-center pb-4">
              <MDBCard className="text-left">
                <h2 className="text-left">Christchurch / Lincoln branch</h2>
                <h5 className="h5-responsive text-left font-space-xs">
                  Enter your details and proceed to payment.
                </h5>
                <div
                  className="text-center"
                  style={{
                    color: "darkred",
                    fontSize: "14px",
                    lineHeight: "14px",
                    fontWeight: 500,
                    minHeight: "20px",
                  }}
                >
                  {error}
                </div>
                <form
                  className="needs-validation"
                  onSubmit={(e) => checkout(e)}
                  noValidate
                >
                  <div>
                    <div className="form-group">
                      <label htmlFor="name">Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="Full name"
                        onChange={changeHandler}
                        value={data.name}
                        disabled={loading}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="phone" className="">
                        Phone
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="phone"
                        placeholder="Phone number"
                        onChange={changeHandler}
                        value={data.phone}
                        pattern="^(0|(\+64(\s|-)?)){1}\d{1}(\s|-)?\d{3}(\s|-)?\d{4,8}$"
                        disabled={loading}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="email" className="">
                        Email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="Email address"
                        onChange={changeHandler}
                        value={data.email}
                        pattern="^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$"
                        disabled={loading}
                        required
                      />
                    </div>
                    {/* <div className="form-group">
                      <label htmlFor="clientId" className="">
                        Client Code
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="clientId"
                        placeholder="Client Code"
                        onChange={changeHandler}
                        value={data.clientId}
                        disabled={loading}
                        required
                      />
                    </div> */}
                    <div className="form-group">
                      <label htmlFor="invoiceNo" className="">
                        Invoice Number
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="invoiceNo"
                        placeholder="Invoice number"
                        onChange={changeHandler}
                        value={data.invoiceNo}
                        disabled={loading}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="amount" className="">
                        Payment Amount
                      </label>
                      <input
                        type="number"
                        min="1.00"
                        step="0.01"
                        max="25000"
                        className="form-control"
                        id="amount"
                        placeholder="Amount to pay"
                        onChange={changeHandler}
                        value={data.amount}
                        disabled={loading}
                        required
                      />
                    </div>
                  </div>
                  <MDBRow className="mt-lg-5">
                    <MDBCol className="text-left">
                      <MDBBtn
                        color="secondary"
                        type="button"
                        onClick={() => history.push("/payment-options")}
                        disabled={loading}
                      >
                        {/* <MDBIcon icon="arrow-left" className="mr-2" /> */}
                        Cancel
                      </MDBBtn>
                    </MDBCol>
                    <MDBCol className="text-right">
                      <MDBBtn color="primary" type="submit" disabled={loading}>
                        Proceed to payment
                        {loading ? (
                          <div
                            className="spinner-border"
                            role="status"
                            style={{
                              height: "15px",
                              width: "15px",
                              verticalAlign: "middle",
                              marginLeft: "5px",
                              marginBottom: "2px",
                              color: "white !important",
                            }}
                          >
                            <span className="sr-only"></span>
                          </div>
                        ) : (
                          <MDBIcon icon="arrow-right" className="ml-2" />
                        )}
                      </MDBBtn>
                    </MDBCol>
                  </MDBRow>
                </form>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
    </HelmetProvider>
  );
};

export default PaymentForm;
